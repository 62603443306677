import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import SignupLayout from './SignupLayout';
import { onLogIn } from './LoginAction';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { initNextBtnClickOnEnterEvent } from '../../../library/Core/SmartFunctions';

const AdminLogin = ({pageTitle}:any) => {
    document.title=pageTitle
    // to get the page name
    const location = useLocation();
    const pageName = 'admin-login';
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.setItem('user-logged-out','TRUE');
        const domainData = localStorage.getItem('app-domain-data');
        const domainDataTimestamp = localStorage.getItem('app-domain-data-timestamp');
        localStorage.clear();
        localStorage.setItem('app-domain-data', domainData ?? '');
        localStorage.setItem('app-domain-data-timestamp', domainDataTimestamp ?? '');
        sessionStorage.clear();
    },[]);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: { LOG_IN: onLogIn, ROUTER_NAVIGATE: navigate },
    } as any);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <SignupLayout>{<PageBuilder />}</SignupLayout>
        </SmartContext.Provider>
    );
};

export default AdminLogin;
