import { getValueFromSessionStore } from '../library/Core/SessionStore';
import { getDomainData } from '../library/Core/SmartFunctions';

class DomainDataService {
    static async get() {
        const storedData = getValueFromSessionStore(`domain-data`);
        const storedDataObj = storedData ? new Map(JSON.parse(storedData as string)) : new Map();
        if (storedDataObj.size > 20) return Promise.resolve({ data: storedDataObj, mode: 'local-storage' }) as any;

        return { data: await getDomainData() };
    }
}

export default DomainDataService;
