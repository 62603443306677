import { useContext, useState, useEffect } from 'react';
import { Modal, Tooltip } from 'bootstrap';
import { useNavigate } from 'react-router-dom';
import { getCompanyImage, getUniversityImage } from '../../../../common/styles/Images';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { axiosClient } from '../../../../library/Service/axiosClient';
import VerifyMobileControl from '../../onboarding/VerifyMobileControl';
import {
    canEditProfile,
    getDomainValueForCode,
    getFlexColDomainValueForCode,
    isEmpty,
    isSchoolAdmin,
    isSuperAdmin,
} from '../../../../library/Core/SmartFunctions';
import { getProfileHeadeData } from '../../../../library/Service/cardTypeServce';
import ShareActionModal from '../../../management/alumni-list/ShareActionModal';
import ShareEmailActionModal from '../../../management/alumni-list/ShareEmailActionModal';
import SessionContext from '../../../../library/Core/SessionContext';

const ProfileHeader = () => {
    const { state } = useContext(SmartContext);
    const navigate = useNavigate();
    const programName = state?.data?.programName ?? '';
    const userInstituteName = state?.data?.userInstituteName;
    const { sessionState, sessionDispatch } = useContext(SessionContext);
    const [passOutYearToolTip, setPassOutYearToolTip] = useState('');
    const [passOutYear, setPassOutYear] = useState('');
    const [profileMarkerURL, setProfileMarkerURL] = useState('');
    const [showProfileMarkerAlert, setShowProfileMarkerAlert] = useState(false);
    const [profileMarkerAlertMessage, setprofileMarkerAlertMessage] = useState('');

    const setProfileMarkerAlertData = () => {
        if(!isEmpty(state.data?.profile?.profileMarkerJson))
        {
            const demographicsMarkerColumns = ["CurrentlyAbroad","genderCode","LivedAbroadandBack","StudiedAbroad"]
            let urls:string[] = [];
            const markersJson = JSON.parse(state.data?.profile?.profileMarkerJson);
            let messageColumnCategories:string[] = [];
            markersJson.forEach((marker: any) => {
                if (demographicsMarkerColumns.includes(marker.field) && !messageColumnCategories.includes('Demographics') ) {
                    messageColumnCategories.push('Demographics');
                    if(marker.field == 'StudiedAbroad'){
                        urls.push('pg');
                    }else{
                        urls.push('info');
                    }
                }
            });
            
            const message = `You have a ${messageColumnCategories.join(',')} Marker active without substantial data in the actual profile of the alumni.`
            setShowProfileMarkerAlert(true);
            if(urls.includes("pg") && urls.includes("info"))
            {
                setProfileMarkerURL(`/alumni/${model.id}/info`);
            }
            else if(urls.includes("pg"))
            {
                setProfileMarkerURL(`/alumni/${model.id}/pg`);
            }
            else
            {
                setProfileMarkerURL(`/alumni/${model.id}/info`);
            }
            setprofileMarkerAlertMessage(message);
        }
    };
   
    useEffect(() => {
        const getProgramDetails = async () => {
                let instituteIdSecondInst = null;
                let passOutYearSecondInst = null;
                for (let i = 0; i < state?.data?.userInstitutes.length; i++) {
                    if(state?.data?.selectedInstitute){
                        if (state?.data?.userInstitutes[i].instituteId !== state?.data?.selectedInstitute.instituteId) {
                            instituteIdSecondInst = state?.data?.userInstitutes[i].instituteId;
                            passOutYearSecondInst = state?.data?.userInstitutes[i].passOutYear;
                        }else{
                            setPassOutYear(state?.data?.userInstitutes[i].passOutYear);
                        }
                    }else{
                        instituteIdSecondInst = state?.data?.userInstitutes[0].instituteId;
                        passOutYearSecondInst = state?.data?.userInstitutes[0].passOutYear;
                        setPassOutYear(state?.data?.userInstitutes[i].passOutYear);
                    }
                } 
            if (state?.data?.userInstitutes.length > 1) {
                try {
                     const response = (
                        await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/profile/get-program-data/${state.data.id}/${instituteIdSecondInst}`)
                     )
                    if (response?.status === 200) {
                        const programNameSecondInst = response?.data?.data?.programName || "Unknown Program";
                        const nameSecondInst = response?.data?.data?.name || "Unknown Name";
                        const tooltipContent = `${programNameSecondInst}, ${passOutYearSecondInst} ${nameSecondInst}`;
                        setPassOutYearToolTip(tooltipContent);
                    }
                } catch (error) {
                    console.error('Error sending SMS:', error);
                }
            }
        };
    
        getProgramDetails();
        setProfileMarkerAlertData();
    }, [state.data.userInstitutes]);
    

    useEffect(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });
    }, []);
    //const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    useEffect(() => {
        console.log(selectedStudents, 'selectedStudents after state update');
    }, [selectedStudents]); // The effect triggers when selectedStudents changes

    const model = state.internal.model;
    const data = state.data;

    console.log(data, 'data');

    const [showVerifyMobile, setShowVerifyMobile] = useState(false);
    const [isMobileVerified, setIsMobileVerified] = useState(model.isMobileVerified); // Initialize the state based on model.is_mobile_verify
    const handleVerifyMobile = (event: any) => {
        event.preventDefault();
        sendMobileInvite(model.mobileNumber);
        setShowVerifyMobile(true);
    };
    const socialShare = async (students: any) => {
        try {
            // Your logic here
            //console.log('updateAction logic');
            console.log(students, 'students data');
            setSelectedStudents(students);

            console.log(selectedStudents, 'selectedStudents data');
            const modalElement = document.getElementById('social-share');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const socialEmailShare = async (students: any) => {
        try {
            // Your logic here
            //console.log('updateAction logic');
            console.log(students, 'students data');
            setSelectedStudents(students);
            console.log(selectedStudents, 'selectedStudents data');
            const modalElement = document.getElementById('social-email');
            if (modalElement) {
                const modal = new Modal(modalElement);
                modal.show();
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const sendMobileInvite = async (mobile: string) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/sms/send-verification-otp`);
            if (response?.status === 200) {
                setIsMobileVerified(true);
            }
        } catch (error) {
            console.error('Error sending SMS:', error);
        }
    };

    const genderCode = state?.data?.profile?.genderCode ?? 'MALE';
    let profileImage = model?.profileImage;
    if (profileImage == '' || profileImage == null) {
        profileImage =
            genderCode == 'MALE'
                ? `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student-boy.svg`
                : `${process.env.REACT_APP_IMAGE_BASEPATH}/yellow-student.svg`;
    }

    const handleSendMessage = (event: any, id: string) => {
        event.preventDefault();
        navigate(`/messages/send-message/${id}`);
    };

    const {
        current_logo,
        current_name,
        current_field,
        current_type_text,
        current_status,
        showCurretnStatusDiv,
        interestedInUpSkilling,
        interestedInHigherEducation,
    } = getProfileHeadeData(state, model);
    console.log("current_status",current_status)

    const [isOpen, setOpen] = useState(false);

    const handleFilterToggle = () => {
        setOpen(!isOpen);
    };

    const isProfilePictureEditable = data.id === sessionState?.id ? true : false;
    console.log(isSchoolAdmin());
    return (
        <>
            {!state?.flags.isDataLoading && (
                <section
                    key={state?.routeInfo?.id}
                    className="bg-white container-fluid alumni_profile_brife_wrap"
                    id="alumni_profile_brife_wrap">
                    <div className="max-1140 mx-auto alumni_profile_brife">
                        <div className="row">
                            {state?.data?.userTypeCode !== 'STUDENT' && !canEditProfile(model.id, sessionState) && (
                                <div className="col-12 mb-4">
                                    <a className="btn-back" role="button" onClick={() => navigate('/alumni/directory')}>
                                        <i className="icon-right-arrow"></i> <span>Back to Directory</span>
                                    </a>
                                </div>
                            )}

                            {showProfileMarkerAlert && (isSchoolAdmin() || isSuperAdmin()) && <div className='col-12'>
                                <div className='custom-alret'>
                                    <div className='row g-2 align-items-center'>
                                        <div className="col">{profileMarkerAlertMessage}</div>
                                        <div className='col-auto'><a onClick={() => navigate(profileMarkerURL)} className='btn-green'><span>Update Now</span></a></div>
                                        <div className='col-auto'><button onClick={() => setShowProfileMarkerAlert(false)} className='btn-close w-20 mt-2'></button></div>
                                    </div>
                                </div>
                            </div>}
                            <div className="col-12">
                                <div className="alumni_profile_name_college_other_detail_wrap">
                                    <div className="alumni_profile_image_name_other_detail">
                                        <div className="alumni_profile_image_wrap">
                                            <div className="alumni_profile_image">
                                                <img src={profileImage} alt="" />
                                            </div>
                                            {(isSchoolAdmin() || isProfilePictureEditable) && (
                                                <a
                                                    className="pencil-edit"
                                                    href="javascript:;"
                                                    onClick={() => navigate(`/alumni/${model.id}/profile-picture`)}>
                                                    <i className="icon-pencil"></i> {/* Optional: Add an icon */}
                                                </a>
                                            )}
                                        </div>
                                        <div className="alumni_profile_name_other_detail">
                                            <h2>
                                                {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                            </h2>
                                            <div className="alumni_profile_location">
                                                <i className="icon-location-pin"></i>{' '}
                                                <span>
                                                    {model.cityName}, {model.countryName}
                                                </span>
                                            </div>
                                            <div className="alumni_profile_class_college">
                                               <div className="alumni_profile_class">Program: {`${programName} ${passOutYear}`} 
                                                {state.data.userInstitutes.length > 1 && (
                                                 <div data-bs-toggle="tooltip" data-bs-original-title={passOutYearToolTip}>
                                                  + 1 More</div>)}
                                                </div>
                                                <div className="alumni_profile_college mb-2">{model?.schoolName ?? userInstituteName}</div>

                                                {model.isEditable && (
                                                    <div className="alumni_profile_college_phone mb-1">
                                                        <span className="font-16 font-500 text-black line-height-18">
                                                            (+{model?.mobileCountryCode}) {model?.mobileNumber}
                                                        </span>
                                                        {/* {userTypeCode != 'STUDENT' && model?.mobileNumber?.length > 0 && !isMobileVerified && (
                                                        <a className="verifyLink" role="button" onClick={handleVerifyMobile}>
                                                            <span>Verify Now</span>
                                                        </a>
                                                    )} */}
                                                        {isMobileVerified && (
                                                            <a className="verifiedLink">
                                                                <i className="icon-varified font-16 me-2"></i>
                                                                <span>Verified</span>
                                                            </a>
                                                        )}
                                                    </div>
                                                )}

                                                {model.isEditable && (
                                                    <div className="alumni_profile_college_phone">
                                                        <span
                                                            data-bs-toggle="tooltip"
                                                            className="font-16 font-500 text-black line-height-18"
                                                            data-bs-original-title={model?.registeredEmailId}
                                                            data-bs-placement="bottom">
                                                            {model?.registeredEmailId.length > 15
                                                                ? model?.registeredEmailId.substring(0, 15) + '...'
                                                                : model?.registeredEmailId}
                                                        </span>
                                                        <a className="verifiedLink">
                                                            <i className="icon-varified font-16 me-2"></i>
                                                            <span>Verified</span>
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                            {isSchoolAdmin() && (
                                                <div className="send_link">
                                                    <div className="row g-2">
                                                        <div className="col-auto">
                                                            <a
                                                                role="button"
                                                                onClick={(event) => handleSendMessage(event, model.id)}
                                                                className="btn-send">
                                                                <i className="icon-alumni-send"></i>
                                                                <span>Send</span>
                                                            </a>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="w-auto position-relative">
                                                                <a
                                                                    className="btn-moreaction px-3 collapsed"
                                                                    href="javascript:;"
                                                                    onClick={handleFilterToggle}
                                                                    aria-expanded={isOpen ? 'true' : 'false'}
                                                                    aria-controls="collapseExample">
                                                                    <span>More Actions</span>
                                                                    <span className="dot">
                                                                        <i></i>
                                                                    </span>
                                                                </a>
                                                                <div
                                                                    className={`position-absolute z1 top-48 left-0 collapse ${
                                                                        isOpen ? 'show' : ''
                                                                    }`}
                                                                    id="collapseExample">
                                                                    <div className="card card-body">
                                                                        <ul className="more_action">
                                                                            <li>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    role="button"
                                                                                    onClick={(event) => socialShare(model.id)}>
                                                                                    Social Share
                                                                                </a>
                                                                            </li>
                                                                            <li>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    role="button"
                                                                                    onClick={(event) => socialEmailShare(model.id)}>
                                                                                    Send via Email
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {model.isEditable && (
                                            <a className="btn btn-link " role="button" onClick={() => navigate(`/alumni/${model.id}/info`)}>
                                                <span className="pencil-edit"></span>
                                            </a>
                                        )}
                                        
                                    </div>
                                    {showCurretnStatusDiv && (
                                        <div className="alumni_profile_college_name_other_detail_wrap">
                                            <div className="alumni_profile_college_logo">
                                                <img src={current_logo} alt={current_name} />
                                            </div>
                                            <div className="alumni_profile_college_name_other_detail">
                                                <div className="alumni_profile_college_name_detail">
                                                    <div className="alumni_profile_college_joined">{current_type_text}</div>
                                                    <h3>{current_name}</h3>
                                                    {current_field && (
                                                        <div className="alumni_profile_college_subject">
                                                            <i className="icon"></i>
                                                            <span className="defaultTip" data-bs-toggle="tooltip" title={current_field}>
                                                                {current_field}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="alumni_profile_other_detail">
                                                    <ul className="alumni_profile_status">
                                                        <li>
                                                            <span className="title">Current Status</span>
                                                            <p>{current_status}</p>
                                                        </li>
                                                        {(interestedInUpSkilling || interestedInHigherEducation) && (
                                                            <li>
                                                                <span className="title">Next Move in Career</span>
                                                                <p>
                                                                    {interestedInHigherEducation && 'Higher studies'}
                                                                    {interestedInHigherEducation &&
                                                                        interestedInUpSkilling &&
                                                                        ', Upskilling courses'}
                                                                    {!interestedInHigherEducation &&
                                                                        interestedInUpSkilling &&
                                                                        'Upskilling courses'}
                                                                </p>
                                                            </li>
                                                        )}

                                                        {isSchoolAdmin() == false && sessionState?.id !== model.id && (
                                                            <li>
                                                                <span className="title">Connect Now</span>
                                                                <div className="alumni_profile_name_other_detail">
                                                                    <a onClick={() => navigate(`/messages/${model.id}`)} role="button" className="btn-send">
                                                                        <i className="icon-send"></i><span>Send</span>
                                                                    </a>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {showVerifyMobile && <VerifyMobileControl setShowVerifyMobile={setShowVerifyMobile} mobileNumber={model.mobileNumber} />}
            <ShareActionModal data={[selectedStudents]} />
            <ShareEmailActionModal data={[selectedStudents]} />
        </>
    );
};

export default ProfileHeader;
