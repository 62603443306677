import { isFormValid } from "../../../library/Core/SmartFunctions";
import { ControlOnChangeArguments, DispatchEvent, DomainElement, State } from "../../../library/Core/SmartTypes";
import { axiosClient } from "../../../library/Service/axiosClient";
import { addUserSpecificDomains } from "../../../library/Service/domainService";
import { useContext } from "react";
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from "../../../library/Core/SmartContext";
import UsersListService from './UsersListService';
import { toast } from 'react-toastify';

import { v4 as uuidv4 } from 'uuid';

// export const onSpeakernameChange = async (
//     props: { value?: string[] }
// ) => {
//     console.log("Props received:", props);
//     if (!props || !props.value) {
//         console.warn("No valid props or value provided.");
//         return;
//     }
//     console.log("Speaker Names:", props.value);
// };

//Validation check for length based on country code
export const onSpeakernameChange = (props: ControlOnChangeArguments) => {
    console.log(props.value);
};


export const handleCreate = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    eventId: string // Accept eventId explicitly
) => {
    event.preventDefault();

    if (!isFormValid(state, dispatch)) {
        return;
    }

    console.log("State at submission:", state); // Log full state for debugging
    console.log("State data:", state['data']);
    console.log("Event ID:", eventId);

    const eventAdd = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatespeakerinfo`;
    axiosClient()
        .post(`${eventAdd}`, {
            eventId, // Use the passed eventId
            speakerinfo: JSON.stringify(state['data']['speakerinfo']),
        })
        .then((res: any) => {
            console.log(res.data.eventId);
            alert(res.data.message);

            if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
            console.log("Here redirect code needs to be implemented");
        })
        .catch((error) => {
            console.error("Error saving speaker info:", error);
            alert("Failed to save speaker info. Please try again.");
        });
};

export const onOrganizationChange = async (
    props: { value: string[] },
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void
) => {
    const { allUsers } = UsersListService();
    console.log("Handling Organization change:", props);

    // Perform any additional logic if needed
   // const { value } = props;

    console.log(props.value, 'value of the Organization')

    try {
        const instituteId = Array.isArray(props.value) ? props.value[0] : props.value;
        console.log(instituteId, 'instituteId');
        if (!instituteId) throw new Error("Invalid instituteId provided.");

        // Fetch users list from the same institute
        const usersListFromSameInstitute = await allUsers({ instituteId });
        //const usersListFromSameInstitute = '';

        // Create a strongly typed domainMap
        // const domainMap: Map<string, DomainElement[]> = new Map<string, DomainElement[]>([...state.domain]);
        // domainMap.set("USERS_FROM_SAME_INSTITUTE", usersListFromSameInstitute || []);

        // // Update domains
        // //const updatedDomain = addUserSpecificDomains(domainMap, state.data);

        // // Dispatch the updated domain to the state
        // dispatch({
        //     type: 'SET_DOMAIN',
        //     payload: { key: 'USERS_FROM_SAME_INSTITUTE', value: usersListFromSameInstitute },
        // });

        // toast.success("Organization data updated successfully.");

        // Dynamically add or merge the data into the domain
        const existingDomain = state?.domain?.get("USERS_FROM_SAME_INSTITUTE") || [];
        const updatedDomain = [
            ...existingDomain, // Retain existing data
            ...usersListFromSameInstitute, // Add new data
        ];

        // Dispatch the updated domain
        dispatch({
            type: "SET_DOMAIN",
            payload: { key: "USERS_FROM_SAME_INSTITUTE", value: updatedDomain },
        });

        toast.success("Organization data updated successfully.");
        
    } catch (error) {
        console.error("Error in onOrganizationChange:", error);
        toast.error("Failed to fetch organization data. Please try again.");
    }
};

// const handleSpeakernameChange = (props, state, dispatch) => {
//     console.log("Handling speakername change:", props);

//     // Perform any additional logic if needed
//     const { value } = props;

//     // Dispatch updated value to state
//     dispatch({
//         type: "CONTROL_VALUE_CHANGE",
//         payload: { dataKey: "speakerinfo.speakername", value },
//     });
// };

