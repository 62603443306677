import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import Swal from 'sweetalert2';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
import SessionContext from '../../../library/Core/SessionContext';
//import PreviewLayout from './PreviewLayout';
import EventsGridControl from '../../meetings-events/listing/EventsGridControl';
import EventPreviewControl from './EventPreviewControl';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';

const Preview = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const userType = sessionState?.userTypeCode;

    // const queryParams = new URLSearchParams(window.location.search);
    // const eventId = queryParams.get('id');
    const { eventId } = useParams();

    console.log(eventId);
    if (!eventId) {
        navigate(`/event/add`);
    }
    const { routeInfo, formConfig, data, internal } = useLoaderData() as State;

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        internal: {
            isListModalOpen: false
        },
        data: data,
        actions: {
            // SAVE: handleCreate,
            PUBLISH: publishEvent,
        },
    } as any);

    useEffect(() => {
        console.log(state.formValidationErrors, 'formValidationErrors');
    }, [state.formValidationErrors]);

    async function publishEvent(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();

        let retData = {
            id: '',
            success: 0,
            message: `Oops..! Something went wrong, Please try again in a while..!!`,
        };

        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

        //let updateaudience = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatethemeinfo`;
        // Prepare the API endpoint
        const updatetheme = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/theme-info`;

        axiosClient()
            .post(`${updatetheme}`, {
                eventId: eventId ? eventId : '',
                eventtheme: arrayColumn(state.data?.updatetheme?.eventtheme ?? [], 'eventtheme'),
                departmentid: arrayColumn(state.data?.updatetheme?.departmentid ?? [], 'departmentid'),
                eventcountries: arrayColumn(state.data?.updatetheme?.eventcountries ?? [], 'eventcountries'),
                eventskills: arrayColumn(state.data?.updatetheme?.eventskills ?? [], 'eventskills'),
                eventtests: arrayColumn(state.data?.updatetheme?.eventtests ?? [], 'eventtests'),
                eventindustry: arrayColumn(state.data?.updatetheme?.eventindustry ?? [], 'eventindustry'),
            })
            .then((res: any) => {
                console.log(res, '  R E S ');
                retData.id = res.data.eventId;
                retData.message = res.data.message;
                retData.success = res.data.success;

                if (res.data.success != '1') {
                    retData.message = retData.message ?? 'There is some issue in event management. Please try after sometime!';
                    retData.success = 0;
                }

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: `${retData.success == 1 ? 'success' : 'warning'}`,
                    title: `${retData.message}`,
                });
            });

        return retData;
    }

    useEffect(() => {
        console.log(data, " EVENT PREIEW DATA 110")
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: data,
                domain,
                routeInfo,
                internal: {
                    ...internal,
                    isListModalOpen: false
                }
            },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            {/* <PreviewLayout>
                {!state.flags.isDataLoading && <PageBuilder />}
            </PreviewLayout> */}
            {/* <EventsGridControl/> */}
            {!state.flags.isDataLoading && <EventPreviewControl/>}
            {/* <DebugBarControl /> */}
        </SmartContext.Provider>
    );
};

export default Preview;
