// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfig.json';
import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';

class PsychometricTestMappingService {
    static userStatusCode: string;
    static studentId: string;
    static userTypeCode: string;
    static genderCode: string;
    static instituteLogo: string;
    static instituteGroupId: string;
    constructor() {
        // IIFE (Immediately Invoked Function Expression)
        (() => {
            const { sessionState } = useContext(SessionContext);
            PsychometricTestMappingService.userStatusCode = sessionState?.userStatusCode as any;
            PsychometricTestMappingService.studentId = sessionState?.studentId as any;
            PsychometricTestMappingService.userTypeCode = sessionState?.userTypeCode as any;
            PsychometricTestMappingService.genderCode = sessionState?.genderCode as any;
            PsychometricTestMappingService.instituteLogo = sessionState?.instituteLogo as any;
            PsychometricTestMappingService.instituteGroupId = sessionState?.instituteGroupId as any;
            //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        })();

        // Rest of the constructor
    }

    static pageLoader(params: any) {    
        const state: any = {};
        const { pageName } = params;
        logger.log(`Loading ${pageName}`);

        return Promise.all([this.getPageConfig()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = values[0];
                state.routeInfo = { pageName };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }
}
export default PsychometricTestMappingService;
