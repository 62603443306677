import { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, isEmpty } from '../Core/SmartFunctions';
import ErrorControl from './ErrorControl';

const SelectEditableMulti = (args) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state);
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState(
        data ? data.map((item) => ({ value: item, label: item })) : []
    );
    const [domainCategoryCode, alternativeDomainCategoryCode] = control.props.domainCategoryCode.split('#');
    const manualEntryColumn = control?.props?.customProperties?.manualEntryColumn;
    const manualEntryData = getControlValueFromState(`${parentDataKey}.${manualEntryColumn}`, state);
    const parentData = control.props.parentId && getControlValueFromState(parentDataKey + '.' + control.props.parentId, state);
    const autoGrow = control?.props?.customProperties?.autoGrow ?? true;
    const [customHeight, setCustomHeight] = useState('45px');

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey, state)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        const domainOptions = getDomainOptions();
        setSelectedOptions(
            domainOptions.filter((option) => data?.includes(option.value)) ||
            data?.map((item) => ({ value: item, label: item })) ||
            []
        );
        setOptions(domainOptions);
    }, []);

    const getDomainOptions = () => {
        if (isEmpty(parentData)) {
            return state?.domain
                ?.get(domainCategoryCode)
                .map((domain) => ({ value: domain.code, lowerLabel: domain.value.toLowerCase(), label: domain.value }));
        }

        let controlDomain = state?.domain?.get(domainCategoryCode).filter((domain) => {
            if (
                parentData == null ||
                control.props.parentId === null ||
                control.props.parentId === undefined ||
                control.props.parentId.length === 0
            )
                return true;
            else return domain.parentCode == parentData;
        });

        return controlDomain.map((domain) => ({ value: domain.code, lowerLabel: domain.value.toLowerCase(), label: domain.value }));
    };

    const loadOptions = (inputValue, callback) => {
        inputValue.length < 3
            ? callback([])
            : callback(options.filter((option) => option.lowerLabel.includes(inputValue.toLowerCase())));
    };

    const processOptionValues = (selectedValues) => {
        const selectedValuesSet = new Set(selectedValues.map((option) => option.value));
        const activeOptions = options.filter((opt) => selectedValuesSet.has(opt.value));
        const manualEntries = selectedValues.filter(
            (opt) => !activeOptions.some((activeOpt) => activeOpt.value === opt.value)
        );

        const activePayload = {
            dataKey,
            name: control.id,
            value: activeOptions.map((opt) => opt.value),
            errorMessages: [],
        };

        const manualEntryPayload = {
            dataKey: `${parentDataKey}.${manualEntryColumn}`,
            name: control.id,
            value: manualEntries.map((opt) => opt.value).join(','),
            errorMessages: [],
        };

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: activePayload });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: manualEntryPayload });

        setSelectedOptions(selectedValues);
    };

    const handleChange = (selected) => {
        if (autoGrow) setCustomHeight(`${45 + selected.length * 0.4 * 40}px`);
        processOptionValues(selected || []);
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div 
                className="custom-multi-select input-group"
                >
                <div 
                    className="input-group-text bg-white rounded-start"
                    >
                    <i className="bi bi-search fs-6"></i>
                </div>
                <AsyncSelect
                        loadOptions={loadOptions}
                        value={selectedOptions}
                        onChange={handleChange}
                        isMulti
                        isClearable
                        isSearchable
                        cacheOptions
                        noOptionsMessage={({ inputValue }) =>
                            inputValue.length < 3 ? 'Enter minimum three characters' : 'No options'
                        }
                    />
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectEditableMulti;