// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfig.json';
import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';

class ManageEventsService {
    static pageLoader(params: any) {    
        const state: any = {};
        const { pageName } = params;
        logger.log(`Loading ${pageName}`);

        return Promise.all([this.getPageConfig()])
            .then((values) => {
                logger.log(`Retrieved values successfully for ${pageName}`);
                state.formConfig = values[0];
                // state.data = {
                //     ...state?.data, 
                //     mappingData: state?.data?.mappingData || {
                //         ...state?.data?.mappingData,
                //         instituteId: 0,
                //         classes: state?.data?.mappingData?.classes || [{}]
                //      },
                //     programid: '', displayValue: ''
                // }

                state.routeInfo = { pageName };
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        return Promise.resolve(pageConfig);
    }

    // static async get(): Promise<any> {
//         let data: any = {};
//         data = (await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/test/get-school-test-mapping`))?.data;
// console.log(data, " D A T A ");
        // data = {
        //     ...data, 
        //     mappingData: data?.mappingData || { 
        //         ...data?.mappingData,
        //         instituteId: 0,
        //         classes: {...data?.mappingData?.classes || {}}
        //     },
        //     programid: '', displayValue: ''
        // };

        // return Promise.resolve(data);
    // }

    static async save(id: string, type: string, data: any): Promise<any> {
        let result;
        if (type === 'profile') {
            result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`, data);
            sessionStorage.setItem(`studentProfile-${id}`, JSON.stringify(result));
        } else if (type === 'additional-info') {
            result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return Promise.resolve(result);
    }
}
export default ManageEventsService;
