import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty, toSentenceCase } from '../../../library/Core/SmartFunctions';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';

import ListPopup from '../../../library/SimpleControls/ListPopup';
import { axiosClient } from '../../../library/Service/axiosClient';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
const EventPreviewControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const navigate = useNavigate();

    const [eventData, setEventData] = useState(state?.data || {});
    const [isPublished, setIsPublished] = useState(eventData?.isPublished == 'yes' ? 1 : 0);

    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [player, setPlayer] = useState<any>(null);
    const [videoLink, setVideoLink] = useState('');
    const playerRef = useRef<any>(null);
    // const modalRef = useRef<HTMLElement | null>(null); // Create a ref for the modal element

    function extractVideoId(url: string) {
        if (url === undefined) return '';
        const videoIdMatch = url.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        if (videoIdMatch) {
            return videoIdMatch[1];
        }
        return '';
    }

    useEffect(() => {
        if (window.YT && typeof window.YT.Player !== 'undefined' && videoLink) {
            const videoId = extractVideoId(videoLink);
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    }, [videoLink]);

    const onPlayerReady = (event: any) => {
        setPlayer(event.target);
        if (isVideoModalOpen) {
            event.target.playVideo();
        }
    };

    const openVideoModal = (link: string) => {
        setVideoLink(link);
        window.scrollTo(0, 0);
        setVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        setVideoLink(''); //@note Same video is not playing again once closed the player;

        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
            playerRef.current = null;
            setPlayer(null);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);
        // dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: true } });

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // OwlCarousel options
    const options = {
        items: 1,
        merge: true,
        loop: false,
        video: true,
        lazyLoad: true,
        nav: true,
        // navText : ["Prev","Next"],
        dots: false,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: false,
    };

    const handleViewList = (data: any) => {
        let popupData: any = [];
        let list: any = [];
        data.forEach((universityId: any) => {
            const univFlexData = getFlexColDomainValueForCode(universityId, 'INSTITUTE_DOMAIN', state);
            const univFlexObj = JSON.parse(univFlexData);

            list.push({
                logo: univFlexObj.logo,
                name: getDomainValueForCode(universityId, 'INSTITUTE_DOMAIN', state),
            });
        });

        popupData.data = list;
        popupData.title = 'List of Universities';

        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'listPopup', value: popupData } });
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: true } });
    };

    const handlePublish = async () => {
        try {
            const response = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/publish/${eventData.id}`);
            console.log('Publish successful:', response.data); // Log success message or handle response data
            Swal.fire({
                title: 'Done!',
                text: 'Event has been published successfully..!',
                icon: 'success',
            }).then((result) => {
                setIsPublished(1);
                navigate(`/events/created-by-you`, { replace: false });
            });
        } catch (error) {
            console.error('Error publishing event:'); // Log error message
            // Handle errors gracefully, e.g., display an error message to the user
        }
    };

    return (
        <div className="max-1140 mx-auto d-flex justify-content-center pb-5">
            <div className="flex-1 polarcontsec tab-content mt-38" id="myTabContent">
                <div className="tab-pane fade show active" id="UpcomingEvents" role="tabpanel" aria-labelledby="UpcomingEvents-tab">
                    <h3 className="font-18 font-500 mb-3 d-none d-md-block">
                        {/* <span id="ucount">{events.length}</span> Events Found */}
                    </h3>

                    <div className="row EventsBlk justify-content-center" id="loadmore">
                        <div key={eventData.id} className="col-lg-6 col-md-12 album_blk_parent pfusers" data-key={eventData.id}>
                            <div className="album_blk bg-white">
                                <div className="album_imgblk position-relative">
                                    <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute">
                                        {getDomainValueForCode(eventData.eventType, 'EVENT_TYPE_ALL', state)}
                                    </span>
                                    <img src={eventData.eventImage} alt="" />
                                </div>
                                <div className="album_contblk eventsbox">
                                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                                        <div className="flex-1">
                                            <h3 className="font-20 font-500 mb-2 title">{toSentenceCase(eventData.eventTitle)}</h3>
                                        </div>
                                    </div>
                                    <p className="d-flex align-items-center justify-content-start mContCenter mb-0">
                                        <span className="icon_bluecir me-2">
                                            <span className="icon-calender-time font-18 text-dark-blue icon"></span>
                                        </span>
                                        <span className="font-28 font-600 text-dark-blue me-2">{new Date(eventData.from).getDate()}</span>
                                        <span className="font-16 font-500 text-dark-blue">
                                            {moment(eventData.from).format('MMM YYYY, h:mm A')}
                                        </span>
                                    </p>

                                    {! [2,6].includes(parseInt(eventData?.eventType)) && eventData?.speakerInfo?.length > 0 && (
                                        <OwlCarousel
                                            className="owl-carousel owl-theme cardslider max-360 my-2"
                                            id="cardsliderCarousel"
                                            {...options}>
                                            {eventData?.speakerInfo?.map((speaker: any, idx: any) => (
                                                <div className="item" key={`speaker-${eventData.id}-${idx}`}>
                                                    <div className="row g-2">
                                                        <div className="col-auto">
                                                            <span className="icon_bluecir">
                                                                {!isEmpty(speaker?.picture) && (
                                                                    <img src={speaker?.picture} className="img-fluid w-auto" />
                                                                )}
                                                                {isEmpty(speaker?.picture) && (
                                                                    <img
                                                                        src={`${process.env.REACT_APP_IMAGE_BASEPATH}/speech_icon.jpg`}
                                                                        className="img-fluid w-auto"
                                                                    />
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row g-2">
                                                                <div className="col">
                                                                    <span className=" fst-italic text-dark-blue">
                                                                        <b className="font-16 font-500 fst-normal d-block line-height-16">
                                                                            {toSentenceCase(speaker.name)},
                                                                        </b>
                                                                        <span className="font-14 d-block line-height-16 font-400">
                                                                            {speaker.designation},{' '}
                                                                            {!isEmpty(speaker.organization)
                                                                                ? getDomainValueForCode(
                                                                                      speaker.organization,
                                                                                      'INSTITUTE_DOMAIN',
                                                                                      state
                                                                                  )
                                                                                : speaker?.organizationManual}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </OwlCarousel>
                                    )}

                                    {[2, 6].includes(parseInt(eventData?.eventType)) && eventData?.relatedInstitute?.length > 0 && (
                                        <div className="row g-2 my-2 align-items-center">
                                            <div className="col-auto">
                                                <span className="icon_bluecir">
                                                    <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/speech_icon.jpg`} />
                                                </span>
                                            </div>
                                            <div className="col">
                                                <div className="row g-2 align-items-center">
                                                    <div className="col">
                                                        <span className=" fst-italic text-dark-blue">
                                                            <b className="font-16 font-500 fst-normal d-block line-height-16">
                                                                {eventData?.relatedInstitute?.length} Universities
                                                            </b>
                                                        </span>
                                                    </div>
                                                    <div className="col-auto">
                                                        <a
                                                            href="javascript:;"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#universities_list"
                                                            className="btn btn-border-gray height-28 line-height-26 d-inline-flex font-500 font-14 text-gray-41 text-decoration-none justify-content-center"
                                                            onClick={() => {
                                                                handleViewList(eventData?.relatedInstitute);
                                                            }}>
                                                            <span>View List</span>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {!isEmpty(eventData?.videoLink?.trim()) && (
                                        <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                            <a
                                                className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                role="button"
                                                onClick={() => openVideoModal(eventData.videoLink)}>
                                                <span>Watch</span>
                                            </a>
                                            <br />
                                        </p>
                                    )}

                                    {isEmpty(eventData?.videoLink?.trim()) && eventData?.eventUpcoming == '0' && (
                                        <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                            <a
                                                className="btn btn-green disable height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                style={{ cursor: 'not-allowed' }}
                                                data-bs-toggle="tooltip"
                                                title="Coming soon..."
                                                role="button">
                                                <span>Watch</span>
                                            </a>
                                            <br />
                                        </p>
                                    )}

                                    {eventData?.eventUpcoming == '1' && eventData?.regStatus == 'yes' && !isEmpty(eventData?.regLink) && (
                                        <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                            <a
                                                className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                role="button"
                                                onClick={() => window.open(eventData?.regLink, '_blank', 'noopener,noreferrer')}>
                                                <span>Register</span>
                                            </a>
                                            {eventData?.remainingDays > 0 && (
                                                <span className="text-danger">{eventData?.remainingDays} Days left!</span>
                                            )}

                                            <br />
                                        </p>
                                    )}

                                    {eventData?.isPublished == 'no' && (
                                        <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                            <a
                                                className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                role="button"
                                                onClick={() =>
                                                    (window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/event/add/${eventData.id}`)
                                                }>
                                                <span>Edit</span>
                                            </a>
                                            <br />
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            id="video_pop"
                            className={`modal fade vh-100 ${isVideoModalOpen ? 'show' : ''}`}
                            tabIndex={-1}
                            role="dialog"
                            style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                            <div className="modal-dialog modal-lg " role="document">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <button type="button" className="btn-close" onClick={closeVideoModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div className="ratio ratio-16x9">
                                            <div id="youtube-player"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isPublished != 1 && (
                    <div>
                        <button type="button" className="btn btn-green my-2 my-sm-0 mx-2 height-45 px-5 mx-auto" onClick={handlePublish}>
                            <span className="px-3">Publish</span>
                        </button>
                    </div>
                )}
                <ListPopup id="universities_list" />
            </div>
        </div>
    );
};

export default EventPreviewControl;
