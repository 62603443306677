import { useContext, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, handleControlValueChange } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import './custom-control.css';
import ErrorControl from './ErrorControl';

const SelectControlV3 = ({ control, dataKey, parentDataKey }: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const data = getControlValueFromState(dataKey, state as State, []);
    const autoGrow = control?.props?.customProperties?.autoGrow ?? true;
    const [customHeight, setCustomHeight] = useState('45px');
    const [Limit, setLimit] = useState(control?.props?.customProperties?.limit ?? 100);

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });
    }, []);

    const parentData = control.props.parentId && getControlValueFromState(parentDataKey + '.' + control.props.parentId, state as State);
    let controlDomain: any = (state?.domain?.get(control.props.domainCategoryCode) || []).filter((domain: DomainElement) => {
        if (
            parentData == null ||
            control.props.parentId === null ||
            control.props.parentId === undefined ||
            control.props.parentId.length === 0
        )
            return true;
        else return domain.parentCode == parentData;
    });

    const options = useMemo(() => {
        const domainOptions = controlDomain.map((domain: any) => ({ value: domain.code, label: domain.value }));
        return [{ value: 'select_all', label: 'Select All' }, ...domainOptions];
    }, [controlDomain]);

    // const selectedValue = useMemo(() => {
    //     const tempVal = controlDomain.filter((domain: any) =>
    //         data?.some((item: any) => item[control.id] == domain.code)
    //     ) as DomainElement[];
    //     if (autoGrow) setCustomHeight(`${45 + tempVal.length * 0.4 * 40}px`);
    //     return tempVal.map((item) => ({ value: item.code, label: item.value }));
    // }, [data]);

    const selectedValue = useMemo(() => {
        const tempVal = controlDomain.filter((domain: any) =>
            Array.isArray(data) && data.some((item: any) => item[control.id] == domain.code)
        ) as DomainElement[];
        if (autoGrow) setCustomHeight(`${45 + tempVal.length * 0.4 * 40}px`);
        return tempVal.map((item) => ({ value: item.code, label: item.value }));
    }, [data]);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            height: customHeight,
            overflow: 'auto',
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: customHeight,
            padding: '0 6px',
        }),
        input: (provided: any) => ({
            ...provided,
            margin: '0px',
        }),
        indicatorsContainer: (provided: any) => ({
            ...provided,
            height: customHeight,
        }),
        option: (provided: any, controlState: any) => ({
            ...provided,
            color: controlState.isSelected ? 'text-white' : provided.color,
            backgroundColor: controlState.isSelected ? 'bg-primary' : provided.backgroundColor,
        }),
    };

    const handleOnChange = (selectedOptions: any) => {
        if (!selectedOptions) {
            handleControlValueChange({ control, value: [], dataKey, parentDataKey, state, dispatch });
            if (autoGrow) setCustomHeight('45px');
            return;
        }

        const isSelectAll = selectedOptions.some((option: any) => option.value === 'select_all');
        if (isSelectAll) {
            const allOptions = controlDomain.map((domain: any) => ({
                [control.id]: domain.code,
            }));
            handleControlValueChange({ control, value: allOptions, dataKey, parentDataKey, state, dispatch });
            setCustomHeight(`${45 + allOptions.length * 0.4 * 40}px`);
        } else {
            let newOptionsSelected = [];
            for (let i = 0; i < selectedOptions.length; i++) {
                newOptionsSelected.push({ [control.id]: selectedOptions[i].value });
            }
            handleControlValueChange({ control, value: newOptionsSelected, dataKey, parentDataKey, state, dispatch });
            if (autoGrow) setCustomHeight(`${45 + selectedOptions.length * 0.4 * 40}px`);
        }
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div
                className="custom-input-group custom-input-wrap"
                style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', height: customHeight, alignItems: 'stretch' }}>
                <div
                    className="custom-input-group-text bg-white d-flex align-items-center justify-content-center border"
                    style={{ padding: '0.375rem 0.75rem' }}>
                    <i className="bi bi-search fs-6"></i>
                </div>
                <Select
                    isMulti
                    name="colors"
                    onChange={handleOnChange}
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={selectedValue}
                    components={{ DropdownIndicator: null, IndicatorSeparator: null }}
                    styles={customStyles}
                    isOptionDisabled={() => state.data[dataKey]?.length >= Limit}
                    isClearable={true}
                />
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectControlV3;
