import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../../library/Core/SmartContext';
import { isEmpty } from '../../library/Core/SmartFunctions';
import { Modal } from 'bootstrap';

interface ListPopupProps {
    id: string;
}

const ListPopup: React.FC<ListPopupProps> = ({ id }) => {
    const { state, dispatch } = useContext(SmartContext);
    const [modalInstance, setModalInstance] = useState<Modal | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const data = state?.internal?.listPopup?.data || [];
    const count = data.length;

    useEffect(() => {
        // // Ensure the modal element exists before initializing
        // if (modalRef.current && !modalInstance) {
        //     const instance = new Modal(modalRef.current, { backdrop: true }); // Ensure `backdrop` is set correctly
        //     setModalInstance(instance);
        // }

        // // Show or hide the modal based on state
        // if (modalInstance) {
        //     if (state.internal.isListModalOpen) {
        //         modalInstance.show();
        //     } else {
        //         modalInstance.hide();
        //     }
        // }

        if (modalRef.current && state.internal?.isListModalOpen) {
            const modal = new Modal(modalRef.current);
            modal.show();
        }
    }, [state.internal?.isListModalOpen]);

    // useEffect(() => {
    //     if (modalRef.current && !modalInstance) {
    //         const instance = new Modal(modalRef.current, { backdrop: true }); // Ensure `backdrop` is set correctly
    //         setModalInstance(instance);
    //     }
    // }, [modalRef, modalInstance]);

    const handleClosePopup = () => {
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: false } });

        if (modalInstance) {
            modalInstance.hide();
        }
    };

    return (
        <div
            className="modal fade px-3"
            id={id}
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            style={{ display: 'none', background: 'rgba(0,0,0,0.7)'}}
            aria-modal="true"
            
            ref={modalRef}>
            <div className="modal-dialog modal-dialog-centered max-600 mx-auto">
                <div className="modal-content">

                    <div className="modal-header border-0 bg-lighter-blue ps-4">
                        <h5 className="font-20 font-600 py-2 mb-0" id="staticBackdropLabel">
                            <i className="me-2 d-flex align-items-center">
                                {state.internal?.listPopup?.icon && (
                                    <img src={state.internal.listPopup.icon} width="30" alt="college icon" />
                                )}
                            </i>
                            {state?.internal?.listPopup?.title} ({count})
                        </h5>
                        <button
                            type="button"
                            onClick={handleClosePopup}
                            className="btn-close m-2 mt-2 me-1 p-0 position-absolute right20 top20 z9"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div className="colleges-list">
                            <ul>
                                {data.map((item: any, index: number) => (
                                    <li key={index}>
                                        <div className="logoImg">
                                            <img
                                                src={
                                                    !isEmpty(item.logo) ? item.logo : `${process.env.REACT_APP_IMAGE_BASEPATH}/building.svg`
                                                }
                                                width={90}
                                                alt={item.name}
                                                className="img-fluid rounded h-auto"
                                            />
                                        </div>
                                        <div className="logoName d-flex">
                                            <div className="d-flex align-items-center justify-content-between w-100">
                                                <div className="d-flex flex-column">
                                                    <p className="me-2">{item.name}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListPopup;
