// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfigtheme.json';
import PageConfigtheme_institute_admin from './PageConfigtheme_institute_admin.json';

import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';
import { format, isValid } from 'date-fns';

class PreviewService {
    static userStatusCode: string;
    static studentId: string;
    static userTypeCode: string;
    static genderCode: string;
    static instituteLogo: string;
    static instituteGroupId: string;
    // constructor() {
        // IIFE (Immediately Invoked Function Expression)
        // (() => {
        //     const { sessionState } = useContext(SessionContext);
        //     PreviewService.userStatusCode = sessionState?.userStatusCode as any;
        //     PreviewService.studentId = sessionState?.studentId as any;
        //     PreviewService.userTypeCode = sessionState?.userTypeCode as any;
        //     PreviewService.genderCode = sessionState?.genderCode as any;
        //     PreviewService.instituteLogo = sessionState?.instituteLogo as any;
        //     PreviewService.instituteGroupId = sessionState?.instituteGroupId as any;
        //     //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        // })();

        // Rest of the constructor
    // }

    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, pageName } = params;

        return Promise.all([this.getPageConfig(), PreviewService.get(eventId)])
            .then((values) => {
                state.formConfig = values[0];
                state.data = values[1];
                state.routeInfo = { pageName };
                //return state; // Resolve the Promise with the state object
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};
        const userTypeCode = localUserData?.userTypeCode;
        if (userTypeCode === 'INSTITUTE_ADMIN') {
            return Promise.resolve(PageConfigtheme_institute_admin);
        }

        return Promise.resolve(pageConfig);
    }

    static async get(eventId: any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        let BasicInfo = { };

        if (eventId) {
            // BasicInfo = (await axiosClient().get(`${process.env.REACT_APP_LEAD_SERVER}/marketevent/geteventinfo/${eventId}`))?.data;            
            BasicInfo = (await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/basic-info/${eventId}`))?.data;            
            BasicInfo = PreviewService.toEventto(BasicInfo);
        }

        return Promise.resolve(BasicInfo);
    }

    static toEventto(eventinfo: any) {
            const dateFromDate = new Date(eventinfo.from);
            const dateToDate = eventinfo.to ? new Date(eventinfo.to) : '';
            // Format the date and time as required by MySQL (YYYY-MM-DD HH:MM:SS)
    
            const formattedFromDateTime = format(dateFromDate, 'yyyy-MM-dd HH:mm:ss');
            const formattedToDateTime = dateToDate && isValid(dateToDate) ? format(dateToDate, 'yyyy-MM-dd HH:mm:ss') : '';
    
            eventinfo.activeEvent = eventinfo.status == 'no' ? 0 : 1;
            eventinfo.dateFrom = formattedFromDateTime;
            eventinfo.dateTo = formattedToDateTime;
            eventinfo.registrationsActive = eventinfo.regStatus == 'yes' ? 1 : 0;
            eventinfo.clientsOnly = eventinfo.clientsOnly == 'yes' ? true : false;
            eventinfo.showInSuperCounsellor = eventinfo.showInSuperCounsellor == 'yes' ? true : false;
            // eventinfo.registrationLink = eventinfo.regLink;
            eventinfo.eventType = String(eventinfo.eventType);
            eventinfo.instituteType = eventinfo.instituteType.map((item: any) => {
                return {instituteType: item}
            });
    
            return eventinfo;
        }

    
   
}
export default PreviewService;
