import { useContext, useEffect, useRef, useState } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, getParentData, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { DomainElement, SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import { v4 as uuidv4 } from 'uuid';

const SelectControlForOthers = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey, handleChangeEvent } = { ...args };
    const [domainCategoryCode, alterativeDomainCategoryCode] = control.props.domainCategoryCode.split('#');
    let topOption = new Set();
    const manualEntryColumn = control?.props?.customProperties?.manualEntryColumn;

    const readOnly =
        control.readOnly ||
        evaluateExpression(control.readOnlyExpression, state?.data, getControlValueFromState(parentDataKey as string, state as State));

    let data = getControlValueFromState(dataKey, state as State);

    const customSelectText = control.props?.customProperties?.customSelectText ?? 'Select';
    const parentData = control.props.parentId && getParentData(parentDataKey + '.' + control.props.parentId, state as State);

    const formControlRef = useRef(null); // For ErrorControl reference

    const [isOtherSelected, setIsOtherSelected] = useState(false); // Default state is false
    const [otherValue, setOtherValue] = useState(""); // State for the "Other" input field value

    let controlDomain =
        (state?.domain?.get(domainCategoryCode) as DomainElement[])?.filter((domain: DomainElement) => {
            if (
                parentData == null ||
                control.props.parentId === null ||
                control.props.parentId === undefined ||
                control.props.parentId.length === 0
            )
                return true;
            else return domain.parentCode == parentData;
        }) ?? [];

    if (alterativeDomainCategoryCode != null && controlDomain.length === 0)
        controlDomain = state?.domain?.get(alterativeDomainCategoryCode) as DomainElement[];

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        const selectedLabel = controlDomain.find((domain) => domain.code === data)?.value || '';
        if (selectedLabel === 'Others' || selectedLabel === 'State Police Force') {
            setIsOtherSelected(true);
        } else {
            setIsOtherSelected(false);
        }
        const initialOtherValue = getControlValueFromState(`${parentDataKey}.${manualEntryColumn}`, state as State) || "";
        setOtherValue(initialOtherValue);
    }, []);

    const getSelectOptions = () => {
        const getAllOptions = () => {
            return controlDomain
                .filter((domain) => ![...topOption].includes(domain.code))
                .map((domain, index) => (
                    <option key={`${index}.${domain.value}.${domain.parentCode}`} value={domain.code} defaultValue={data}>
                        {domain.value}
                    </option>
                ));
        };

        if (!isEmpty(control.props.topOptionsGroupLabelInSelect)) {
            controlDomain
                .filter((item: DomainElement) => item.displayOrder > 0)
                .forEach((item: DomainElement) => {
                    topOption.add(item.code);
                });
        }
        if (isEmpty(control.props.topOptionsGroupLabelInSelect)) {
            return getAllOptions();
        }

        return (
            <>
                <optgroup
                    key={`${control.id}-${control.props.topOptionsGroupLabelInSelect}`}
                    label={control.props.topOptionsGroupLabelInSelect}>
                    {controlDomain
                        .filter((item: DomainElement) => item.displayOrder > 0)
                        .sort((curr: DomainElement, next: DomainElement) => curr.displayOrder - next.displayOrder)
                        .map((domain, idx) => (
                            <option key={`${control.id}-${domain.value}-${idx}-top`} value={domain.code} defaultValue={data}>
                                {domain.value}
                            </option>
                        ))}
                </optgroup>
                <optgroup key={`${control.id}----------------------`} label="----------------------">
                    {getAllOptions()}
                </optgroup>
            </>
        );
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;    
        handleControlValueChange({ control, value: selectedValue, dataKey, parentDataKey, state, dispatch });

        const selectedOption = event.target.selectedOptions[0];
        const selectedLabel = selectedOption?.label || selectedOption?.textContent;

        console.log("selectedLabel",selectedLabel)
        if (selectedLabel === 'Others' || selectedLabel === 'State Police Force') {
            setIsOtherSelected(true); 
        } else {
            setIsOtherSelected(false);
            setOtherValue('');
            handleControlValueChange({
                control,
                value: '',
                dataKey: `${parentDataKey}.${manualEntryColumn}`, 
                parentDataKey,
                state,
                dispatch
            });
        }
    };
    
    const handleOtherCompanyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setOtherValue(newValue);
        handleControlValueChange({
            control,
            value: newValue,
            dataKey: `${parentDataKey}.${manualEntryColumn}`, 
            parentDataKey,
            state,
            dispatch
        });
    };

    const getTextControl = () => {
        return (
            <input
                id={manualEntryColumn}
                data-testid={manualEntryColumn}
                type={'text'}
                disabled={readOnly ?? false}
                className={`form-control pl-0`}
                placeholder={control.props?.placeholder}
                value={otherValue} 
                onChange={(event) => handleOtherCompanyChange(event)}
                minLength={control.props?.minLength}
                maxLength={control.props?.maxLength}
                min={control.props?.min}
                max={control.props?.max}
                ref={formControlRef}
            />
        );
    };
    
    return (
        <>
            {control.props.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} `}
                </label>
            )}
            <select
                key={uuidv4()}
                id={control.id}
                name={dataKey}
                className={`form-select`}
                value={data ?? control.props.defaultValue}
                required={control.props.required}
                disabled={readOnly ?? false}
                onChange={handleSelectChange}
                ref={formControlRef}>
                {!controlDomain?.some((domain) => domain.code === '') && <option value={''}>{customSelectText}</option>}
                {getSelectOptions()}
            </select>
            
            {/* If the label is "Others" or "State Police Force", show the input field */}
            {isOtherSelected && (
                <div className="row mt-3">
                    <label htmlFor={control.id} className="form-label w-100">
                        Enter Other Organization
                    </label>
                    <div className="col-md-12">
                        <div className="input-group mb-3">
                            {getTextControl()}
                        </div>
                    </div>
                </div>
            )}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectControlForOthers;
