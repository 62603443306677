import { useContext, useEffect } from 'react';
import { DispatchEvent, State } from '../../../../../../library/Core/SmartTypes';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import PageBuilder from '../../../../../../library/Builders/PageBuilder/PageBuilder';
import EditDemographicssFormLayout from './EditDemographicssFormLayout';
import ProfileMarkerService from './ProfileMakerService';
import { isEmpty, isFormValid, setError } from '../../../../../../library/Core/SmartFunctions';

const EditDemographicssModal = ({ userdata,DemographicsProps }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { saveMarker,fetchUserProfileMarkers } = ProfileMarkerService();
    const handleSubmit = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();
        const payload = {
            markerType: 'demographics',
            ...state?.data?.demographics,
        };

        // const isSyncDataInvalid = validateSyncData(state, dispatch, DemographicsProps);
        if (!isFormValid(state, dispatch)) {
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }
        
        await saveMarker({...payload,syncData : state?.data?.syncData?.[0] || {}}, state?.internal?.model?.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showDemoGraphicsModal' } });
    };

    const validateSyncData = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void, DemographicsProps: any) => {
        const demographics = state?.data?.demographics || {};
        const syncData = state?.data?.syncData?.[0] || {};
        let isFormInvalid = false;
    
        Object.keys(DemographicsProps).forEach((key) => {
            const demographicsKey = key as keyof typeof DemographicsProps;
            const prop = DemographicsProps[demographicsKey];
    
            if (prop.isComment) {
                const commentField = prop.isComment as keyof typeof demographics;
                    console.log("syncData[key]",syncData[key])
                // Validation for genderCode or other fields where the comment is required
                if (key === 'genderCode' && demographics[key] !== syncData[key] && isEmpty(demographics[commentField])) {
                    isFormInvalid = true;
                    setError(
                        `demographics.${String(commentField)}`,
                        [`Please enter a comment for "${prop.label}" because the value has changed.`],
                        dispatch
                    );
                }if (
                    (key === 'StudiedAbroad' &&
                        (demographics[key] != syncData['studied_abroad_ug_flag'] ||
                         demographics[key] != syncData['studied_abroad_pg_flag'])) &&
                    isEmpty(demographics[commentField])
                ) {
                    isFormInvalid = true;
                    setError(
                        `demographics.${String(commentField)}`,
                        [`Please enter a comment for "${prop.label}" because the value has changed.`],
                        dispatch
                    );
                }  else {
                    setError(`demographics.${String(commentField)}`, [], dispatch);
                }
            }
        });
    
        return isFormInvalid;
    };
    

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSubmit } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditDemographicssFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditDemographicssFormLayout>
        </SmartContext.Provider>
    );
};

export default EditDemographicssModal;
