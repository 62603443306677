import { useContext, useEffect, useRef, useState } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { evaluateExpression, getControlValueFromState, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import logger from '../Core/logger';
import { axiosClient } from '../Service/axiosClient';
import ErrorControl from './ErrorControl';

const SmartFileUploader = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);
    const isHidden = evaluateExpression(control.hideExpression, state?.data);
    const customProperties = control?.props?.customProperties;
    const [invalidFile, setInvalidFile] = useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [imagelink, setImageLink] = useState('');
    const [doclink, setDocLink] = useState('');
    const imgArray = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const validExtensions = customProperties?.validExtensions ?? [...imgArray, 'pdf', 'doc', 'docx'];

    // Ref for file input
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    if (data && !imagelink && !doclink) {
        const extension = data.split('.').at(-1);
        if (imgArray.includes(extension.toLowerCase())) {
            setImageLink(data);
            setDocLink('');
        } else {
            setDocLink(data);
            setImageLink('');
        }
    }

    useEffect(() => {
        if (invalidFile) {
            const timer = setTimeout(() => {
                setInvalidFile(false);
            }, 3000);

            // Cleanup function to clear the timer when the component is unmounted
            return () => clearTimeout(timer);
        }
    }, [invalidFile]);

    const handleFileControlUpload = async (state: State, file: File) => {
        logger.log(state?.data);

        const extension = file?.name.split('.').at(-1)?.toLowerCase(); // Optional chaining

        if (!extension || !validExtensions.includes(extension)) {
            setInvalidFile(true);
            return;
        }

        const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/get-attached`;

        const formData = new FormData();
        formData.append('pdf_doc', file);

        try {
            const response = await axiosClient().post(apiUrl, formData);
            if (response.status === 400) {
                alert(response.data.message);
                return;
            }

            const link = response.data.link;
            if (imgArray.includes(link.split('.').at(-1).toLowerCase())) {
                setImageLink(link);
                setDocLink('');
            } else {
                setDocLink(link);
                setImageLink('');
            }

            handleControlValueChange({ control, value: link, dataKey, parentDataKey, state, dispatch });
        } catch (error) {
            console.error("An error occurred:", error);
            alert("Error uploading file");
        }
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            handleFileControlUpload(state as State, file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(true);
        event.dataTransfer.dropEffect = 'copy'; // Change cursor text to "Copy"
    };

    const handleDragLeave = () => {
        setDragOver(false);
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            handleFileControlUpload(state as State, event.target.files[0]);
        }
    };

    const handleBrowseClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input click event
        }
    };

    if (isHidden) return <></>;

    return (
        <div className="w-100">
            <label htmlFor="formFileLg" className="form-label">
                {control.props.label}
            </label>

            <div
                className={`upload_section ${dragOver ? 'drag-over' : ''}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
            >
                <input
                    ref={fileInputRef}
                    id={control.id}
                    data-testid={control.id}
                    type="file"
                    hidden={control.props?.isCode || isHidden}
                    className="form-control form-control-lg pl-0 d-none"
                    accept=".jpg, .jpeg, .png, .gif, .bmp, .webp, .pdf, .doc, .docx"
                    name="cover"
                    onChange={handleFileInputChange}
                />
                <div className="row g-2 align-items-center justify-content-center">
                    <div className="col-sm d-flex align-items-center justify-content-center">
                        <div className="cloud-wrap px-3 px-sm-5 mx-auto">
                            <div className="cloud-img">
                                <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/upload.svg`} alt="" />
                            </div>
                            <div className="cloud-text">
                                {dragOver ? (
                                    <span>Drop here</span> // Display "Drop" text when dragging over
                                ) : (
                                    <span>
                                        Drag and drop file here or <u onClick={handleBrowseClick} className="text-sky-blue">Browse</u>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-end font-14 d-block text-dark-blue opacity-7">
                <span className={`d-inline-block ${invalidFile ? 'shake-err' : ''}`}>Only {validExtensions.join(', ')} files.&nbsp;</span>
                {!isEmpty(customProperties?.infoLine) && (
                    <span>{customProperties?.infoLine}</span>
                )}
            </div>

            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            {imagelink && <img src={imagelink} width="160" alt="" className="img-fluid" />}
            {doclink && <a href={doclink} target='_blank' rel="noopener noreferrer">View File</a>}
        </div>
    );
};

export default SmartFileUploader;
