import { useContext, useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, isEmpty } from '../Core/SmartFunctions';
import ErrorControl from './ErrorControl';

const SelectEditable = (args) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(data ? { value: data, label: data } : null);
    const [domainCategoryCode, alterativeDomainCategoryCode] = control.props.domainCategoryCode.split('#');
    const manualEntryColumn = control?.props?.customProperties?.manualEntryColumn;
    const manualEntryData = getControlValueFromState(`${parentDataKey}.${manualEntryColumn}`, state);
    const parentData = control.props.parentId && getControlValueFromState(parentDataKey + '.' + control.props.parentId, state);
    console.log("parentData",parentData)
    
    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey, state)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        const domainOptions = getDomainOptions();
        console.log("domainOptions",domainOptions)
        setSelectedOption(domainOptions.find((option) => option.value == data) ?? { value: manualEntryData, label: manualEntryData });
        setOptions(domainOptions);
    }, [parentData]);

    const getDomainOptions = () => {
        if (isEmpty(parentData)) {
            return state?.domain
                ?.get(domainCategoryCode)
                .map((domain) => ({ value: domain.code, lowerLabel: domain.value?.toLowerCase() ?? "", label: domain.value }));
        }

        let controlDomain = state?.domain?.get(domainCategoryCode).filter((domain) => {
            if (
                parentData == null ||
                control.props.parentId === null ||
                control.props.parentId === undefined ||
                control.props.parentId.length === 0
            )
                return true;
            else return domain.parentCode == parentData;
        });

        return controlDomain.map((domain) => ({ value: domain.code, lowerLabel: domain.value?.toLowerCase(), label: domain.value }));
    };

    const loadOptions = (inputValue, callback) => {
        console.log(inputValue, 'inputValue data')
        //inputValue.length < 3 ? callback([]) : callback(options.filter((option) => option.lowerLabel.includes(inputValue.toLowerCase())));
        // callback(options.filter((option) => option.lowerLabel.includes(inputValue.toLowerCase())));

        console.log(inputValue, 'inputValue data');
        if (inputValue.length < 3) {
            callback([]);
        } else {
            const filteredOptions = options.filter(
                (option) => option.lowerLabel && option.lowerLabel.includes(inputValue.toLowerCase())
            );
            callback(filteredOptions);
        }

    };

    const processOptionValue = (optionValue) => {
        // setSelectedOption({ value: optionValue, label: optionValue });
        let selectedOptionExistsInOptions = options.some((opt) => opt.value === optionValue);
        let activePayload, inactivePayload;

        if (!selectedOptionExistsInOptions) {
            // if the selected option doesn't exist in the options array,
            // set the payload to manualEntryColumn
            activePayload = { dataKey: `${parentDataKey}.${manualEntryColumn}`, name: control.id, value: optionValue, errorMessages: [] };
            inactivePayload = { dataKey, name: control.id, value: null, errorMessages: [] };
        } else {
            // if the selected option does exist in the options array,
            // set the payload to the regular column
            activePayload = { dataKey, name: control.id, value: optionValue, errorMessages: [] };
            inactivePayload = { dataKey: `${parentDataKey}.${manualEntryColumn}`, name: control.id, value: null, errorMessages: [] };
        }

        // Dispatch the active and inactive payloads
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: activePayload });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: inactivePayload });

        setSelectedOption(options.find((option) => option.value === optionValue) ?? { value: optionValue, label: optionValue });
    };

    const handleChange = (option, actionMeta) => {
        processOptionValue(option?.value);
    };

    const handleInputChange = (inputValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
            if (inputValue.length < 3) return;
            processOptionValue(inputValue);
        }
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-text bg-white rounded-start">
                    <i className="bi bi-search fs-6"></i>
                </div>
                <div className="flex-fill form-control border-start-0 p-0" style={{}}>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        value={selectedOption}
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        isClearable
                        isSearchable
                        // defaultOptions={selectedOption && selectedOption?.value?.length >= 3 ? options : []}
                        cacheOptions
                        // noOptionsMessage={({ inputValue }) => (inputValue.length < 3 ? 'Enter minimum three characters' : 'No options')}
                        noOptionsMessage={({ inputValue }) => {
                            if (selectedOption && !inputValue) {
                                return selectedOption.label;
                            }
                            if (inputValue.length < 3) {
                                return 'Enter minimum three characters';
                            }
                            return 'No options';
                        }}    
                    />
                </div>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectEditable;
