import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useEvents from './useEvents';
import SessionContext from '../../../library/Core/SessionContext';

const EventTabs = () => {
    const { state, dispatch } = useContext(SmartContext);
    const counts = state.data?.counts;
    const { sessionState } = useContext(SessionContext);

    const { performUpdateFilters } = useEvents();

    const handleTabSelect = (tab: string) => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'searchBy', value: tab } });
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'prevSearchBy', value: state?.data?.searchBy } });
        performUpdateFilters(tab);
    };

    return (
        <div className="row job_internship_tab polartabsec">
            <div className="xscroll p-0">
                <ul
                    className="nav nav-tabs aos-init aos-animate"
                    id="JobInternshipTab"
                    role="tablist"
                    data-aos="fade-left"
                    data-aos-delay="900">
                    <li className="nav-item" role="presentation">
                        <a style={{ textDecoration: 'none' }}>
                            <button
                                className={`nav-link ${state?.data?.searchBy === 'upcoming' ? ' active ' : ''}`}
                                id="UpcomingEvents-tab"
                                type="button"
                                role="tab"
                                onClick={() => handleTabSelect('upcoming')}>
                                Upcoming <span id="UpcomingCount">({counts?.upcomingEvents ?? 0})</span>
                            </button>
                        </a>
                    </li>

                    <li className="nav-item" role="presentation">
                        <a style={{ textDecoration: 'none' }}>
                            <button
                                className={`nav-link ${state?.data?.searchBy === 'past' ? ' active ' : ''}`}
                                id="PastEvents-tab"
                                type="button"
                                role="tab"
                                onClick={() => handleTabSelect('past')}>
                                Past <span id="PastCount">({counts?.pastEvents ?? 0})</span>
                            </button>
                        </a>
                    </li>

                    {sessionState?.userTypeCode == 'INSTITUTE_ADMIN' && (
                        <li className="nav-item" role="presentation">
                            <a style={{ textDecoration: 'none' }}>
                                <button
                                    className={`nav-link ${state?.data?.searchBy === 'your-drafts' ? ' active ' : ''}`}
                                    id="PastEvents-tab"
                                    type="button"
                                    role="tab"
                                    onClick={() => handleTabSelect('your-drafts')}>
                                    Your Drafts <span id="PastCount">({counts?.yourDrafts ?? 0})</span>
                                </button>
                            </a>
                        </li>
                    )}
                    
                    {sessionState?.userTypeCode == 'INSTITUTE_ADMIN' && (
                        <li className="nav-item" role="presentation">
                            <a style={{ textDecoration: 'none' }}>
                                <button
                                    className={`nav-link ${state?.data?.searchBy === 'created-by-you' ? ' active ' : ''}`}
                                    id="PastEvents-tab"
                                    type="button"
                                    role="tab"
                                    onClick={() => handleTabSelect('created-by-you')}>
                                    Created By You <span id="PastCount">({counts?.createdByYou ?? 0})</span>
                                </button>
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default EventTabs;
