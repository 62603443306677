import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import DebugBarControl from '../../../library/SimpleControls/DebugBarControl';
import AlumniEngagementGrid from './AlumniEngagementGrid';
import Layout from './Layout';
import { addUserSpecificDomains } from '../../../library/Service/domainService';

const AlumniEngagement = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        modalFlags: {
            showEmailModal: false,
            showUserModal: false,
            showEmailPreviewModal: false,
            showWhatsAppPreviwModal: false,
            showSentHistoryModal: false,
        },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { formConfig, data, internal, routeInfo } = useLoaderData() as State;

    const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

    const workStatusCodes = domain.get('USER_STATUS_CODE');
    if (workStatusCodes) {
        const filteredworkstatusCode = workStatusCodes.filter((status) => status.code !== 'PENDING_EMAIL_VERIFICATION');
        domain.set('USER_STATUS_CODE', filteredworkstatusCode);
    }

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, 
                data, 
                // domain: new Map([...globalState.domain]), 
                domain,
                internal, 
                routeInfo },
        });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <DebugBarControl />
            {!state.flags.isDataLoading && (
                <Layout>
                    {/* Please check layout for the <SearchCriteria /> */}
                    <AlumniEngagementGrid />
                </Layout>
            )}
        </SmartContext.Provider>
    );
};

export default AlumniEngagement;
