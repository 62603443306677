import { useContext, useEffect, useRef } from 'react';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { debounce, getControlValueFromState, handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Tooltip } from 'bootstrap';

const RichTextAreaControl = (args: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };
    const data = getControlValueFromState(dataKey, state as State);

    const editorStyle = {
        height: '350px'        
    };

    useEffect(() => {
        const errorMessages = validateFormField(
            control,
            data,
            state,
            control?.props?.label,
            dataKey,
            getControlValueFromState(parentDataKey as string, state as State)
        );
        dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey, errorMessages } });

        if (isEmpty(data) && !isEmpty(control.props?.defaultValue)) {
            handleControlValueChange({ control, value: control.props.defaultValue, dataKey, parentDataKey, state, dispatch });
        }
    }, [data, control.props.defaultValue]);

    const handleTextChange = debounce((value: string) => {
        // Function to strip HTML tags and get plain text
        const stripHtmlTags = (html: string) => html.replace(/<[^>]*>?/gm, '');
    
        // Function to truncate HTML content based on visible characters
        const truncateHtml = (html: string, limit: number) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
    
            let visibleTextLength = 0;
            const walker = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT, null);
            const nodesToRemove: Node[] = [];
    
            while (walker.nextNode()) {
                const node = walker.currentNode as Text;
                const nodeText = node.nodeValue ?? '';
    
                if (visibleTextLength + nodeText.length > limit) {
                    const allowedLength = limit - visibleTextLength;
                    node.nodeValue = nodeText.substring(0, allowedLength);
                    visibleTextLength += allowedLength;
                    break;
                } else {
                    visibleTextLength += nodeText.length;
                }
            }
    
            // Remove any remaining nodes after the character limit
            while (walker.nextNode()) {
                nodesToRemove.push(walker.currentNode);
            }
            nodesToRemove.forEach((node) => node.parentNode?.removeChild(node));
    
            return tempDiv.innerHTML;
        };
    
        // Strip tags to get the plain visible text
        const plainText = stripHtmlTags(value);
    
        // If visible text exceeds the limit, truncate the HTML content
        if (control.props?.customProperties?.limit && plainText.length > control.props?.customProperties?.limit) {
            value = truncateHtml(value, control.props?.customProperties?.limit);
        }
    
        // Update the state with the valid value
        handleControlValueChange({ control, value, dataKey, parentDataKey, state, dispatch });
    }, 300); // Debounce by 300ms
        
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl);
    });

    return (
        <>
            <label htmlFor={control.id} className="form-label m-0 mb-1 font-16 font-500 w-100">
                {control.props.label} {control.props?.customProperties?.info && (
                    <i className="bi bi-info-circle-fill" data-bs-toggle="tooltip" title={`${control.props?.customProperties?.info}`}></i>
                )}
            </label>
            <ReactQuill
                id={control.id}
                className="d-flex flex-column"
                style={editorStyle}
                value={data ?? ''}
                onChange={handleTextChange}
                placeholder={control.props.placeholder}
            />
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default RichTextAreaControl;
