import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import SessionContext from '../../../library/Core/SessionContext';
//import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
import { getinstituteList } from '../../../library/Service/domainService';
import Swal from 'sweetalert2';


const AudienceCategory = () => {

    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const userType = sessionState?.userTypeCode;
    const userId = sessionState?.id;
    // const queryParams = new URLSearchParams(window.location.search);
    // const eventId = queryParams.get('id');
    const { eventId } = useParams();
    
    if(!eventId){
        navigate(`/event/add`);
    }

    const { globalState } = useContext(GlobalContext);
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            // SAVE: handleCreate,
            SAVE: handleSaveAndNext,
            SAVE_AND_EXIT: handleSaveAndExit,
            SAVE_AND_NEXT: handleSaveAndNext,
        },
    } as any);

    async function handleSaveAndExit(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        
        try {
            // const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            
            // setTimeout(() => {
                if ( ! isEmpty(resp?.id) && resp?.success == '1' ) {
                    let navigateToPath = `${process.env.REACT_APP_APP_BASE_URL}/events/your-drafts`;
                    // navigate(navigateToPath, { replace: true });
                    window.location.href = navigateToPath;
                }
            // }, 500);
            
        } catch (error) {
            console.error("Error while save and exit:", error);
        }
    }
    const [isSubmitting, setIsSubmitting] = useState(false);

    async function handleSaveAndNext(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        if (isSubmitting) return;

        try {
            setIsSubmitting(true);

            // const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
            const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch).then((res: any) => {
                // setTimeout(() => {
                    if ( ! isEmpty(res?.id) && res?.success == 1 ) {
                        navigate(`/event/theme-options/${res?.id}`);
                    }
                // }, 500);
            });
            // if ( ! isEmpty(resp?.id) && resp?.success == 1 ) {
            //     navigate(`/event/theme-options/${resp?.id}`);
            // }
        } catch (error) {
            console.error("Error while save & next:", error);
        } finally {
            setIsSubmitting(false);
        }
    }
    
    // async function handleCreate(
    //     event: React.SyntheticEvent<HTMLButtonElement>,
    //     state: State,
    //     dispatch: (dispatchEvent: DispatchEvent) => void,
    //     globalState: any,
    //     globalDispatch: (dispatchEvent: DispatchEvent) => void
    // ) {
    const handleCreate = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ): Promise<any> => {
        event.preventDefault();
    
        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

        const isnotValideventaudience = iseventaudiencenotValid(state, dispatch);
        if (isnotValideventaudience ) {
            
            dispatch({ type: 'SHOW_ERRORS' });
            return;
        }
        let retData = {
            id: '',
            success: 0,
            message: `Oops..! Something went wrong, Please try again in a while..!!`
        }

        //let updateaudience = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updateaudienceinfo`;

        const loader	= Swal.fire({
            title: 'Saving...',
            html: `Please wait while we're saving your data..!`,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(); // Show loading spinner
            },
        });
        // Prepare the API endpoint
        const updateaudience = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/audience-info`;

        await axiosClient()
        .post(`${updateaudience}`, {
            eventId: eventId ? eventId : '',
            audienceCategory1: arrayColumn(state.data?.eventaudience?.audienceCategory1 ?? [], 'audienceCategory1'),
            educationLevelCode: state.data?.eventaudience?.educationLevelCode,
            programId: arrayColumn(state.data?.eventaudience?.programId ?? [], 'programId'),
            workingStatusCode: arrayColumn(state.data?.eventaudience?.workingStatusCode ?? [], 'workingStatusCode'),
            instituteType: arrayColumn(state.data?.eventaudience?.instituteType ?? [], 'instituteType'),
            curriculum: arrayColumn(state.data?.eventaudience?.curriculum ?? [], 'curriculum'),
            superCounselor: arrayColumn(state.data?.eventaudience?.superCounselor ?? [], 'superCounselor'),
            CompletionYear12th: arrayColumn(state.data?.eventaudience?.CompletionYear12th ?? [], 'CompletionYear12th'),
            UGCompletionYear: arrayColumn(state.data?.eventaudience?.UGCompletionYear ?? [], 'UGCompletionYear'),
            PGCompletionYear: arrayColumn(state.data?.eventaudience?.PGCompletionYear ?? [], 'PGCompletionYear'),
            doctoralCompletionYear: arrayColumn(state.data?.eventaudience?.doctoralCompletionYear ?? [], 'doctoralCompletionYear'),
            industry: arrayColumn(state.data?.eventaudience?.industry ?? [], 'industry'),
            functionalArea: arrayColumn(state.data?.eventaudience?.functionalArea ?? [], 'functionalArea'),
            programCompletionYear: arrayColumn(state.data?.eventaudience?.programCompletionYear ?? [], 'programCompletionYear')
        })
            .then((res: any) => {
                retData.id = res.data.eventId ?? res.data.id;
                retData.message = res.data.message;
                retData.success = 1;

                if (res.data.success != 1) {
                    retData.message = retData.message ?? 'There is some issue in event management. Please try after sometime!';
                    retData.success = 0;
                }

                Swal.close();

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    icon: `${retData.success == 1 ? 'success' : 'warning'}`,
                    title: `${retData.message}`,
                });

                // navigate(`/event/theme-options/${res.data.eventId}`);
            });
            return retData;
    }

    const iseventaudiencenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {

        const audienceCategory = state?.data?.eventaudience?.audienceCategory1?.map((item: any) => item.audienceCategory1) || [];
        const educationLevelCode = state?.data?.eventaudience?.educationLevelCode || [];
        const programId = state?.data?.eventaudience?.programId?.map((item: any) => item.programId) || [];
        const workingStatusCode = state?.data?.eventaudience?.workingStatusCode?.map((item: any) => item.workingStatusCode) || [];
        const instituteType = state?.data?.eventaudience?.instituteType?.map((item: any) => item.instituteType) || [];
        const superCounselor = state?.data?.eventaudience?.superCounselor?.map((item: any) => item.superCounselor) || [];
        
        let returnVal = false;
    
        // Validate based on the selected themes
        if (audienceCategory.includes('1')) { // Student
            const atLeastOneProgramEntered = state?.data?.eventaudience?.programId?.some((programId: any) => 
                !isEmpty(programId.programId));
            if (!atLeastOneProgramEntered) {
                setError('eventaudience.programId', ['Please select at least one level of program'], dispatch);
                returnVal = true;
            }else {
                setError('eventaudience.programId', [], dispatch);
            }

            if(userType == 'INSTITUTE_ADMIN'){
                return returnVal;
            }

            const atLeastOneInstituteTypeEntered = state?.data?.eventaudience?.instituteType?.some((instituteType: any) => 
                !isEmpty(instituteType.instituteType));
            if (!atLeastOneInstituteTypeEntered) {
                setError('eventaudience.instituteType', ['Please select at least one institute level'], dispatch);
                returnVal = true;
            }else {
                setError('eventaudience.instituteType', [], dispatch);
            }

            const atLeastOneInstituteTypeCurriculumEntered = state?.data?.eventaudience?.instituteType?.some(
                (instituteType: any) =>
                    instituteType.instituteType === 'PRE_SCHOOL' || instituteType.instituteType === 'SCHOOL'
            );

            console.log(atLeastOneInstituteTypeCurriculumEntered, 'atLeastOneInstituteTypeCurriculumEntered')
            
            // Check if at least one curriculum is entered
            const atLeastOneCurriculumEntered = state?.data?.eventaudience?.curriculum?.some(
                (curriculum: any) => !isEmpty(curriculum.curriculum)
            );
            
            // Validate curriculum based on institute type
            if (atLeastOneInstituteTypeCurriculumEntered && !atLeastOneCurriculumEntered) {
                setError('eventaudience.curriculum', ['Please select at least one curriculum'], dispatch);
                returnVal = true;
            }else {
                setError('eventaudience.curriculum', [], dispatch);
            }
            
        }
    
        if (audienceCategory.includes('2')) { // Alumni
            if (userType == 'INSTITUTE_ADMIN') {
                const atLeastOneProgramEntered = state?.data?.eventaudience?.programId?.some((programId: any) => 
                    !isEmpty(programId.programId));
                if (!atLeastOneProgramEntered) {
                    setError('eventaudience.programId', ['Please select at least one level of program'], dispatch);
                    returnVal = true;
                }else {
                    setError('eventaudience.programId', [], dispatch);
                }
                return returnVal;
            }

            const atLeastOneeducationLevelCodeEntered = state?.data?.eventaudience?.educationLevelCode;
            if (!atLeastOneeducationLevelCodeEntered) {
                setError('eventaudience.educationLevelCode', ['Please select education status'], dispatch);
                returnVal = true;
            }

            if (!atLeastOneeducationLevelCodeEntered && atLeastOneeducationLevelCodeEntered === 'PURSUING_STUDIES') {
                setError('eventaudience.programId', ['Please select at least one level of program'], dispatch);
                returnVal = true;
            }
            
            if (atLeastOneeducationLevelCodeEntered === 'COMPLETED_STUDIES') {
                
                const atLeastOneCompletionYear12thStatusEntered = state?.data?.eventaudience?.CompletionYear12th?.some((CompletionYear12th: any) => 
                    !isEmpty(CompletionYear12th.CompletionYear12th));
    
                if (!atLeastOneCompletionYear12thStatusEntered) {
                    setError('eventaudience.CompletionYear12th', ['Please select at least one level of completion year 12th'], dispatch);
                    returnVal = true;
                }

                const atLeastOneUGCompletionYearStatusEntered = state?.data?.eventaudience?.UGCompletionYear?.some((UGCompletionYear: any) => 
                    !isEmpty(UGCompletionYear.UGCompletionYear));
    
                if (!atLeastOneUGCompletionYearStatusEntered) {
                    setError('eventaudience.UGCompletionYear', ['Please select at least one level of working status'], dispatch);
                    returnVal = true;
                }

                const atLeastOnePGCompletionYearStatusEntered = state?.data?.eventaudience?.PGCompletionYear?.some((PGCompletionYear: any) => 
                    !isEmpty(PGCompletionYear.PGCompletionYear));
    
                if (!atLeastOnePGCompletionYearStatusEntered) {
                    setError('eventaudience.PGCompletionYear', ['Please select at least one level of PG completion year'], dispatch);
                    returnVal = true;
                }

                const atLeastOnedoctoralCompletionYearStatusEntered = state?.data?.eventaudience?.doctoralCompletionYear?.some((doctoralCompletionYear: any) => 
                    !isEmpty(doctoralCompletionYear.doctoralCompletionYear));
    
                if (!atLeastOnedoctoralCompletionYearStatusEntered) {
                    setError('eventaudience.doctoralCompletionYear', ['Please select at least one level of doctoral completion year'], dispatch);
                    returnVal = true;
                }
            }

            const atLeastOneworkingStatusEntered = state?.data?.eventaudience?.workingStatusCode?.some((workingStatusCode: any) => 
                !isEmpty(workingStatusCode.workingStatusCode));

            if (!atLeastOneworkingStatusEntered) {
                setError('eventaudience.workingStatusCode', ['Please select at least one level of working status'], dispatch);
                returnVal = true;
            }

            const atLeastOneInstituteTypeEntered = state?.data?.eventaudience?.instituteType?.some((instituteType: any) => 
                !isEmpty(instituteType.instituteType));
            if (!atLeastOneInstituteTypeEntered) {
                setError('eventaudience.instituteType', ['Please select at least one institute level'], dispatch);
                returnVal = true;
            }
        }
    
        if (audienceCategory.includes('6')) { // super Counselor
            console.log('Please select at least one test tag')
            const atLeastOnesuperCounselorEntered = state?.data?.eventaudience?.superCounselor?.some((superCounselor: any) => 
                !isEmpty(superCounselor.superCounselor));
            if (!atLeastOnesuperCounselorEntered) {
                setError('eventaudience.superCounselor', ['Please select at least one super counselor'], dispatch);
                returnVal = true;
            }
        }

        if (audienceCategory.includes('8')) { // super Counselor
            console.log('Please select at least one institute level')
            const atLeastOneInstituteTypeEntered = state?.data?.eventaudience?.instituteType?.some((instituteType: any) => 
                !isEmpty(instituteType.instituteType));
            if (!atLeastOneInstituteTypeEntered) {
                setError('eventaudience.instituteType', ['Please select at least one institute level'], dispatch);
                returnVal = true;
            }
        }
    
        // if (audienceCategory.includes('2') || audienceCategory.includes('4')) { // Career-Course Overview or University Course Overview
        //     console.log('Please select at least one department tag')
        //     const atLeastOneDepartmentEntered = state?.data?.eventaudience?.departmentid?.some((departmentid: any) => 
        //         !isEmpty(departmentid.departmentid));
        //     if (!atLeastOneDepartmentEntered) {
        //         setError('eventaudience.departmentid', ['Please select at least one department tag'], dispatch);
        //         returnVal = true;
        //     }
        // }
    
        //Validate Audience field if data is in array format
        const atLeastOneEventAudienceEntered = audienceCategory.length > 0;
        
        if (!atLeastOneEventAudienceEntered) {
            setError('eventaudience.audienceCategory1', ['Please select at least one audience category'], dispatch);
            returnVal = true;
        }
    
        // If no validation errors, return false to indicate success
        return returnVal;
    };

    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        domain.set('PROGRAM_COMPLETION_YEAR', domain.get('PAST_YEAR_CODE') || []);

        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { eventaudience: data },
                domain,
                routeInfo,
            },
        });
    }, []);

    useEffect(() => {
        if(state.data?.user?.instituteId != undefined){
            // alert('Test')
            const fetchData = async () => {
                try {
                    const response = await axiosClient().post(`${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/user/get-programs-by-institute-id`, {
                        institute_id: state.data?.user?.instituteId,
                    });
                    const programs = response.data.data.programs;
                    let program_values = [];
                    for(let i = 0; i < programs.length;i++)
                    {
                        program_values[i] = {'label' : programs[i]['name'],'value': programs[i]['id']};
                    }
                    
                    dispatch({
                        type: 'SET_DOMAIN',
                        payload: { key: 'INSTITUTE_ATTENDED_PROGRAM_CODE', value: getinstituteList('INSTITUTE_ATTENDED_PROGRAM_CODE', program_values) },
                    });
                } catch (error) {
                    console.error('Error fetching program data:', error);
                }
            }

            fetchData();
        }
    }, [state.data?.user?.instituteId]);

    // useEffect(() => {
        // setProgramCompletionYear(state, dispatch);
    // }, []);

    useEffect(() => {
        if ( isEmpty (state.data?.eventaudience?.audienceCategory1) ) {
            return;
        }
        
        setProgramCompletionYear(state, dispatch);
    }, [state.data?.eventaudience?.audienceCategory1]);

    function setProgramCompletionYear(state: State, dispatch: any) {
        const domainMap = new Map([...globalState.domain]); // Get the domain map from global state.
        let completionYearData: any = [];
    
        // Fetch the past year data from the domain map
        const pastYearData = domainMap.get('PAST_YEAR_CODE') || [];
        // Determine if 'Student' or 'Alumni' are checked
        const isStudentChecked = state.data?.eventaudience?.audienceCategory1?.some((item: any) => item.audienceCategory1 === '1');
        const isAlumniChecked = state.data?.eventaudience?.audienceCategory1?.some((item: any) => item.audienceCategory1 === '2');
    
        // If alumni is checked, add past years data.
        if (isAlumniChecked) {
            completionYearData = [...pastYearData];
        }
    
        // If student is checked, add future years (next 10 years).
        if (isStudentChecked) {
            const currentYear = new Date().getFullYear();
            const futureYears = [];
    
            // Add the next 10 future years to the data
            for (let i = 0; i < 10; i++) {
                futureYears.push({
                    categoryCode: 'PROGRAM_COMPLETION_YEAR',
                    code: (currentYear + i).toString(),
                    value: (currentYear + i).toString(),
                    parentCode: '',
                    displayOrder: i,
                    flexCol: null
                });
            }
    
            // Add future years to completionYearData
            completionYearData = [...futureYears];
        }
    
        // If both are checked, combine both the past year data and future years
        if (isStudentChecked && isAlumniChecked) {
            completionYearData = [...pastYearData, ...completionYearData];
        }
    
        // **Filter out duplicates by 'code' (the year)**
        completionYearData = Array.from(
            new Map(completionYearData.map((item: any) => [item.code, item])).values()
        );
    
        // Dispatch the updated completion years data to the global state
        dispatch({
            type: 'SET_DOMAIN',
            payload: { key: 'PROGRAM_COMPLETION_YEAR', value: completionYearData },
        });

        if ( ! isEmpty(state.data?.eventaudience) ) {
            const selectedOptions = state.data?.eventaudience?.programCompletionYear || [];
            dispatch({
                type: 'REFRESH_DATA',
                payload: {
                    data: {
                        ...state.data,
                        eventaudience: {
                            ...state.data?.eventaudience,
                            programCompletionYear: [...selectedOptions]
                        }
                    },
                },
            });
        }
        
    }
    
    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default AudienceCategory;
