import { useContext, useEffect, useState } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import useManageAlumni from '../../broadcast/student/useManageAlumni';
import ManageLeadService from './ManageReferralService';
import SearchCriteria from './SearchCriteria';
import { useNavigate } from 'react-router';

const Layout = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const [fuzzySearchText, setFuzzySearchText] = useState('');
    const [referralSearchText, setReferralSearchText] = useState('');
    const [showSearchCriteria, setShowSearchCriteria] = useState(true);
    const navigate = useNavigate();
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    // Debounce API call
    useEffect(() => {
        if (!isInitialLoad) {
            const timeoutId = setTimeout(() => {
                handleFuzzySearch();
            }, 500); // Set delay of 500ms

            return () => clearTimeout(timeoutId); // Clear the timeout if input changes
        }
        setIsInitialLoad(false); // Set isInitialLoad to false after the first render
    }, [fuzzySearchText]);

    useEffect(() => {
        if (!isInitialLoad) {
            const timeoutId = setTimeout(() => {
                handleReferralSearch();
            }, 500); // Set delay of 500ms

            return () => clearTimeout(timeoutId); // Clear the timeout if input changes
        }
    }, [referralSearchText]);

    const handleFuzzySearch = () => {
        const searchCriteria = {
            ...state.data.searchCriteria,
            name: fuzzySearchText
        };
        ManageLeadService.search(searchCriteria, state.routeInfo.pageName).then((response: any) =>
            setStateValue('leadList', response.data)
        );
    };

    const handleReferralSearch = () => {
        const searchCriteria = {
            ...state.data.searchCriteria,
            referral: referralSearchText
        };
        ManageLeadService.search(searchCriteria, state.routeInfo.pageName).then((response: any) =>
            setStateValue('leadList', response.data)
        );
    };

    const handleFuzzySearchChange = (event: any) => {
        setFuzzySearchText(event.target.value);
    };

    const handleReferralSearchChange = (event: any) => {
        setReferralSearchText(event.target.value);
    };

    const handleToggleSearchCriteria = () => {
        setShowSearchCriteria(!showSearchCriteria);
    };

    return (
        <div className="main flex-1">
            <section className="updateadditionalinformation">
                <div className="container max-1140 px-lg-0 overflow-hidden">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <div className="white-block white-block-notopborderradius p-0 h-100">
                                <div className="p-4 p-md-5">
                                    <div className="alumni_statistics">
                                    <div className="d-flex justify-content-between align-items-center mb-3 pb-2">
                                        <h3 className="font-35 font-600 mb-0">{state.formConfig?.title}</h3>
                                        <div className="dashboard innerpage smart-scroll scrolled-down">
                                        <button type="button" className="btn btn-green" onClick={() => {navigate('/broadcast/add-referral')}}><span className="d-flex align-items-center"> Add Referral</span></button>
                                        </div>
                                    </div>

                                        {/*<h2 className="font-26 font-600 mb-0 pb-4">{state.formConfig?.title}</h2>
                                        
                                         <button className='btn btn-primary float-end' onClick={() => {navigate('/broadcast/add-referral')}}>Add Referral</button> */}
                                        <div className="d-flex flex-wrap justify-content-between align-items-start align-items-md-center mb-2 filterSearch">
                                            <div className="d-flex flex-wrap flex-1 mb-0 justify-content-start">
                                                <button className="btn-grpfiter me-3" type="button" onClick={handleToggleSearchCriteria}>
                                                    <i className="icon-filter font-13 me-2"></i>
                                                    Filters
                                                </button>
                                                <div className='dual-search'>
                                                <div className="search alumniSearch m-0 open position-static flex-1 me-2">
                                                    <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                                                        <input
                                                            value={referralSearchText}
                                                            placeholder="Search by Lead name"
                                                            aria-label="Search"
                                                            className="form-control flex-1 font-13 font-500 text-truncate"
                                                            onChange={handleReferralSearchChange}
                                                        />
                                                        <button
                                                            className="btn btn-search icon icon-search"
                                                            onClick={handleReferralSearch}></button>
                                                       
                                                    </div>
                                                </div>
                                                <div className="search alumniSearch m-0 open position-static flex-1">
                                                    <div className="form-inline d-flex flex-wrap justify-content-between d-block">
                                                    <input
                                                            value={fuzzySearchText}
                                                            placeholder="Search by Referral name"
                                                            aria-label="Search"
                                                            className="form-control flex-1 font-13 font-500 text-truncate"
                                                            onChange={handleFuzzySearchChange}
                                                        />
                                                        <button
                                                            className="btn btn-search icon icon-search"
                                                            onClick={handleFuzzySearch}></button>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        {showSearchCriteria && <SearchCriteria />}
                                        {props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Layout;
