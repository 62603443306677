// import config from '../config/config';
import { AxiosResponse } from 'axios';
import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
// import PageConfigService from './page-config.service';
import pageConfig from './PageConfigtheme.json';
import PageConfigtheme_institute_admin from './PageConfigtheme_institute_admin.json';

import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
import { SessionStateType } from '../../../library/Core/SmartTypes';

class ThemeOptionsService {
    static userStatusCode: string;
    static studentId: string;
    static userTypeCode: string;
    static genderCode: string;
    static instituteLogo: string;
    static instituteGroupId: string;
    constructor() {
        // IIFE (Immediately Invoked Function Expression)
        (() => {
            const { sessionState } = useContext(SessionContext);
            ThemeOptionsService.userStatusCode = sessionState?.userStatusCode as any;
            ThemeOptionsService.studentId = sessionState?.studentId as any;
            ThemeOptionsService.userTypeCode = sessionState?.userTypeCode as any;
            ThemeOptionsService.genderCode = sessionState?.genderCode as any;
            ThemeOptionsService.instituteLogo = sessionState?.instituteLogo as any;
            ThemeOptionsService.instituteGroupId = sessionState?.instituteGroupId as any;
            //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        })();

        // Rest of the constructor
    }

    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, pageName } = params;

        return Promise.all([this.getPageConfig(), ThemeOptionsService.get(eventId)])
            .then((values) => {
                state.formConfig = values[0];
                state.data = values[1];
                state.routeInfo = { pageName };
                //return state; // Resolve the Promise with the state object
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};
        const userTypeCode = localUserData?.userTypeCode;
        if (userTypeCode === 'INSTITUTE_ADMIN') {
            return Promise.resolve(PageConfigtheme_institute_admin);
        }

        return Promise.resolve(pageConfig);
    }

    static async get(eventId: any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        let ThemeInfo = { };
        let ThemeInfodata = [ ];
        // const queryParams = new URLSearchParams(window.location.search);
        // const eventId = queryParams.get('id');
        
        if (eventId) {
            ThemeInfodata = (await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/theme-info/${eventId}`))?.data; 
            
            const Themedata = ThemeInfodata;
            console.log(Themedata, 'Get Themedata')
            
            if (Themedata){
                ThemeInfo = ThemeOptionsService.toThemeto(ThemeInfodata);
            }else{
                ThemeInfo =  { };
            }
        }

        return Promise.resolve(ThemeInfo);
    }

    static toThemeto(ThemeInfo: any) {

        const parseToArray = (input: any) => {
            if (Array.isArray(input)) {
                return input; // Already an array, no need to parse
            }
            try {
                return JSON.parse(input || "[]"); // Safely parse string to array
            } catch (error) {
                console.error("Failed to parse input to array:", error);
                return []; // Fallback to an empty array in case of error
            }
        };


        // ThemeInfo.ThemeInfo = Array.isArray(ThemeInfo?.themeType)
        // ? ThemeInfo.themeType.map((item: any) => {
        //     return { eventtheme: item };
        // })
        // : JSON.parse(ThemeInfo.themeType || "[]").map((item: any) => {
        //     return { eventtheme: item };
        // });
        // Safely map each field
        ThemeInfo.eventtheme = parseToArray(ThemeInfo.themeType).map((item: any) => {
            return { eventtheme: item };
        });

        ThemeInfo.departmentid = parseToArray(ThemeInfo.departmentType).map((item: any) => {
            return { departmentid: item };
        });

        ThemeInfo.eventcountries = parseToArray(ThemeInfo.countries).map((item: any) => {
            return { eventcountries: item };
        });

        ThemeInfo.eventskills = parseToArray(ThemeInfo.skillType).map((item: any) => {
            return { eventskills: item };
        });

        ThemeInfo.eventtests = parseToArray(ThemeInfo.testType).map((item: any) => {
            return { eventtests: item };
        });

        ThemeInfo.eventindustry = parseToArray(ThemeInfo.industryType).map((item: any) => {
            return { eventindustry: item };
        });

        console.log(ThemeInfo, 'ThemeInfo data')

        return ThemeInfo;
    }

    // static toThemeto(ThemeInfo: any) {
       
        
    //     ThemeInfo.eventtheme = ThemeInfo.data.theme_type;
    //     ThemeInfo.departmentid = ThemeInfo.data.department_type;
    //     ThemeInfo.eventcountries = ThemeInfo.data.countries;
    //     ThemeInfo.eventcareers = ThemeInfo.data.career_type;
    //     ThemeInfo.eventskills = ThemeInfo.data.skill_type;
    //     ThemeInfo.eventtests = ThemeInfo.data.test_type;
    //     ThemeInfo.eventindustry = ThemeInfo.data.industry_type;


    //     return ThemeInfo;
    // }

   
}
export default ThemeOptionsService;
