import { useContext, useEffect } from "react";
import { DispatchEvent, State } from "../../../../../../library/Core/SmartTypes";
import { SmartContext } from "../../../../../../library/Core/SmartContext";
import PageBuilder from "../../../../../../library/Builders/PageBuilder/PageBuilder";
import EditAffinityFormLayout from "./EditAffinityFormLayout";
import EditInfluenceFormLayout from "./EditInfluenceFormLayout";
import ProfileMarkerService from "./ProfileMakerService";

const EditInfluenceModal = ({ userdata }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { saveMarker, fetchUserProfileMarkers } = ProfileMarkerService();

    const handleSubmit = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) =>  {
        event.preventDefault();
        const validateSocialInfluence = (reviews: any[]) => {
            const names = new Set();        
            for (const review of reviews) {
                if (names.has(review.socialInfluence)) {
                    return false; 
                }
                names.add(review.socialInfluence);
            }
            return true; 
        };
        
        let socialInfluence = state.data.socialInfluence;   
        if (!validateSocialInfluence(socialInfluence)) {
            alert("Duplicate Social Influence Name detected. Please correct the entries.");
            return; 
        }
        let payload = {
            markerType: 'influence',
            ...state?.data?.influence,
        };

        if (state?.data?.visibleControl === 'socialInfluence') {
            payload.markerType = 'socialInfluence';
            payload.socialInfluence = JSON.stringify(state.data.socialInfluence);
            // if (Array.isArray(state?.data?.socialInfluence)) {
            //     const validsocialInfluence= state.data.socialInfluence.filter((social: any) =>
            //         social &&
            //         typeof social.socialInfluence !== 'undefined' &&
            //         social.socialInfluence !== ''
            //         // typeof social.socialInfluenceProfileLink !== 'undefined' &&
            //         // social.socialInfluenceProfileLink !== ''
            //         // typeof social.socialInfluenceFollowerCount !== 'undefined' &&
            //         // social.socialInfluenceFollowerCount !== ''
            //     );
            //     payload.markerType = 'socialInfluence';
            //     if (validsocialInfluence.length > 0) {
            //         payload.socialInfluence = JSON.stringify(validsocialInfluence);
            //     } else {
            //         payload.socialInfluence = JSON.stringify([{}]); 
            //     }
            // } else {
            //     payload.markerType = 'socialInfluence';
            //     payload.socialInfluence = JSON.stringify([{}]);
            // }
        }

        await saveMarker({...payload,syncData : state?.data?.syncData?.[0] || {}}, state?.internal?.model?.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showInfluenceModal' } });
    };

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSubmit } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditInfluenceFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditInfluenceFormLayout>
        </SmartContext.Provider>
    );
};

export default EditInfluenceModal;
