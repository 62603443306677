import { useContext, useState, useEffect } from 'react';
import { SmartContext } from '../Core/SmartContext';
import { handleControlValueChange, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';

const DateTimeSelectorControl = (args: SimpleFormControlArguments) => {
    
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };

    const dateTimeControl = control.controlGroup[0];
    const dateTimeDataKey = `${parentDataKey}.${dateTimeControl.id}`;
    // const dateTimeValue = state.data[dateTimeDataKey] || '';
    const dateTimeValue = state.data[parentDataKey][dateTimeControl.id] || '';
   // const dateTimeValue = dateTimeDataKey || '';
   
    const minDateProp = control?.props?.customProperties?.minDate ?? '';
    const [minDateTime, setMinDateTime] = useState<string | null>(null);
    const minDelay = control?.props?.customProperties?.minDelay ?? 0; // Delay in minutes

    const [dateTimeOption, setDateTimeOption] = useState('custom');

    useEffect(() => {
        if (dateTimeValue) {
            setDateTimeOption(dateTimeOption);
        } else {
            setDateTimeOption('');
        }
    }, [dateTimeValue]);

    useEffect(() => {
        if (minDateProp === 'current_datetime') {
            const currentDateTime = new Date();
            if ( !isEmpty(minDelay)) {
                currentDateTime.setMinutes(currentDateTime.getMinutes() + minDelay); // Add delay
            }
            setMinDateTime(currentDateTime.toISOString().slice(0, 16));
        } else if (minDateProp && minDateProp.startsWith('id:')) {
            const referencedFieldId = minDateProp.split(':')[1];
            const referencedValue =
                state.data[parentDataKey]?.[referencedFieldId] ?? '';

            if (referencedValue) {
                const referencedDateTime = new Date(referencedValue);
                if ( !isEmpty(minDelay)) {
                    referencedDateTime.setMinutes(referencedDateTime.getMinutes() + minDelay); // Add delay
                }
                setMinDateTime(referencedDateTime.toISOString().slice(0, 16));
            }
        } else {
            setMinDateTime(null);
        }
    }, [minDateProp, minDelay, state.data, parentDataKey]);

    const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setDateTimeOption('custom');
        handleControlValueChange({
            control: dateTimeControl,
            value: value,
            dataKey: dateTimeDataKey,
            parentDataKey,
            state,
            dispatch,
        });
    };

    return (
        <div className="mb-3">
            {control.props?.label && (
                <label htmlFor={dateTimeControl.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <input
                id={dateTimeControl.id}
                type="datetime-local" // Use datetime-local input type
                className="form-control"
                value={dateTimeValue}
                min={minDateTime || undefined} // Apply the minDate
                onChange={handleDateTimeChange}
            />
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </div>
    );
};

export default DateTimeSelectorControl;
