import { useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { axiosClient } from '../../../library/Service/axiosClient';
import EventsService from './EventsService';

const useEvents = () => {
    const { state, dispatch } = useContext(SmartContext);

    const performSearch = async (action: string) => {
        if (action === 'TAB_CHANGE') await performUpdateFilters(action);
        const response = await axiosClient().post(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/fetch-events`, getSearchCriteria());

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: {
                dataKey: 'events',
                value: response.data?.rows ?? []
            },
        });

        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'counts', value: response.data?.counts },
        });
    };

    const performUpdateFilters = async (selectedTab: string = '') => {
        const searchBy = selectedTab === '' ? state.data.searchBy : selectedTab;
        const filterDomain = await EventsService.getDefaultFilterDomain(searchBy);

        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'filters', value: EventsService.getDefaultFilters() } });
        dispatch({
            type: 'CONTROL_VALUE_CHANGE',
            payload: { dataKey: 'filterDomain', value: filterDomain },
        });
    };

    const getSearchCriteria = () => {
        const userData: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};
        const criteria: any = getDefaultSearchCriteria();
        for (let prop in state?.data.filters) {
            if (criteria.hasOwnProperty(prop)) {
                criteria[prop] = state?.data?.filters[prop];
            }
        }

        if (state?.data?.searchBy === 'upcoming') {
            criteria.upcoming = 1;
        } else if (state?.data?.searchBy === 'past') {
            criteria.past = 1;
        }

        criteria.login_ref_id = sessionStorage.getItem('user-login-id');
        criteria.userTypeCode = userData?.userTypeCode;
        // criteria.institute_id = userData?.userTypeCode == 'INSTITUTE_ADMIN' ? userData?.primaryInstitute : userData?.instituteId;
        criteria.user_id = userData?.userTypeCode == 'INSTITUTE_ADMIN' ? userData?.userId : userData?.studentId;
        criteria[userData?.userTypeCode?.toLowerCase()] = 1;
        if ( state?.internal?.userData?.clientType != 'CLIENT' ) {
            criteria.clients_only = 'no';
        }

        if (userData?.userTypeCode == 'STUDENT' || userData?.userTypeCode == 'ALUMNI') {
            criteria.programLevel = userData?.programLevel;
            criteria.passOutYear = userData?.passOutYear;
        }

        criteria.instituteTypeCode = state?.internal?.userData?.instituteTypeCode;

        switch (state?.data?.searchBy) {
            case 'upcoming':
                criteria.upcoming = 1;
                break;
           
            case 'past':
                criteria.past = 1;
                break;

            case 'created-by-you':
                criteria.created_by = criteria.user_id;
                criteria.is_published = 'yes';
                break;
            
            case 'your-drafts':
                criteria.created_by = criteria.user_id;
                criteria.is_published = 'no';
                break;
        }

        return criteria;
    };

    const getDefaultSearchCriteria = () => {
        const userTypeCode = sessionStorage.getItem('user-type-code') as string;
        let req_from = EventsService.getRequestFrom();
        let student_class = null;
        let board_ref_id = null;

        if (userTypeCode === 'STUDENT') {
            student_class = sessionStorage.getItem('user-class');
            board_ref_id = sessionStorage.getItem('user-board-ref-id');
        }

        return {
            req_from,
            login_ref_id: '',
            eve_types: '',
            upcoming: '',
            past: '',
            created_by: '',
            is_published: '',
            eve_presenter: '',
            eve_themes: '',
            eve_test: '',
            eve_skill: '',
            eve_country: '',
            eve_university: '',
            eve_inds: '',
            eve_pdt: '',
            eve_career: '',
            passout_year: sessionStorage.getItem('pass-out-year'),
            eve_department: '',
            class: student_class,
            board_ref_id: board_ref_id,
            start: 0,
            limit: 1000,
            instituteTypeCode: ''
        };
    };

    return { performSearch, performUpdateFilters };
};

export default useEvents;