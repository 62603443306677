import { useEffect } from 'react';
import { getCompanyImage } from '../../../common/styles/Images';
import { getDomainValueForCode, isEmpty } from '../../../library/Core/SmartFunctions';
import { Tooltip } from 'bootstrap';
import { getFieldOfStudyName } from '../alumni-profile/service/student-profile-view.service';

const CardWorking = ({ alumni, state }: { alumni: any;  state: any }) => {
    console.log(alumni, " alumni ")

    const getEducationDetails = (alumni: any) => {
        let title = '';
        let university_name = '';
        let IsPrestigiousCollege='';
        let department='';
        switch (alumni?.pursuingEducationLevelCode) {
            case 'UG':
                title = 'Pursuing UG';
                university_name=alumni.universityName
                IsPrestigiousCollege=alumni?.isPrestigiousCollege 
                department=getFieldOfStudyName(alumni.fieldOfStudyCode, state)
                break;

            case 'PG':
                title = 'Pursuing PG';
                university_name=alumni.pgUniversityName
                IsPrestigiousCollege=alumni?.pgIsPrestigiousCollege 
                department=getFieldOfStudyName(alumni.pgFieldOfStudyCode, state)
                break;

            case 'JOINED_UG':
                title = 'Joined UG';
                university_name=alumni.universityName
                IsPrestigiousCollege=alumni?.isPrestigiousCollege 
                department=getFieldOfStudyName(alumni.fieldOfStudyCode, state)
                break;

            case 'JOINED_PG':
                title = 'Joined PG';
                university_name=alumni.pgUniversityName
                IsPrestigiousCollege=alumni?.pgIsPrestigiousCollege 
                department=getFieldOfStudyName(alumni.pgFieldOfStudyCode, state)
                break;
            case 'Doctoral':
                title = 'Joined Doctoral';
                university_name=alumni.universityName
                IsPrestigiousCollege=alumni?.isPrestigiousCollege 
                department=getFieldOfStudyName(alumni.fieldOfStudyCode, state)
                break;
        }
        return (
             `<div class="alumni_univarsity_brife">
                    <div class="alumni_education text-start">${title}</div>
                    <div class="alumni_univarsity_name">
                        <span class="text-black">${university_name}</span>
                        ${IsPrestigiousCollege === '1' ? (
                            `<i class="ms-1">
                                <img src="https://miles.univariety.com/school/public/images/subtraction.svg" alt="" />
                            </i>`
                        ) : ''}
                    </div>
                    ${!isEmpty(department) ? (
                        `<div class="alumni_univarsity_subject">
                            <i></i><span class="text-start">${department}</span>
                        </div>`
                    ) : ''}
                </div>`
        );
    };

    return (
        <div key={alumni.uuid} className="alumni_univarsity_details working">
            <div className="alumni_univarsity_details_inner_cover">
                <div className="alumni_univarsity_logo">
                    {/* <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/dummy_male.svg`} alt="" /> */}
                    <img src={getCompanyImage(alumni.jobCompanyImage)} alt="" />
                </div>
                <div className="alumni_univarsity_brife">
                    <div className="alumni_education">
                        <div className="row g-2">
                            <div className="col">Working as</div>
                            {(alumni?.presentEducationStatusCode === 'PURSUING_STUDIES' && alumni?.isWorking === 1 &&
                                ((alumni?.pursuingEducationLevelCode === 'PG' && !isEmpty(alumni.pgUniversityName)) ||
                                (alumni?.pursuingEducationLevelCode === 'UG' && !isEmpty(alumni.universityName)))) &&
                                    <div className="col-auto">
                                        <a
                                            href="javascript:;"
                                            className="btn-white-tooltip"
                                            data-bs-toggle="tooltip"
                                            data-bs-html="true"
                                            title=""
                                            data-bs-original-title={getEducationDetails(alumni)}>
                                            Education Status
                                        </a>
                                    </div>
                                }
                        </div>
                    </div>
                    <div className="alumni_univarsity_name">
                        <span>
                        {
                        alumni?.workingStatusCode === 'TEACHING_AND_RESEARCH'
                            ? (alumni?.workingInstituteId
                                ? getDomainValueForCode(alumni?.workingInstituteId, 'INSTITUTE_DOMAIN', state)
                                : alumni?.workingInstituteName)
                            : (alumni?.jobCompanyId
                                ? (getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state) === alumni?.jobCompanyId
                                    ? alumni?.companyName
                                    : getDomainValueForCode(alumni?.jobCompanyId, 'COMPANY_CODE', state))
                                : alumni?.companyName)
                        }
                        </span>
                        {alumni?.jobCompanyPrestigious == 1 && (
                            <i className="ms-1">
                                <img src="https://miles.univariety.com/school/public/images/subtraction.svg" alt="" />
                            </i>
                        )}
                    </div>
                    {alumni?.designation && (
                        <div className="alumni_univarsity_subject">
                            <i className="icon-brifecase"></i>
                            <span>{alumni.designation  
                            ?.toLowerCase()  
                            .replace(/_/g, ' ')  
                            .replace(/\b\w/g, (char:any) => char.toUpperCase()
                            )}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CardWorking;
