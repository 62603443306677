import { useState,useContext } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';

const Layout = (props:any) => {
    const { state, dispatch } = useContext(SmartContext);

    return (
        <div className="main flex-1">
        <section className="updatebasicinformation">
            <div className="container max-1140 px-lg-0 overflow-hidden">
                <div className="row">
                    <div className="col-md-12 mb-4">
                        <div className="white-block white-block-notopborderradius p-0 h-100">
                            <div className="border-bottom border-2 custom-border-gray p-3 p-sm-4 pb-3">
                                <div className="d-flex flex-wrap justify-content-between align-items-center mx-0 mx-sm-1">
                                   <h1 className="font-26 font-600 my-0 my-sm-1 py-2">Psychometric Test and BAS Mapping</h1>
                                </div>
                            </div>

                            <div className="d-flex flex-column justify-content-between align-items-center mx-auto max-800 pt-4 pt-sm-5 pb-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="900" id="no-notification">
                                <form className="lstCustForm w-100">
                                    <div className="row g-4">
                                        {props.children}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    );
};

export default Layout;
