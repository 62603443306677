import React, {useState,useContext,useEffect } from 'react';
import { SmartContext } from '../../../library/Core/SmartContext';
import { useParams } from 'react-router-dom';
import { axiosClient } from '../../../library/Service/axiosClient';
import '../../../styles/css/valuable_conversation.css';
import moment from 'moment';
import UnivarietyLogo from '../../../styles/images/logo.png';
import { formatFullName } from '../../../library/Core/SmartFunctions';


const AlumniSelectedConversions = () => {
    const params = useParams()
    const [conversations, setConversations] = useState<Conversation[]>([]);

    var [alumniData, setAlumniData] = useState({
        AlumniName: '',
        AlumniPassoutYear:'',
        AlumnischoolName:'',
        CurrentStatus:'',
        seniorityLevelCode:'',
        profilePictureFilePath:'',
        schoolId:'',
        AlumniUserId:'',
        AlumniProgramName:''
      });

    interface Conversation {
        studentName: string;
        schoolName:string;
        passOutYear:string;
        profilePictureFilePath:string;
        programName:string,

    }

    useEffect(() => {
        const getConversation = async () => {
            const encodedID = params.id; 
            const decodedID = atob(encodedID ?? '');
            const response = await axiosClient().get(`${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-conversation-by-logid/${decodedID}`);
           // console.log(response.data);
            if (response.status === 200) {
                setConversations(response.data.conversations);
                const responseAlumni = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-alumnidetails-by-uuid`,{'alumniId':response?.data?.alumniId,'instituteId':response?.data?.instituteId});
                const data = await responseAlumni.data; // Extract the data from the response
                console.log(data);
                if(data.data)
                {
                    const alumni=data.data;
                    setAlumniData({
                        ...alumniData,
                        AlumniUserId: alumni[0]?.UUID,
                        AlumniName:formatFullName(alumni[0]?.firstName,alumni[0]?.lastName), //(alumni[0]?.firstName ?? "") + " " + (alumni[0]?.lastName ?? ""),
                        AlumniPassoutYear: alumni[0]?.passOutYear,
                        AlumniProgramName: alumni[0]?.programName,
                        CurrentStatus:alumni[0]?.currentStatus,
                        AlumnischoolName: alumni[0]?.schoolName,
                        seniorityLevelCode:alumni[0]?.seniorityLevelCode,
                        profilePictureFilePath: alumni[0]?.profilePictureFilePath,
                        schoolId:alumni[0]?.schoolId
                      });
                }
            }
        };
        getConversation();
    }, []);
    
    // const formatDate = (isoDateString: string): string => {
    //     const createdDate = new Date(isoDateString); // Convert ISO string to Date object

    //     // Format the date as per your requirement
    //     const formattedDate = `${createdDate.toLocaleDateString('en-US', {
    //         day: 'numeric',
    //         month: 'short',
    //         year: 'numeric',
    //     })} ${createdDate.toLocaleTimeString('en-US', {
    //         hour: '2-digit',
    //         minute: '2-digit',
    //         hour12: true,
    //     })} `;

    //     return formattedDate;
    // };

    const formatDate = (isoDateString: string): string => {
        // Check if the input is null or undefined
        if (!isoDateString) {
            console.error('Null or undefined date string');
            return 'Invalid Date';
        }
    
        // Preprocessing for iOS compatibility
        const processedDateString = isoDateString;
    
        try {
            // Multiple parsing strategies
            let parsedDate: Date;
    
            // Strategy 1: Direct parsing
            parsedDate = new Date(processedDateString);
    
            // Strategy 2: If first parse fails, try more aggressive parsing
            if (isNaN(parsedDate.getTime())) {
                // Remove 'T' and try parsing
                const cleanedDateString = processedDateString.replace('T', ' ');
                parsedDate = new Date(cleanedDateString);
            }
    
            // // Strategy 3: Explicit component parsing for iOS
            // if (isNaN(parsedDate.getTime())) {
            //     // Split the date string and manually create Date
            //     const dateParts = processedDateString.split(/[/\s:]/);
            //     if (dateParts.length >= 6) {
            //         // Assumes format: YYYY/MM/DD HH:MM:SS
            //         parsedDate = new Date(
            //             parseInt(dateParts[0]), // Year
            //             parseInt(dateParts[1]) - 1, // Month (0-indexed)
            //             parseInt(dateParts[2]), // Day
            //             parseInt(dateParts[3]), // Hours
            //             parseInt(dateParts[4]) // Minutes
            //         );
            //     }
            // }
    
            // Final validation
            if (isNaN(parsedDate.getTime())) {
                console.error('Invalid date string after all parsing attempts:', isoDateString);
                return 'Invalid Date second';
            }
    
            // Manual formatting to ensure cross-platform compatibility
            const months = [
                'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
            ];
    
            const day = parsedDate.getDate();
            const monthIndex = parsedDate.getMonth();
            const year = parsedDate.getFullYear();
    
            // Manual time formatting
            let hours = parsedDate.getHours();
            const minutes = parsedDate.getMinutes().toString().padStart(2, '0');
            const ampm = hours >= 12 ? 'PM' : 'AM';
    
            // Convert to 12-hour format
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            const formattedHours = hours.toString().padStart(2, '0');
    
            // Combine date and time
            return `${months[monthIndex]} ${day}, ${year} ${formattedHours}:${minutes} ${ampm}`;
    
        } catch (error) {
            console.error('Comprehensive date parsing error:', {
                originalString: isoDateString,
                processedString: processedDateString,
                error: error
            });
            return 'Invalid Date';
        }
    };
return (
        <>
<html lang="en">
<head>
    <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/css/bootstrap.min.css"
    />
</head>
<body>
        <div className="main-wrapper py-5">
            <div className="strip">
                <i></i><i></i><i></i><i></i><i></i>
            </div>
            <div className="">
                <div className="mx-auto text-center pb-3">
                    <img src={UnivarietyLogo} alt="Univariety Logo" />
                </div>
                <div className="max-700 mx-auto">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="rate_container">
                                <div className="experienceboxheading d-flex flex-column align-items-center justify-content-center">
                                    <h3>Valuable Conversation</h3>
                                </div>
                                <div className="experiencebox">
                                    <div className="modal-content half-gray">
                                        <div className="modal-header conversation-header">
                                            <div className="w-100">
                                                <div className="row">
                                                {conversations.map((message, index) => (
                                                            index === 0 && (
                                                                <div key={index} className="col-sm-6 ">
                                                                    <div className="d-flex flex-wrap align-items-center left-col">
                                                                        <i className="me-2">
                                                                            <img
                                                                                src={message.profilePictureFilePath}
                                                                                className="rounded-circle img-fluid"
                                                                                width="47"
                                                                            />
                                                                        </i>
                                                                        <span className="lineHeight-16 flex-1">
                                                                            <span className="font-16 font-500 text-dark-blue">{formatFullName(message.studentName,'')},{' '}<br/>
                                                                             <span className="fst-italic font-15">{message?.programName}{message?.passOutYear ? ` - ${message.passOutYear}` : ''}</span>
                                                                            </span>
                                                                            {/* <span className="d-block font-14 font-500 fst-italic text-dark-blue">{message.schoolName}</span> */}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ))}
                                                    <div className="col-sm-6">
                                                        <div className="d-flex flex-wrap align-items-center flex-row-reverse flex-sm-row right-col">
                                                            <i className="ms-2 ms-sm-0 me-0 me-sm-2">
                                                                <img
                                                                    src={alumniData?.profilePictureFilePath}
                                                                    className="rounded-circle img-fluid"
                                                                    width="47"
                                                                />
                                                            </i>
                                                            <span className="font-16 lineHeight-16 flex-1 d-flex flex-column align-items-end align-items-sm-start">
                                                                <span className="font-18 font-500 text-dark-blue"> { alumniData?.AlumniName},{' '}<br/>
                                                                <span className="fst-italic font-15">{alumniData?.AlumniProgramName}{alumniData?.AlumniPassoutYear ? ` - ${alumniData.AlumniPassoutYear}` : ''}</span>
                                                                </span>
                                                                {/* <span className="d-block font-12 font-500 fst-italic text-dark-blue">{alumniData?.AlumnischoolName}</span> */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-body px-md-4 pb-4">
                                            <div className="conversation gap">
                                                        {conversations.map((message: any, index: number) => (
                                                            <div  key={index}
                                                                className={
                                                                    message?.senderId == alumniData?.AlumniUserId ? 'row justify-content-end' : 'row justify-content-start'
                                                                }>
                                                                <div className="col-sm-6">
                                                                    <div className="d-flex flex-wrap">
                                                                        <div className={
                                                                            message?.senderId == alumniData?.AlumniUserId ? 'messages leftUser ps-5 ps-sm-0' : 'messages rightUser pe-5 pe-sm-0'
                                                                        }>
                                                                            <p className="mb-0">{message.messageContent}</p>
                                                                            {/* <span className="date mb-2">{moment.utc(message.createdAt).local().format('DD-MMM-YYYY h:mm A')}</span>  */}
                                                                           <span> {formatDate(message.createdAt)}</span>
                                                                        </div>
                                                                        {/* <div className="userimg ms-2">
                                                                            <img src="images/ravi_verma_icon.png" className="rounded-circle img-fluid" width="47" />
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </body>
        </html>
        </>
    );
}

export default AlumniSelectedConversions;