import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { canEditProfile } from '../../../../library/Core/SmartFunctions';
import SessionContext from '../../../../library/Core/SessionContext';

const EntranceExams = () => {
    const { state, dispatch } = useContext(SmartContext);
    const model = state.internal.model;
    const { id, pageName } = useParams();
    const navigate = useNavigate();
    const { sessionState }: any = useContext(SessionContext);

    if ( sessionState?.userTypeCode === 'STUDENT' && sessionState?.profileStatusCode === 'PREFERENCES_INCOMPLETE' ) {
        window.location.href = `/alumni/${sessionState?.id}/student-preference`;
        return null; // Redirection is initiated, so no need to render further
    }

    return (
        // <div className="tab-pane fade" id="pills-entranceExams" role="tabpanel" aria-labelledby="pills-entranceExams-tab">
        <div>
            <div className="entranceExams-detail tab-white-block">
                {canEditProfile(model.id, sessionState) && (
                    <a className="pencil-edit" role="button" onClick={() => navigate(`/alumni/${id}/entrance-exam`)}></a>
                )}

                <div className="row g-4">
                    {model.entranceTests.map((item: any, index: number) => (
                        <div key={item.id} className="col-md-12">
                            <div className="customRow-header">
                                <div className="icon icon-exam-note"></div>
                                <div className="customRow-header-title d-flex flex-column justify-content-center lineHeight-22">
                                    <span className="font-20 font-600">{item.entranceTestName}</span>
                                    <span className="font-16 text-gray-41">{item.preparationStatus}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                    {model.entranceTests.length === 0 && (

                    <div  className="col-md-12">
                        <div className="customRow-header">
                            <div className='customRow-header-title d-flex flex-column justify-content-center lineHeight-22'>
                            <span>Please update your Entrance Exam data..!</span></div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EntranceExams;
