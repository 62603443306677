import { Dropdown, Tooltip } from 'bootstrap';

import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { SmartContext } from '../../../library/Core/SmartContext';
import { formatFullName, getDomainValueForCode, getFlexColDomainValueForCode, handleCopyText, isEmpty } from '../../../library/Core/SmartFunctions';
import { Column } from './report-config';
import useManageAlumni from './useManageAlumni';
import { Modal } from 'bootstrap';
import '../../../styles/css/engagement.css';
import SendEmailModal from './SendEmailModal';
import SelectUserModal from './SelectUserModal';
import EmailPreviewModal from './EmailPreviewModal';
import WtappPreviewModal from './WtappPreviewModal';
import { axiosClient } from '../../../library/Service/axiosClient';
import ViewSentHistoryModal from './ViewSentHistoryModal';
import AlumniEngagementService from './AlumniEngagementService';
import ValuableQAModal from './ValuableQAModal';
import EngagementActionSettings from '../../../library/SimpleControls/EngagementActionSettings';
import SessionContext from '../../../library/Core/SessionContext';
import { Buffer } from 'buffer';
import { getStudentImage } from '../../../common/styles/Images';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';

const AlumniEngagementGrid = () => {
    const { sessionState } = useContext(SessionContext);
    console.log('sessionState', sessionState);
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useManageAlumni();
    const [sortedStudents, setSortedStudents] = useState<string[]>([]);
    const [visibleStudents, setVisibleStudents] = useState(state.data.alumniList.slice(0, 10));
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const allRows = [...state.data.alumniList] ?? [];
    const [valuableQAData, setValuableQAData] = useState('');
    const [valuablePage, setValuablePage] = useState(false);

    var [alumniData, setAlumniData] = useState({
        AlumniName: '',
        AlumniMobile: '',
        AlumniUserId: '',
        AlumniEmail: '',
        AlumniDesignation: '',
        AlumniCompanyname: '',
        AlumniPassoutYear: '',
        AlumnischoolName: '',
        universityName: '',
        prestigious: '',
        AlumniAchievments: '',
        AlumniCity: '',
        ProfileSendUsers: '',
        LastSentDate: '',
        AlumniUUID: '',
        CurrentStatus: '',
        profilePictureFilePath: '',
        userHash: '',
        instituteId: '',
        AlumniprogramName: '',
    });

    const [sendingReason, setSendingReason] = useState('');
    const [comment, setComment] = useState('');
    const [communicationChannel, setCommunicationChannel] = useState('');
    const [errors, setErrors] = useState<{ sendingReason: string; comment: string; communicationChannel: string }>({
        sendingReason: '',
        comment: '',
        communicationChannel: '',
    });
    const [EmailSubject, setEmailSubject] = useState('');
    const [EmailBody, setEmailBody] = useState('');
    const [WtappBody, setWtappBody] = useState('');
    const [modalId, setModalId] = useState<string>('');
    const [adminUsers, setAdminUsers] = useState('');
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const [viewSentHistory, setViewSentHistory] = useState('');
    const [WtappHeader, setWtappHeader] = useState('');
    const [alumni, setAlumni] = useState('');

    useEffect(() => {
        if (dropdownRef.current) {
            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);

    useEffect(() => {
        const sorted = [...allRows].sort(compareFunction);
        setSortedStudents(sorted);
    }, [state.data.alumniList, sortColumn, sortDirection]);

    useEffect(() => {
        setVisibleStudents(sortedStudents.slice(0, BATCH_SIZE));
    }, [sortedStudents]);

    useEffect(() => {
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
            if (sortedStudents.length <= visibleStudents.length) {
                if (loadMoreRef.current) {
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }

            if (entries[0].isIntersecting) {
                const nextBatch = sortedStudents.slice(0, visibleStudents.length + BATCH_SIZE);
                setVisibleStudents([...nextBatch]);
            }
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleStudents]);

    const getadminUsers = async (school_id: any) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-internal-user-by-schoolid`, {
                schoolId: school_id,
            });
            const data = await response.data;
            console.log(data);
            setAdminUsers(data.data);
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleStudents[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

    const handleColumnSort = (column: string) => {
        if (sortColumn === column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const handlePerformAction = async (action: string, alumni: any) => {
        switch (action) {
            case 'SEND_PROFILE':
                //added by VP #UAS-1283 dt:20-11-24
                const isDefaultProfile = alumni[0]?.profilePictureFilePath?.includes('/yellow-student-boy.svg') || alumni[0]?.profilePictureFilePath?.includes('yellow-student.svg');
                if (isDefaultProfile) {
                    alert('For SEND_PROFILE action, you need to add a profile picture for this user.'); 
                    return; 
                }
                //end by VP #UAS-1283 dt:20-11-24
                displayfirstpopup(alumni);
                setAlumni(alumni);
                break;
            case 'VIEW_SENT_HISTORY':
                console.log('send_profile');
                displayviewSentHistory(alumni);
                break;
        }
    };

    const handleAction = (row: any, action: string) => {
        // console.log(`handleAction: ${uuid} / ${action}`);
        handlePerformAction(action, [row]);
    };

    const getValidActions = (userStatusCode: string) => {
        return actions.filter((item: any) => item !== (userStatusCode === 'ACTIVE' ? 'Activate' : 'Deactivate'));
    };

    const showStar = (row: any) => (row.isPrestigiousCollege || row.pgIsPrestigiousCollege || row.hasReceivedScholarship ? true : false);

    const achiev_tool = (row: any) => {
        const achievementTypeCodes = row.achievementTypeCode.split(',');
        if (achievementTypeCodes.length > 0) {
            const listItems = achievementTypeCodes
                .map((code: any) => `<li>${getDomainValueForCode(code, 'ACHIEVEMENT_CODE', state)}</li>`)
                .join('');
            const viewDetailsLink = `<a target="_blank" href="/${row.userTypeCode?.toLowerCase()}/${
                row.uuid
            }/profile/achievements">View Details</a>`;
            const html = `<ol>${listItems}${viewDetailsLink}</ol>`;
            return html;
        } else {
            return '';
        }
    };

    const exam_tool = (row: any) => {
        const mainarr = row.ugExams.split('||');
        const firstTwoItems = mainarr.slice(0, 2); // Get the first two items
        const remainingItems = mainarr.slice(2);

        const firsttwoitems_html = firstTwoItems.map((item: any, index: any) => {
            const parts = item.split('-');
            const numberPart = parts[0].trim();
            if (parts.length >= 2) {
                const yearPart = parts[1].replace('<b>', '').replace('</b>', '').trim();
                return (
                    <li style={{ listStyleType: 'none' }} key={index}>
                        {getDomainValueForCode(numberPart, 'ENTRANCE_TEST_CODE', state)} {yearPart && `- ${yearPart}`}
                    </li>
                );
            } else {
                return (
                    <li style={{ listStyleType: 'none' }} key={index}>
                        {getDomainValueForCode(numberPart, 'ENTRANCE_TEST_CODE', state)}
                    </li>
                );
            }
        });

        //  const listItems = remainingItems.map((exam:any) => `<li>${exam}</li>`).join('');
        const listItems = remainingItems
            .map((item: any) => {
                const parts1 = item.split('-');
                const numberPart1 = parts1[0].trim();
                if (parts1.length >= 2) {
                    const yearPart1 = parts1[1].replace('<b>', '').replace('</b>', '').trim();
                    return `<li>${getDomainValueForCode(numberPart1, 'ENTRANCE_TEST_CODE', state)} - ${yearPart1}</li>`;
                } else {
                    return `<li>${getDomainValueForCode(numberPart1, 'ENTRANCE_TEST_CODE', state)}</li>`;
                }
            })
            .join('');

        let secondSpan = null;
        if (listItems) {
            const html_inner = `<ol>${listItems}</ol>`;
            secondSpan = (
                <span>
                    <a
                        className="btn-html"
                        href="javascript:;"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        data-bs-original-title={html_inner}>
                        &amp; {remainingItems.length} More
                    </a>
                </span>
            );
        }
        const exam = (
            <span>
                {firsttwoitems_html}
                {secondSpan}
            </span>
        );
        return exam;
    };

    const displayfirstpopup = async (alumni: any) => {
        console.log("sessionState",sessionState)
        if(sessionState?.userTypeCode !=="SUPER_ADMIN"){
            alert("You're not authorized to perform this action.\nPlease Login to continue..!");
            return false;
        }
        console.log(alumni);
        setSendingReason('');
        setCommunicationChannel('');
        setComment('');
        setErrors({ ...errors, sendingReason: '', communicationChannel: '', comment: '' });

        let maskedMobile = '';
        let maskedEmail = '';
        console.log(alumni[0]?.mobileNumber)
        if (!isEmpty(alumni[0]?.mobileNumber)) {
            maskedMobile = `+${alumni[0]?.mobileCountryCode} ${'X'.repeat(
                alumni[0]?.mobileNumber.length - 4
            )}${alumni[0]?.mobileNumber.slice(-4)}`;
        }

        const email = alumni[0].registeredEmailId;
        const atIndex = email.indexOf('@');
        if (atIndex !== -1) {
            maskedEmail = email[0] + email.substring(1, atIndex).replace(/./g, 'X') + email.substring(atIndex);
        }
        let AchievItems = '';
        if (alumni[0] && alumni[0].achievementTypeCode) {
            const achievementTypeCodes = alumni[0].achievementTypeCode.split(',');
            if (achievementTypeCodes.length > 0) {
                AchievItems = achievementTypeCodes.map((code: any) => getDomainValueForCode(code, 'ACHIEVEMENT_CODE', state)).join(',');
            }
        }

        const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-alumnidetails-by-uuid`, {
            alumniId: alumni[0]?.uuid,
            instituteId: alumni[0]?.instituteId,
        });
        const data = await response.data;
        const logdata = data.data;

        setAlumniData({
            ...alumniData,
            AlumniName: formatFullName(alumni[0]?.firstName, alumni[0]?.lastName), //(alumni[0]?.firstName ?? '') + ' ' + (alumni[0]?.lastName ?? ''),
            AlumniMobile: maskedMobile,
            AlumniUserId: alumni[0]?.uuid,
            AlumniEmail: maskedEmail,
            AlumniDesignation: alumni[0]?.jobDesignation,
            AlumniCompanyname: isEmpty(alumni[0]?.companyId)
                ? alumni[0]?.otherCompany
                : getDomainValueForCode(alumni[0]?.companyId, 'COMPANY_CODE', state),
            AlumnischoolName: alumni[0]?.schoolName,
            AlumniPassoutYear: alumni[0]?.passOutYear,
            AlumniprogramName: alumni[0]?.programName,
            universityName: alumni[0]?.universityName,
            prestigious: alumni[0]?.isPrestigiousCollege === 1 ? 'Prestigious' : '',
            AlumniAchievments: AchievItems == '' ? 'NA' : AchievItems,
            AlumniCity:
                getDomainValueForCode(alumni[0]?.residingCityCode, 'CITY_CODE', state) == ''
                    ? 'NA'
                    : getDomainValueForCode(alumni[0]?.residingCityCode, 'CITY_CODE', state),
            ProfileSendUsers: logdata[0]?.sentUsers ? JSON.parse(logdata[0]?.sentUsers) : '',
            LastSentDate: logdata[0]?.lastSentDate,
            AlumniUUID: alumni[0]?.uuid,
            userHash: alumni[0]?.userHash,
            instituteId: alumni[0]?.instituteId,
            profilePictureFilePath: alumni[0]?.profilePictureFilePath,
        });
        getadminUsers(alumni[0]?.instituteId);

        // const modalElement = document.getElementById('send-email');
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
        //dispatch({ type: 'SET_MODAL_FLAG', payload: { key: 'showEmailModal', value: true } });
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showEmailModal' } });
    };

    const handleSendingReasonChange = (value: any) => {
        setEmailSubject('');
        setEmailBody('');
        setWtappBody('');
        if (value == 3) {
            window.open(`/internal/alumni-interaction/${alumniData?.AlumniUUID}/${alumniData?.instituteId}`, '_blank');
            var closeButton = document.getElementById('send-email')?.getElementsByClassName('btn-close')[0];
            if (closeButton instanceof HTMLButtonElement) {
                closeButton?.click();
            }
            setSendingReason('');
        } else {
            setSendingReason(value);
            if (value == 6) {
                setCommunicationChannel('');
            }

            setParametersWiseSendingReason(value, state);
            setErrors({ ...errors, sendingReason: '' });
        }
    };

    const setParametersWiseSendingReason = (reasonId: any, state: any) => {
        var adminuserHash = '';
        if (typeof adminUsers[0] === 'object' && adminUsers[0] !== null) {
            console.log(adminUsers[0]['userHash']);
            adminuserHash = adminUsers[0]['userHash'];
        }
        console.log(reasonId);
        var link = btoa('profile');
        if (reasonId == '2') {
            link = btoa('profile/achievements');
        } else if (reasonId == '5') {
            link = btoa('profile/work');
        }
        console.log(link);
        // const url =
        //     alumniData?.userHash != undefined
        //         ? process.env.REACT_APP_APP_BASE_URL +
        //           '/sso/' +
        //           adminuserHash +
        //           '/' +
        //           link +
        //           '/' +
        //           alumniData?.AlumniUserId
        //         : '';

        const flexCol = getFlexColDomainValueForCode(alumniData?.instituteId, 'INSTITUTE_DOMAIN', state);
        const whitelabel_url = isEmpty(flexCol) ? '' : JSON.parse(flexCol).whitelabel_url;
        const baseUrl = !isEmpty(whitelabel_url) ? whitelabel_url : `${process.env.REACT_APP_APP_BASE_URL}/`;

        const url =
            alumniData?.userHash !== undefined
                ? `${baseUrl}sso/${adminuserHash}/${link}/${alumniData?.AlumniUserId}`
                : '';

        console.log(url);

        const reasonData = state.domain.get('PROFILE_SENDING_REASONS_CATEGORY');
        const extrainfo = reasonData.find((item: any) => item.code == reasonId);
        const flexData = JSON.parse(extrainfo?.flexCol as string);
        const replacements = {
            company_name: alumniData?.AlumniCompanyname ?? 'NA',
            designation: alumniData?.AlumniDesignation ?? 'NA',
            alumni_name: alumniData?.AlumniName ?? 'NA',
            passout_year: alumniData?.AlumniPassoutYear ?? 'NA',
            school_name: alumniData?.AlumnischoolName ?? 'NA',
            college_name: alumniData?.universityName ?? 'NA',
            prestigious: alumniData?.prestigious ?? 'NA',
            achievments: alumniData?.AlumniAchievments ?? 'NA',
            location: alumniData?.AlumniCity ?? 'NA',
            autologin_url: url ?? 'NA',
            program_name: alumniData?.AlumniprogramName ?? 'NA',
        };
        var mail_subject = flexData ? flexData?.mail_subject : '';
        mail_subject = replaceVariables(mail_subject, replacements);
        var mail_body = flexData ? flexData?.mail_body : '';
        mail_body = replaceVariables(mail_body, replacements);
        var wtapp_body = flexData ? flexData?.wtapp_body : '';

        wtapp_body = replaceVariables(wtapp_body, replacements);

        setEmailSubject(mail_subject);
        setEmailBody(mail_body);
        setWtappBody(wtapp_body);
        setWtappHeader(flexData ? flexData?.wtapp_header : '');
    };
    function replaceVariables(subject: string, replacements: any) {
        let result = subject;
        if (isEmpty(subject)) {
            return '';
        }
        for (const variable in replacements) {
            result = result.replace(new RegExp(`{{\\$${variable}}}`, 'g'), replacements[variable]);
        }
        return result;
    }

    const handleCommentChange = (value: any) => {
        setComment(value);
        setErrors({ ...errors, comment: '' });
    };

    const handleCommunicationChannelChange = (value: any) => {
        setCommunicationChannel(value);
        let newModalId = ``;
        if (value == 'Only_Email' || value == 'Both_Email_WA') {
            //newModalId = `select-user-popup-review`;
            setModalId('showEmailPreviewModal');
        }
        if (value == 'Only_WhatsApp') {
            //newModalId = `select-user-popup-whatsapp-review`;
            setModalId('showWhatsAppPreviwModal');
        }

        setErrors({ ...errors, communicationChannel: '' });
    };

    const handleNextPreviewAdminSelection = () => {
        const newErrors = {
            sendingReason: '',
            comment: '',
            communicationChannel: '',
        };
        if (!sendingReason) {
            newErrors.sendingReason = 'Please fill Sending Reason field.';
        }
        if (!comment && sendingReason == '6') {
            newErrors.comment = 'Please fill Comment field.';
        }
        if (!communicationChannel) {
            newErrors.communicationChannel = 'Please select a Communication Channel.';
        }
        setErrors(newErrors);
        if (Object.values(newErrors).some((error) => error)) {
            return;
        }
        console.log('handleNextPreviewAdminSelection');
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showUserModal' } });
        // var closeButton = document.getElementById('send-email')?.getElementsByClassName('btn-close')[0];
        // if (closeButton instanceof HTMLButtonElement) {
        //     closeButton?.click();
        // }

        // const modalElement = document.getElementById('select-user-popup');
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
    };

    const handlePreviewEmailPopup = (selectedItems: any) => {
        setSelectedItems(selectedItems);

        //dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showUserModal' } });
        // var closeButton = document.getElementById('select-user-popup')?.getElementsByClassName('btn-close')[0];
        // if (closeButton instanceof HTMLButtonElement) {
        //     closeButton?.click();
        // }
        console.log(modalId);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: modalId } });
        // const modalElement = document.getElementById(modalId);
        // if (modalElement) {
        //     const modal = new Modal(modalElement);
        //     modal.show();
        // }
    };

    const handleRemoveEmail = (index: any) => {
        const updatedSelectedItems = [...selectedItems];
        updatedSelectedItems.splice(index, 1);
        setSelectedItems(updatedSelectedItems); // Update the state with the modified array
    };

    const SubmitEmailPreview = () => {
        if (EmailSubject.trim() === '' || EmailBody.trim() === '') {
            alert('Both Email Subject and Email Body are required.');
            return false;
        }
        const validEmails = Array.from(document.querySelectorAll('.selectedEmailIds li')).map((li) => li.getAttribute('data-withoutmask'));
        console.log(validEmails);
        const uniqueEmails = [...new Set(validEmails)];
        if (uniqueEmails.length !== validEmails.length) {
            alert('Duplicate emails are not allowed');
            return false;
        }

        if (communicationChannel == 'Both_Email_WA') {
            // var closeButton = document.getElementById('select-user-popup-review')?.getElementsByClassName('btn-close')[0];
            // if (closeButton instanceof HTMLButtonElement) {
            //     closeButton?.click();
            // }

            // const modalElement = document.getElementById('select-user-popup-whatsapp-review');
            // if (modalElement) {
            //     const modal = new Modal(modalElement);
            //     modal.show();
            // }
            dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: "showWhatsAppPreviwModal" } });

        } else {
            if (validEmails.length > 0) {
                insertSendProfileData(validEmails, null);
            } else {
                alert('Select or Enter atleast one user');
            }
        }
    };

    const insertSendProfileData = async (validEmails: any, validMobiles: any) => {
        try {
            const ConversationLink = process.env.REACT_APP_APP_BASE_URL + '/valuable-conversation/';
            const reasonToImageMap: { [key: string]: string } = {
                'Prestigious College': 'https://ags-qa-bucket.s3.ap-south-1.amazonaws.com/teleport/prestigious_college.png',
                'Stellar Achievement': 'https://ags-qa-bucket.s3.ap-south-1.amazonaws.com/teleport/stellar_achievement.png',
                'Valuable Q&A': 'https://ags-qa-bucket.s3.ap-south-1.amazonaws.com/teleport/good_coversation.png',
                'Good Designation': 'https://ags-qa-bucket.s3.ap-south-1.amazonaws.com/teleport/good_designation.png',
                'Good Location': 'https://ags-qa-bucket.s3.ap-south-1.amazonaws.com/teleport/good_location.png'
            };
    
            const reasonCode = getDomainValueForCode(sendingReason, 'PROFILE_SENDING_REASONS_CATEGORY', state);

            const isDefaultProfile = alumniData?.profilePictureFilePath?.includes('/yellow-student-boy.svg') || alumniData?.profilePictureFilePath?.includes('yellow-student.svg');
            const profilePictureUrl = isDefaultProfile ? 
            (reasonToImageMap[reasonCode] || alumniData?.profilePictureFilePath) : 
            alumniData?.profilePictureFilePath;

            const response: any = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/email/send-alumni-profile`, {
                Emails: validEmails,
                Mobiles: validMobiles,
                sentProfileId: alumniData?.AlumniUserId,
                comment: comment,
                communicationChannel: communicationChannel,
                EmailSubject: EmailSubject,
                EmailBody: EmailBody,
                sendingReason: reasonCode,
                added_by: (sessionState?.id) ? sessionState?.id : sessionState?.studentId,
                WtappBody: WtappBody,
                WtappHeader: WtappHeader,
                ConversationLink: ConversationLink,
                instituteId: alumniData?.instituteId,
                profilePictureFilePath: profilePictureUrl
            });

            const data = await response.data;
            console.log(data);
            if (data.success) {
                alert(data.message);
                let newModalId = ``;
                if (communicationChannel == 'Only_Email') {
                    // newModalId = `select-user-popup-review`;
                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: "showEmailPreviewModal" } });

                }
                if (communicationChannel == 'Only_WhatsApp' || communicationChannel == 'Both_Email_WA') {
                    // newModalId = `select-user-popup-whatsapp-review`;
                    dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: "showWhatsAppPreviwModal" } });
                }
              
                AlumniEngagementService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) => {
                    setStateValue('alumniList', response.data);
                });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const SubmitWtappPreview = () => {
        const validMobiles = Array.from(document.querySelectorAll('.selectedWtappIds li'))
        .map((li) => li.getAttribute('data-withoutmask'))
        .filter(mobile => mobile && mobile.trim() !== '');  

        const validEmails = Array.from(document.querySelectorAll('.selectedEmailIds li')).map((li) => li.getAttribute('data-withoutmask'));
        
        const uniqueMobiles = [
            ...new Set(
                validMobiles.map((mobile) => {
                    if (mobile) {
                        const processedMobile = mobile.replace(/\s+/g, '').replace(/^(?:\+91-?|91-?)?/, '');
                        return processedMobile.match(/^[0-9]{10}$/) ? processedMobile : null;
                    }
                    return null;
                })
            ),
        ].filter((mobile) => mobile !== '');

        if (uniqueMobiles.length !== validMobiles.length) {
            alert('Duplicate Mobile number are not allowed');
            return false;
        }
        if (validMobiles.length > 0) {
            insertSendProfileData(validEmails, validMobiles);
        } else {
            alert('Select or Enter atleast one user');
        }
    };

    const options = state?.domain?.get('PROFILE_SENDING_REASONS_CATEGORY');
    const selectedSendingReasonValue = getDomainValueForCode(sendingReason, 'PROFILE_SENDING_REASONS_CATEGORY', state);

    const displayviewSentHistory = (alumni: any) => {
        if (alumni[0]?.lastSentDate == '' || alumni[0]?.lastSentDate == null) {
            alert('No Sent History Available');
        } else {
            getSentHistory(alumni);
        }
        console.log(alumni);
    };

    const getSentHistory = async (alumni: any) => {
        try {
            const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/users/get-sent-profile-data`, {
                uuid: alumni[0]?.uuid,
                instituteId: alumni[0]?.instituteId,
            });
            const data = await response.data;
            if (data.success) {
                setViewSentHistory(data.data);
                let maskedMobile = '';
                let maskedEmail = '';
                if (!isEmpty(alumni[0]?.mobileNumber)) {
                    maskedMobile = `+${alumni[0]?.mobileCountryCode} ${'X'.repeat(
                        alumni[0]?.mobileNumber.length - 4
                    )}${alumni[0]?.mobileNumber.slice(-4)}`;
                }
                const email = alumni[0].registeredEmailId;
                const atIndex = email.indexOf('@');
                if (atIndex !== -1) {
                    maskedEmail = email[0] + email.substring(1, atIndex).replace(/./g, 'X') + email.substring(atIndex);
                }

                setAlumniData({
                    ...alumniData,
                    AlumniName: formatFullName(alumni[0]?.firstName, alumni[0]?.lastName),
                    AlumniMobile: maskedMobile,
                    AlumniUserId: alumni[0]?.uuid,
                    AlumniEmail: maskedEmail,
                    ProfileSendUsers: data.data[0]?.send_to ? JSON.parse(data.data[0]?.send_to) : '',
                    LastSentDate: alumni[0]?.lastSentDate,
                    AlumniPassoutYear: alumni[0]?.passOutYear,
                    AlumniprogramName: alumni[0]?.programName,
                    CurrentStatus: alumni[0]?.currentStatus,
                    AlumnischoolName: alumni[0]?.schoolName,
                    profilePictureFilePath: alumni[0]?.profilePictureFilePath,
                });

                //dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showSentHistoryModal' } });
                const modalElement = document.getElementById('view-sent-history');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
            // setAdminUsers(data.data)
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const getValuableQaModal = async (logid: any) => {
        try {
            console.log('alumniId', logid);
            const response = await axiosClient().get(
                `${process.env.REACT_APP_MESSAGE_SERVER}/conversations/get-conversation-by-logid/${logid}`
            );
            if (response.status === 200) {
                setValuableQAData(response.data.conversations);
                var closeButton = document.getElementById('view-sent-history')?.getElementsByClassName('btn-close')[0];
                if (closeButton instanceof HTMLButtonElement) {
                    closeButton?.click();
                }
                //dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showSentHistoryModal' } });
                const modalElement = document.getElementById('view_sent_qa_pop');
                if (modalElement) {
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }
    };

    const getTagline = (row: any) => {
        if (!isEmpty(row.passOutYear)) {
            return `${row.programName} - ${row.passOutYear}`;
        } else if (!isEmpty(row.programName)) {
            return `${row.programName}`;
        } else {
            return '';
        }
    };

    const getAlumniNameField = (row: any) => {
        return (
            <div className="d-flex flex-column checkoption">
                <a
                    className="tablink"
                    href={`/alumni/${row.uuid}/profile`}
                    // href={`/${row?.userTypeCode?.toLowerCase()}/${row.uuid}/profile`}
                    // href={`/sso/${sessionStorage.getItem('user-hash')}/cHJvZmlsZQ==/${row.uuid}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    <i className="me-2 position-relative">
                        <img src={getStudentImage(row)} className="rounded-circle img-fluid" width="30" />
                        {showStar(row) && <i className="icon-star circle-img"></i>}
                    </i>
                    <span
                        data-bs-toggle="tooltip"
                        title={`${formatFullName(row.firstName, row.lastName)}`}
                        className="singlelinename font-16 lineHeight-16">
                        {formatFullName(row.firstName, row.lastName)}
                        <span className="d-block font-13 fst-italic"> {getTagline(row)} </span>
                    </span>
                </a>
                <div className="copyId d-flex align-items-center mt-1">
                    <span className="font-13 fst-italic singlelinename me-1"> {row.uuid} </span>
                    <button
                        id={`uuid-${row.uuid}`}
                        className="copy-text border-0 bg-transparent"
                        title="click to copy id"
                        onClick={() => handleCopyText(row.uuid, `uuid-${row.uuid}`)}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-copy"
                            viewBox="0 0 16 16">
                            <path
                                fill-rule="evenodd"
                                d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            <div>
                <div className="ActiveListings_present">
                    <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                        <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                            <div className="d-flex justify-content-start align-items-center customselect_parnt"></div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center justify-content-end">
                            <h3 className="font-14 font-500 mb-0 me-2">
                                Total{' '}
                                {state?.internal?.alumniCount &&
                                    state?.internal?.alumniCount[0].totalInstitute.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                                records
                            </h3>
                        </div>
                    </div>
                    <div className="table-responsive attendee_dtl">
                        <table id="example1" className="table sticky_table_3_columns" style={{ minWidth: '2500px' }}>
                            <thead>
                                <tr>
                                    {gridColumns.includes(Column.ACTION) && (
                                        <th className="text-center" style={{ width: '140px' }}>
                                            Action
                                        </th>
                                    )}
                                    <th style={{ width: '250px' }}>
                                        <div className="d-flex flex-wrap">
                                            <div> Name</div>
                                        </div>
                                    </th>
                                    {gridColumns.includes(Column.CAMPUS) && (
                                        <th style={{ width: '180px' }} className="university-name">
                                            Campus
                                        </th>
                                    )}
                                    {gridColumns.includes(Column.PRESENT_STATUS) && <th style={{ width: '180px' }}>Present Status</th>}

                                    <th>Course</th>
                                    <th style={{ width: '240px' }}>University</th>
                                    <th>Exam Details</th>
                                    <th>Engagements</th>
                                    <th>Last Interaction </th>
                                    <th>Work-Ex</th>
                                    <th>Location</th>
                                    <th>Achievements </th>
                                </tr>
                            </thead>
                            <tbody>
                                {visibleStudents.map((row: any) => (
                                    <tr className={`${row?.userStatusCode != 'ACTIVE' ? ' bg-warning ' : ''}`}>
                                        {gridColumns.includes(Column.ACTION) && (
                                            <td className="text-center">
                                                <div className="d-flex flex-column">
                                                    {
                                                        <EngagementActionSettings
                                                            id={row}
                                                            actions={getValidActions(row.userStatusCode)}
                                                            onClick={handleAction}
                                                        />
                                                    }
                                                    <span className="last-sent">
                                                        Last Sent -
                                                        <div>
                                                            {row.lastSentDate ? moment(row.lastSentDate).format('DD-MMM-YYYY') : 'NA'}
                                                        </div>
                                                    </span>
                                                </div>
                                            </td>
                                        )}
                                        <td>
                                            <div className="d-flex flex-wrap">
                                                <div className="name d-flex flex-column line-height-18">
                                                    {getAlumniNameField(row)}
                                                </div>
                                            </div>
                                        </td>

                                        {gridColumns.includes(Column.CAMPUS) && (
                                            <td>
                                                <div className="d-flex flex-column">
                                                    <span>
                                                        <b className="font-500">{row.schoolName}</b>
                                                    </span>
                                                </div>
                                            </td>
                                        )}

                                        {gridColumns.includes(Column.PRESENT_STATUS) && (
                                            <td>
                                                <span>
                                                    Education status:{' '}
                                                    {getDomainValueForCode(row.presentEducationStatusCode, 'PRESENT_EDUCATION_STATUS_CODE', state) ?? ''}
                                                </span>
                                                <br />
                                                <span>
                                                    Working status:{' '}
                                                    {getDomainValueForCode(row.workingStatusCode, 'WORK_STATUS_CODE', state) ?? ''}
                                                </span>
                                            </td>
                                        )}

                                        <td>
                                            {row.fieldOfStudyCode && (
                                                <div className="course-list-row">
                                                    <span>
                                                        UG -{' '}
                                                        {getDomainValueForCode(row.fieldOfStudyCode, 'FIELD_OF_STUDY_TYPE_CODE_ALUMNI', state) ??
                                                            ''}
                                                    </span>
                                                    {row.scholarshipAmountUg > 0 && (
                                                        <i
                                                            className="icon-dollor font-20 defaultToolTIp"
                                                            data-bs-toggle="tooltip"
                                                            title={`Received Scholarship 
                                                            ${
                                                                row.scholarshipTypeCodeUg !== 'AMOUNT' ? 
                                                                `${row.scholarshipAmountUg}%` : 
                                                                `${getDomainValueForCode(row.scholarshipCurrencyCodeUg, 'CURRENCY_CODE', state)}:${row.scholarshipAmountUg}`
                                                            }`}
                                                        ></i>
                                                    )} 
                                                      
                                                </div>
                                            )}
                                            {row.pgFieldOfStudyCode && (
                                                <div className="course-list-row">
                                                    <span>
                                                        PG -{' '}
                                                            <span>
                                                                {getDomainValueForCode(
                                                                    row.pgFieldOfStudyCode,
                                                                    'FIELD_OF_STUDY_TYPE_CODE_ALUMNI',
                                                                    state
                                                                )}
                                                            </span>
                                                    </span>
                                                    
                                                    {row.pgScholarshipAmount > 0 && (
                                                        <i
                                                            className="icon-dollor font-20 defaultToolTIp"
                                                            data-bs-toggle="tooltip"
                                                            title={`Received Scholarship 
                                                            ${
                                                                row.scholarshipTypeCodePg !== 'AMOUNT' ? 
                                                                `${row.pgScholarshipAmount}%` : 
                                                                `${getDomainValueForCode(row.scholarshipCurrencyCodePg, 'CURRENCY_CODE', state)}:${row.pgScholarshipAmount}`
                                                            }`}
                                                        ></i>
                                                    )} 
                                                </div>
                                            )}
                                        </td>

                                        <td className="text-start">
                                            {row?.universityName && (
                                                <div className="d-flex flex-wrap">
                                                    <div className="universityName">
                                                        <span>UG - {row?.universityName}</span>
                                                        {row?.isPrestigiousCollege === 1 && (
                                                            <i
                                                                className="icon ms-1"
                                                                data-bs-toggle="tooltip"
                                                                title=""
                                                                data-bs-original-title="Prestigious College">
                                                                <img
                                                                    src="https://miles.univariety.com/school/public/images/subtraction.svg"
                                                                    width="16"
                                                                />
                                                            </i>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                            {row?.pgUniversityName && (
                                                <div className="d-flex flex-wrap">
                                                    <div className="universityName">
                                                        <span>PG - {row?.pgUniversityName}</span>
                                                        {row?.pgIsPrestigiousCollege === 1 && (
                                                            <i
                                                                className="icon ms-1"
                                                                data-bs-toggle="tooltip"
                                                                title=""
                                                                data-bs-original-title="Prestigious College">
                                                                <img
                                                                    src="https://miles.univariety.com/school/public/images/subtraction.svg"
                                                                    width="16"
                                                                />
                                                            </i>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            {row.ugExams != null ? (
                                                <div className="d-flex flex-column">{exam_tool(row)}</div>
                                            ) : (
                                                <div>-</div>
                                            )}
                                        </td>

                                        {gridColumns.includes(Column.ENGAGEMENTS) && (
                                            <td className="text-start">
                                                {row.guidanceVideoCount > 0 && (
                                                    <div>
                                                        {row.guidanceVideoCount} video (
                                                        <Link
                                                            className="btn-conview"
                                                            to={`/alumni/${row.uuid}/profile/contributions`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            View
                                                        </Link>
                                                        )
                                                    </div>
                                                )}
                                                {row.conversationCount > 0 && (
                                                    <div>
                                                        {row.conversationCount} Conversation (
                                                        <Link
                                                            className="btn-conview"
                                                            to={`/internal/alumni-interaction/${row.uuid}/${row.instituteId}`}
                                                            target="_blank"
                                                            rel="noopener noreferrer">
                                                            View
                                                        </Link>
                                                        )
                                                    </div>
                                                )}
                                            </td>
                                        )}

                                        <td>
                                            {row.lastInteractionDate != null ? (
                                                <div>{moment(row.lastInteractionDate).format('DD-MMM-YYYY')}</div>
                                            ) : null}
                                        </td>
                                        <td>
                                            {(row.companyId !== null || row.otherCompany !== null) && (
                                                <div className="d-flex flex-column">
                                                    {row.seniorityLevelCode && row.seniorityLevelCode.trim() !== '' ? (
                                                        <span>
                                                            <b className="blue-badge primary">{row.seniorityLevelCode} </b>
                                                        </span>
                                                    ) : (
                                                        <span>
                                                            <b className="blue-badge primary">No Designation </b>
                                                        </span>
                                                    )}
                                                    <span className="d-flex flex-wrap mb-1">
                                                        {row.jobDesignation} at{' '}
                                                        {row.companyId === null
                                                            ? row.otherCompany
                                                            : getDomainValueForCode(row.companyId, 'COMPANY_CODE', state)}
                                                    </span>
                                                </div>
                                            )}
                                            {isEmpty(row.companyId) && isEmpty(row.otherCompany) && <div>NA</div>}
                                        </td>

                                        <td className="text-start w-110">
                                            {row.residingCityCode &&
                                                (getDomainValueForCode(row.residingCityCode, 'CITY_CODE', state) ?? '')}
                                            <br />
                                            {getDomainValueForCode(row.residingCountryCode, 'COUNTRY_CODE', state) ?? ''}
                                        </td>
                                        <td className="">
                                            {row.achievementTypeCode != null ? (
                                                <div>
                                                    {row.achievementTypeCode.split(',').length} Achievements <br></br>(
                                                    <a
                                                        href="javascript:;"
                                                        className="btn-conview btn-html "
                                                        data-bs-toggle="tooltip"
                                                        data-bs-html="true"
                                                        title=""
                                                        data-bs-original-title={achiev_tool(row)}>
                                                        View
                                                    </a>
                                                    )
                                                </div>
                                            ) : (
                                                <div>NA</div>
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                <tr ref={loadMoreRef} />
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <SendEmailModal
                data={{
                    alumniData,
                    options,
                    handleNextPreviewAdminSelection,
                    errors,
                    sendingReason,
                    handleSendingReasonChange,
                    comment,
                    handleCommentChange,
                    communicationChannel,
                    handleCommunicationChannelChange,
                    alumni,
                    getSentHistory,
                    valuablePage,
                }}
            />

            <SelectUserModal
                data={{ alumniData, selectedSendingReasonValue, adminUsers, handlePreviewEmailPopup, alumni, getSentHistory }}
            />
            <EmailPreviewModal
                data={{
                    selectedSendingReasonValue,
                    EmailSubject,
                    EmailBody,
                    SubmitEmailPreview,
                    communicationChannel,
                    selectedItems,
                    handleRemoveEmail,
                    setEmailSubject,
                    setEmailBody,
                }}
            />
            <WtappPreviewModal
                data={{ selectedSendingReasonValue, communicationChannel, WtappBody, SubmitWtappPreview, selectedItems, handleRemoveEmail }}
            />
            <ViewSentHistoryModal data={{ alumniData, viewSentHistory, getValuableQaModal }} />
            <ValuableQAModal data={{ alumniData, valuableQAData, getSentHistory, alumni }} />
        </>
    );
};

export default AlumniEngagementGrid;
