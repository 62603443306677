import { useContext, useState } from 'react';
import { SmartContext } from "../../../../../../library/Core/SmartContext";

const ViewProfileMarkersLogs = (props: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const showFlag = state?.modalFlags?.showprofileMarkerLogs;
    const modalData = state?.modalData;

    const logs = modalData?.data?.flat() || [];
    const hasHistory = logs.length > 0;
    const [expandedComments, setExpandedComments] = useState<Record<number, boolean>>({}); 

    type LogPropsArrayType = {
        is_leadership_role_in_large_company: { label: string,isComment :string};
        is_leadership_role_in_sme_company: { label: string,isComment :string};
        work_experience: {label: string,isComment :string};
        is_leadership_role_in_a_listed_company: { label: string,isComment :string};
        is_growing_startup_founder: { label: string,isComment :string };
        is_mid_senior_role_in_fortune_company: { label: string,isComment :string };
        director_or_board_member: { label: string,isComment :string };

        age: { label: string;};
        genderCode: { label: string;};
        isSpouseSameInstitute: { label: string; isComment: string;};
        isSiblingSameInstitute: { label: string; isComment: string;};
        StudiedAbroad: { label: string; isComment: string;};
        CurrentlyAbroad: { label: string; isComment: string;};
        LivedAbroadandBack: { label: string; isComment: string;};
        DidnotTakeEducationloan: { label: string; isComment: string };
        iswealthyNeighborhood: { label: string; isComment: string };

        celebrity: { label: string,isComment :string,};
        wealthyfamily: { label: string,isComment:string };
        investedinaCompany: { label: string,isComment:string };
        featuredinHighFortuneRichestList: { label: string,isComment:string };
        politicalConnection: { label: string,isComment:string };
        influentialPositionOfPower: { label: string,isComment:string };
        ownsLuxuryCar: { label: string,isComment:string };
        featuredinHighTaxPayerList: { label: string,isComment:string };
        mediaMentions: { label: string };
        donatedAnyCauseInPast:{label:string};
        donatedToOtherInstitutes:{label:string};
        recruitedInPast:{label:string,isComment:string};
        recruitingFromOtherInstitutes:{label:string,isComment:string};

        networkingEvents: { label: string,isComment :string};
        lAndDEvents: { label: string,isComment :string};
        instituteSocialMedia: { label: string,isComment :string};
        donationPledges:{label: string,isComment :string};
        recognitionbyInstitute:{label: string,isComment :string};
        managementInteractions:{label: string,isComment :string};
        viewAboutInstitute:{label: string,isComment :string};
        onlineReviews: { label: string,isComment :string};
        admissionReferrals: { label: string,isComment :string};
        isChildSameInstitute:{ label: string,isComment :string};
        // isParentsSameInstitute:{ label: string,isComment :string};
        receivedScholarship:{ label: string,isComment :string};
        benefitsAvailed:{ label: string,isComment :string};
        guidanceVideos:{ label: string,isComment :string};
        jobPosts:{ label: string,isComment :string};
        albums:{ label: string,isComment :string};
        negativeExperience:{ label: string,isComment :string};

        officeBearerofAlumniAssociation: { label: string,isComment:string};
        positionHolderdursingInstitute: { label: string,isComment:string};
        socialInfluence: { label: string,isComment:string};
        popularityinBatch:{ label: string,isComment:string};
        helpedinAdmissions: { label: string,isComment:string};

        isFundingStartUp: { label: string; isComment: string;};
        engagedWithDonorCampaigns: { label: string; isComment: string;};
        engagedWithRecruiterCampaigns: { label: string; isComment: string };
        engagedWithGeneralInstituteCampaigns: { label: string; isComment: string };
        engagedWithRecognitionCampaigns: { label: string; isComment: string };
        engagedWithNetworkingCampaigns: { label: string; isComment: string };
        engagedWithEmotionalConnectCampaigns: { label: string; isComment: string };
        engagedWithRecreationEventsCampaigns: { label: string; isComment: string };
        engagedWithLearningAndDevelopmentCampaigns: { label: string; isComment: string };
        engagedWithHumorCampaigns: { label: string; isComment: string };
    };

    const logsPropsArray: LogPropsArrayType = {
        is_leadership_role_in_large_company: { label: 'Leadership Role in Large Company',isComment:''},
        is_leadership_role_in_sme_company: { label: 'Leadership Role in SME Company',isComment:''},
        work_experience: {label: 'Work Experience > 15 Years',isComment:''},
        is_leadership_role_in_a_listed_company: {label: 'Leadership Role in a Listed Company',isComment:''},
        is_growing_startup_founder: {label: 'Growing Start-up Founder',isComment:''},
        is_mid_senior_role_in_fortune_company: {label: 'Mid-Senior Role in Fortune 500 Company',isComment:''},
        director_or_board_member: {label: 'Director OR Board Member of Company',isComment:''},

        age: { label: 'Age' },
        genderCode: { label: 'Gender' },
        isSpouseSameInstitute: { label: 'Spouse also from same Institute', isComment: 'spouseComment' },
        isSiblingSameInstitute: { label: 'Sibling also from same Institute', isComment: 'siblingComment' },
        StudiedAbroad: { label: 'Study Abroad', isComment: 'StudiedAbroadComment' },
        CurrentlyAbroad: { label: 'Currently Abroad', isComment: 'CurrentlyAbroadComment' },
        LivedAbroadandBack: { label: 'Lived Abroad and Back', isComment: 'LivedAbroadandBackComment' },
        DidnotTakeEducationloan: { label: 'Did not Take Education loan', isComment: 'EducationLoanComment'},
        iswealthyNeighborhood: { label: 'Wealthy Neighborhood', isComment: 'wealthyNeighborhoodAddress' },

        celebrity: { label: 'Celebrity', isComment: 'celebrityComment' },
        wealthyfamily: { label: 'Wealthy Family', isComment: 'wealthyFamilyComment' },
        politicalConnection: { label: 'Political Connection', isComment: 'politicalConnectionComment' },
        influentialPositionOfPower: { label: 'Influential Position of Power', isComment: 'influentialPositionOfPowerComment' },
        ownsLuxuryCar: { label: 'Owns Luxury Car', isComment: 'ownsLuxuryCarComment' },
        investedinaCompany: { label: 'Invested in a Company', isComment: 'investedInAcompanyComment' },
        featuredinHighFortuneRichestList: { label: 'Featured in Fortune Richest List', isComment: 'featuredinHighFortuneRichestListComment' },
        featuredinHighTaxPayerList: { label: 'Featured in High Tax Payer List', isComment: 'featuredinHighTaxPayerListComment' },
        mediaMentions: { label: 'Media Mentions' },
        donatedAnyCauseInPast: { label: 'Donated to any cause in Past' },
        donatedToOtherInstitutes: { label: 'Donated to other Institutes' },
        recruitedInPast: { label: 'Recruited in Past', isComment: 'recruitedInPastComment' },
        recruitingFromOtherInstitutes: { label: 'Recruiting from other Institutes', isComment: 'recruitingFromOtherInstitutesComment' },
    

        networkingEvents: { label: 'Networking Events',isComment:'networkingEventsComment'},
        lAndDEvents: { label: 'L&D Events',isComment:'lAndDEventsComment'},
        instituteSocialMedia:{ label: 'Institute’s Social Media',isComment:'instituteSocialMediaComment'},
        donationPledges:{ label: 'Donation Pledges',isComment:'donationPledgesComment'},
        recognitionbyInstitute:{ label: 'Recognition by Institute',isComment:'recognitionbyInstituteComment'},
        managementInteractions:{ label: 'Management Interactions',isComment:'managementInteractionsComment'},
        viewAboutInstitute:{ label: 'Views about the institute',isComment:'viewAboutInstituteComment'},
        onlineReviews:{ label: 'Online Reviews',isComment:'onlineReviewsComment'},
        admissionReferrals:{ label: 'Admission Referrals',isComment:'admissionReferralsComment'},
        isChildSameInstitute:{ label: 'Child studying in same institute',isComment:'isChildSameInstituteComment'},
        // isParentsSameInstitute:{ label: 'Parents in same institute',isComment:'isParentsSameInstituteComment'},
        receivedScholarship: { label: 'Received Scholarship from Institute',isComment:'receivedScholarshipComment'},
        benefitsAvailed: { label: 'Benfits Availed', isComment: 'benefitsAvailedComment' },
        guidanceVideos:{ label: 'Guidance Videos',isComment:''},
        jobPosts:{ label: 'Job Posts',isComment:''},
        albums:{ label: 'Albums',isComment:''},
        negativeExperience:{ label: 'Negative Experience',isComment:'negativeExperienceComment'},

        officeBearerofAlumniAssociation: { label: 'Office Bearer of Alumni Association', isComment: 'officeBearerofAlumniAssociationComment' },
        positionHolderdursingInstitute: { label: 'Position holders during time at institute', isComment: 'positionHolderdursingInstituteComement' },
        socialInfluence: { label: 'Social Media Influencer', isComment: 'positionHolderdursingInstituteComement123' },
        popularityinBatch: { label: 'Popular in Batch', isComment: 'popularityinBatchComment' },
        helpedinAdmissions: { label: 'Helped in Admissions', isComment: 'helpedinAdmissionsComment' },

        isFundingStartUp: { label: 'Funding Start-ups', isComment: 'fundingStartUpsComment' },
        engagedWithDonorCampaigns: { label: 'Engaged with Donor Campaigns', isComment: 'engagedWithDonorCampaignsComment' },
        engagedWithRecruiterCampaigns: { label: 'Engaged with Recruiter Campaigns', isComment: 'engagedWithRecruiterCampaignsComment' },
        engagedWithGeneralInstituteCampaigns: { label: 'Engaged with General Institute Update Campaigns', isComment: 'engagedWithGeneralInstituteCampaignsComment' },
        engagedWithRecognitionCampaigns: { label: 'Engaged with Recognition Campaigns', isComment: 'engagedWithRecognitionCampaignsComment' },
        engagedWithNetworkingCampaigns: { label: 'Engaged with Networking Campaigns', isComment: 'engagedWithNetworkingCampaignsComment' },
        engagedWithEmotionalConnectCampaigns: { label: 'Engaged with Emotional Connect Campaigns', isComment: 'engagedWithEmotionalConnectCampaignsComment' },
        engagedWithRecreationEventsCampaigns: { label: 'Engaged with Recreation & Events Campaigns', isComment: 'engagedWithRecreationEventsCampaignsComment' },
        engagedWithLearningAndDevelopmentCampaigns: { label: 'Engaged with Learning & Development Campaigns', isComment: 'engagedWithLearningAndDevelopmentCampaignsComment' },
        engagedWithHumorCampaigns: { label: 'Engaged with Humor Campaigns', isComment: 'engagedWithHumorCampaignsComment' }
    };

    const toggleComment = (id: number) => {
        setExpandedComments(prev => ({ ...prev, [id]: !prev[id] }));
    };

    return (
        <div
            id="update-profilemarkerlogs-data"
            className={`modal fade ${showFlag ? 'show' : ''}`}
            tabIndex={-1}
            role="dialog"
            style={{ display: showFlag ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} >
            <div className="modal-dialog modal-dialog-centered max-800 mx-auto">
                <div className="modal-content">
                    <div className="modal-header p-4 height-70 border-0 bg-gray-f2">
                        <h5 className="modal-title" id="staticBackdropLabel">Profile Markers Logs</h5>
                        <button
                            type="button"
                            className="btn-close btn-close-small"
                            onClick={() => {
                                dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showprofileMarkerLogs' } });
                            }}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body p-4">
                    {hasHistory ? (
                            <div className="col-md-12 mb-4">
                                <div className="view-vertical">
                                    <div className="table-responsive">
                                        <table className="table w-750">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col" style={{ width: '130px' }}>Updated On</th>
                                                    <th scope="col" style={{ width: '130px' }}>Updated By</th>
                                                    <th scope="col">Details</th>
                                                    <th scope="col">Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                           {logs
                            .filter((item: any) => item.marker_field in logsPropsArray)
                            .map((item: any, index: number) => {
                                const logProps = logsPropsArray[item.marker_field as keyof LogPropsArrayType];
                                const label = logProps.label || item.marker_field;
                                let commentKey = 'isComment' in logProps ? logProps.isComment : null;
                                let comment = commentKey ? item[commentKey] : '';
                                let value = item?.value;

                                // Handle the special case for 'workProfile'
                                if (item.marker_type === 'workProfile') {
                                    value = item.marker_enabled;
                                    comment = item.remark || '';  
                                }

                                const isArraykey=['mediaMentions','networkingEvents','onlineReviews','lAndDEvents','recognitionbyInstitute','managementInteractions','socialInfluence']
                                // Format the value based on its type
                                let formattedValue;
                                if (isArraykey.includes(item.marker_type)) {
                                    formattedValue = value.length > 1 ? 'Yes' : 'No';
                                } else {
                                    formattedValue = (value == 1 || value == true) ? 'Yes' :
                                                     (value == 0 || value == false) ? 'No' : 
                                                     value;
                                }

                                let commentLog;
                                if (commentKey) {
                                    commentLog = logs.find((log:any) => log.marker_field === commentKey);
                                    comment = commentLog?.value || '-';
                                }
                                
                                const latestUpdatedAt = commentLog && commentLog.updated_at > item.updated_at 
                                    ? commentLog.updated_at 
                                    : item.updated_at;

                                const shouldShowReadMore = comment.length > 30;
                                const isExpanded = expandedComments[item.id || index];

                                return (
                                    <tr key={item.id || index}>
                                        <td>{index + 1}</td> 
                                        <td>{latestUpdatedAt}</td>
                                        <td>{item.added_by}</td>
                                        <td>
                                            <div>
                                                <strong>{label}:</strong> {formattedValue || 'No data'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex flex-wrap align-items-center">
                                                {shouldShowReadMore ? (
                                                    <>
                                                        <span>
                                                            {isExpanded ? comment : `${comment.substring(0, 20)}...`}
                                                        </span>
                                                        <a
                                                            href="javascript:;"
                                                            className="link ms-2"
                                                            onClick={() => toggleComment(item.id || index)}
                                                        >
                                                            {isExpanded ? 'Show less' : 'Read more'}
                                                        </a>
                                                    </>
                                                ) : (
                                                    <span>{comment || '-'}</span>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        ) : (
            <p>No history available for this profile marker.</p>
        )}
    </div>
</div>
</div>
        </div>
    );
};

export default ViewProfileMarkersLogs;
