import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import {
    handleCompleteLater,
    handleNext,
    onCurrentJobSelectionChange,
    onEndDateChange,
    onStartDateChange,
} from './ProfessionalWorkActions';
import ProfessionalWorkLayout from './ProfessionalWorkLayout';

const ProfessionalWork = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl
            //startDate: onStartDateChange,
            //endDate: onEndDateChange,
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    // const getSocialProfileLinks = () => {
    //     return (domain as any)?.get('SOCIAL_NETWORK_CODE').map((item: any) => ({ socialNetworkCode: item.code, link: '' }));
    // };

    useEffect(() => {
        // if (data['socialProfileLinks'].length === 0) data['socialProfileLinks'] = getSocialProfileLinks();
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

        const jobRoleCodeDomain = domain.get('JOB_ROLE_CODE');
        if (jobRoleCodeDomain) {
            const filteredJobRoleCode = jobRoleCodeDomain.filter((role) => role.code !== 'VISITING_FACULTY');
            domain.set('JOB_ROLE_CODE', filteredJobRoleCode);
        }
        
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <ProfessionalWorkLayout>{!state.flags.isDataLoading && <PageBuilder />}</ProfessionalWorkLayout>
        </SmartContext.Provider>
    );
};

export default ProfessionalWork;
