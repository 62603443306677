import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import { handleSave } from './Actions';
import Layout from './Layout';
import { getinstituteList } from '../../../library/Service/domainService';
import SessionContext from '../../../library/Core/SessionContext';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2';

const ManageEvents = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
  
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            SAVE: handleSave
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = new Map([...globalState.domain]);

        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: {
                    eventInfo: state?.data?.eventInfo || {
                        ...state?.data?.eventInfo,
                        audienceCategory: []
                    },
                    // classes: state?.data?.eventInfo?.classes || [{ id: 0, programLevel: 0, testIds: [] }]

                },
                domain,
                routeInfo,
            },
        });
    }, []);

    useEffect(() => {
        if (isEmpty(state.data?.eventInfo?.instituteId) || state.data?.eventInfo?.instituteId === 0) {
            return;
        }

        const fetchData = async () => {
            try {
                const response = await axiosClient().get(`${process.env.REACT_APP_COMMON_API}/test/get-school-test-mapping/${state.data?.eventInfo?.instituteId}`);
                console.log(response.data, " MAPPING DATA ");
                const eventInfo = response?.data?.data;

                if (eventInfo.length <= 0) {
                    Swal.fire({
                        title: "No programs added..!",
                        text: "Please add programs for this institute or try again with another institute..!!",
                        icon: "warning"
                    }).then(() => {
                        dispatch({
                            type: 'REFRESH_DATA',
                            payload: {
                                data: {
                                    ...state.data,
                                    eventInfo: {
                                        ...state.data.eventInfo,
                                        instituteId: 0
                                    }
                                }
                            }
                        });
                               
                    });
                    return false;
                }
                let classes: any = [];
                eventInfo.map((data: any, idx: number) => {
                    let testIds = data?.test_ids?.split(',');
                    let testIdData = testIds?.map((testId: number) => {
                        return {
                            testIds: testId
                        }
                    })

                    classes.push({
                        id: state.data.classes[idx]?.id ?? uuidv4(),
                        programLevel: data?.program_level,
                        testIds: testIdData
                    })
                })

                dispatch({
                    type: 'REFRESH_DATA',
                    payload: {
                        data: { ...state.data, classes: classes }
                    },
                });

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [state.data?.eventInfo?.instituteId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

const sendMoEngageEvent = async (user: any) => {
    try {
        const updatedUser = {
            ...user,
            reqType: 'Admin', // New key-value pair
        };
        const response = await axios.post(`${process.env.REACT_APP_MOENGAGE_API}/moengage/moengage-signup`, {
            userInfo: updatedUser
        });
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default ManageEvents;
