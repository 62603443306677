import { useContext, useEffect, useMemo, useState } from "react";
import AsyncSelect from "react-select/async";
import { SmartContext } from "../Core/SmartContext";
import { getControlValueFromState, getParentData, isEmpty } from "../Core/SmartFunctions";
import { v4 as uuidv4 } from "uuid";
import ErrorControl from "./ErrorControl";

const SelectEditableOnchange = (args) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };

    // Initial selected option from state
    const data = getControlValueFromState(dataKey, state);
    const [selectedOption, setSelectedOption] = useState(
        data ? { value: data.value || data, label: data.label || data } : null
    );

    const [domainCategoryCode] = control.props.domainCategoryCode.split("#");
    const manualEntryColumn = control?.props?.customProperties?.manualEntryColumn;
    const manualEntryData = getControlValueFromState(`${parentDataKey}.${manualEntryColumn}`, state);
    const parentData = control.props.parentId && getParentData(`${parentDataKey}.${control.props.parentId}`, state);

    const action = state?.actions?.[control.id];

    // Memoized domain options
    const options = useMemo(() => {
        const domainData = state?.domain?.get(domainCategoryCode) || [];
        const filteredDomainData = isEmpty(parentData)
            ? domainData
            : domainData.filter((domain) => domain.parentCode === parentData);

        return filteredDomainData.map((domain) => ({
            value: domain.code,
            label: domain.value,
        }));
    }, [state.domain, domainCategoryCode, parentData]);

    // Sync selectedOption when state changes
    useEffect(() => {
        const option = options.find((opt) => opt.value === data?.value || opt.value === data);
        if (option) {
            setSelectedOption({ value: option.value, label: option.label });
        } else if (manualEntryData) {
            setSelectedOption({ value: manualEntryData, label: manualEntryData });
        } else {
            setSelectedOption(null);
        }
    }, [data, manualEntryData, options]);

    const processOptionValue = (optionValue, isManual = false) => {
        const manualEntryKey = `${dataKey}_manual`;
        const selectedOption = options.find((opt) => opt.value === optionValue);

        let valueToSet;

        if (selectedOption && !isManual) {
            valueToSet = { value: selectedOption.value, label: selectedOption.label };

            dispatch({
                type: "CONTROL_VALUE_CHANGE",
                payload: { dataKey, name: control.id, value: valueToSet, errorMessages: [] },
            });

            dispatch({
                type: "CONTROL_VALUE_CHANGE",
                payload: { dataKey: manualEntryKey, name: control.id, value: null, errorMessages: [] },
            });
        } else if (isManual && optionValue) {
            valueToSet = { uuid: uuidv4(), value: optionValue, label: optionValue };

            dispatch({
                type: "CONTROL_VALUE_CHANGE",
                payload: { dataKey: manualEntryKey, name: control.id, value: valueToSet, errorMessages: [] },
            });

            dispatch({
                type: "CONTROL_VALUE_CHANGE",
                payload: { dataKey, name: control.id, value: null, errorMessages: [] },
            });
        } else {
            dispatch({
                type: "CONTROL_VALUE_CHANGE",
                payload: { dataKey, name: control.id, value: null, errorMessages: [] },
            });

            dispatch({
                type: "CONTROL_VALUE_CHANGE",
                payload: { dataKey: manualEntryKey, name: control.id, value: null, errorMessages: [] },
            });
        }

        setSelectedOption(valueToSet);
    };

    const handleChange = (option) => {
        if (option && option.value) {
            processOptionValue(option.value);
        } else {
            processOptionValue(option, true);
        }

        if (action) {
            action(option);
        }
    };

    const loadOptions = (inputValue, callback) => {
        const latestOptions = state?.domain?.get(domainCategoryCode)?.map((domain) => ({
            value: domain.code,
            lowerLabel: domain.value?.toLowerCase() ?? "",
            label: domain.value,
        })) || [];

        if (inputValue.length < 3) {
            callback([]);
        } else {
            callback(latestOptions.filter((option) => option.lowerLabel.includes(inputValue.toLowerCase())));
        }
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {control.props.label}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-text bg-white rounded-start">
                    <i className="bi bi-search fs-6"></i>
                </div>
                <div className="flex-fill form-control border-start-0 p-0">
                    <AsyncSelect
                        loadOptions={loadOptions}
                        value={selectedOption}
                        onChange={handleChange}
                        isClearable
                        isSearchable
                        cacheOptions
                        noOptionsMessage={({ inputValue }) =>
                            inputValue.length < 3 ? "Enter minimum three characters" : "No options"
                        }
                    />
                </div>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectEditableOnchange;
