import { useContext, useEffect } from 'react';
import { DispatchEvent, State } from '../../../../../../library/Core/SmartTypes';
import { SmartContext } from '../../../../../../library/Core/SmartContext';
import PageBuilder from '../../../../../../library/Builders/PageBuilder/PageBuilder';
import EditPositionStrengthFormLayout from './EditPositionStrengthFormLayout';
import ProfileMarkerService from './ProfileMakerService';

const EditPositionStrengthModal = ({ userdata }: any) => {
    const { state, dispatch } = useContext(SmartContext);
    const { saveMarker, fetchUserProfileMarkers } = ProfileMarkerService();
    const handleSave = async (
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void
    ) => {
        event.preventDefault();
        let payload = {
            markerType: 'positionStrength',
            ...state?.data?.positionStrength,
            // ...state?.data?.mediaMentions,
        };

        // if (state?.data?.visibleControl === 'mediaMentions' && Array.isArray(state?.data?.mediaMentions)) {
        //     payload.markerType = 'mediaMentions'
        //     payload.mediaMentions = JSON.stringify(state?.data?.mediaMentions);
        // }
        if (state?.data?.visibleControl === 'mediaMentions') {
            if (Array.isArray(state?.data?.mediaMentions)) {
                const validMediaMentions = state.data.mediaMentions.filter((mention: any) =>
                    mention &&
                    typeof mention.mediaMentionsName !== 'undefined' &&
                    mention.mediaMentionsName !== '' &&
                    typeof mention.mediaMentionsDetails !== 'undefined' &&
                    mention.mediaMentionsDetails !== ''
                );
        
                if (validMediaMentions.length > 0) {
                    payload.markerType = 'mediaMentions';
                    payload.mediaMentions = JSON.stringify(validMediaMentions);
                } else {
                    payload.mediaMentions = JSON.stringify([{}]); 
                }
            } else {
                payload.mediaMentions = JSON.stringify([{}]);
            }
        }
        
        
        await saveMarker({...payload,syncData : state?.data?.syncData?.[0] || {}}, state?.internal?.model?.id);
        await fetchUserProfileMarkers(state?.internal?.model?.id);
        dispatch({ type: 'TOGGLE_MODAL_VISIBILITY', payload: { modalName: 'showPositionStrengthModal' } });
    };

    useEffect(() => {
        dispatch({ type: 'ADD_ACTIONS', payload: { ...state.actions, SAVE: handleSave } });
    }, []);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <EditPositionStrengthFormLayout>{!state.flags.isDataLoading && <PageBuilder />}</EditPositionStrengthFormLayout>
        </SmartContext.Provider>
    );
};

export default EditPositionStrengthModal;
