import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains, getDomainElementsForGivenYearRange } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onStartOrEndYearChange } from './UnderGraduateActions';
import UnderGraduateLayout from './UnderGraduateLayout';
import { getFlexColDomainValueForCode, isEmpty } from '../../../../library/Core/SmartFunctions';
import moment from 'moment';

const UnderGraduate = () => {
    const navigate = useNavigate();
    const { globalState } = useContext(GlobalContext);
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            startYear: onStartOrEndYearChange,
            endYear: onStartOrEndYearChange,
            REDIRECT: setRedirectUrl,
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        data['underGradCollegeJoined'] = data['underGradCollegeJoined'] || {};
        console.log(data);
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);
        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });

        if (data?.underGradCollegeJoined?.startYear == null || data?.underGradCollegeJoined?.endYear == null) {
            dispatch({
                type: 'SET_FIELD_VALIDATION_ERRORS',
                payload: {
                    dataKey: 'underGradCollegeJoined.ugJoinedStartAndEndYear',
                    errorMessages: ['Please enter both start and end year'],
                },
            });
        }
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    useEffect(() => {
        let categoryCode = 'UG_START_YEAR_CODE';

        let ugInstitute = getFlexColDomainValueForCode(state?.data?.underGradCollegeJoined?.universityId, 'INSTITUTE_DOMAIN', {
            domain: globalState.domain,
        } as State);

        let studentPassOutYear = isEmpty(ugInstitute) ? '1970' : JSON.parse(ugInstitute).founding_year ?? 1970;

        const ugPgStartYearDomain = getDomainElementsForGivenYearRange(categoryCode, studentPassOutYear, moment().year());
        // if (ugPgStartYearDomain.length > 0) globalState.domain.set(categoryCode, ugPgStartYearDomain);
        dispatch({
            type: 'SET_DOMAIN',
            payload: { key: 'UG_START_YEAR_CODE', value: ugPgStartYearDomain },
        });
        //alert(state?.data?.underGradCollegeJoined?.startYear);
        if (state?.data?.underGradCollegeJoined?.startYear && state?.data?.underGradCollegeJoined?.startYear !== 'undefined') {
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: {
                    dataKey: 'underGradCollegeJoined.startYear',
                    value: state?.data?.underGradCollegeJoined?.startYear,
                },
            });
        }
    }, [state.data?.underGradCollegeJoined?.universityId]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <UnderGraduateLayout>{!state.flags.isDataLoading && <PageBuilder />}</UnderGraduateLayout>
        </SmartContext.Provider>
    );
};

export default UnderGraduate;
