import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { axiosClient } from '../../library/Service/axiosClient';
import { useLocation } from 'react-router-dom';
import SessionContext from '../../library/Core/SessionContext';
import { SmartContext } from '../../library/Core/SmartContext';
import { useNavigate } from 'react-router';
import { SimpleFormControlArguments, State } from '../../library/Core/SmartTypes'
import ReactQuill from 'react-quill';
// import html2canvas from 'html2canvas';
import Swal from 'sweetalert2';

const uploadImg = `${process.env.REACT_APP_IMAGE_BASEPATH}/upload.svg`;
const editorStyle = {
	// Adjust the height as needed
};

interface Account {
	access_token: string;
	name: string;
	id: string;
}

const SocialPost = (args: SimpleFormControlArguments) => {
	//variable initialization	
	const { state, dispatch } = useContext(SmartContext);
	const navigate = useNavigate();
	const [image, setImage] = useState('');
	const location = useLocation(); // Get the current location, including state
	const submittedData = location.state; // Retrieve the passed data
	const [postText, setPostText] = useState<string>('');
	const defaultPage = 'facebook';
	const [networkType, setNetworkType] = useState('');
	const [selectedPageData, setSelectedPageData] = useState<any | null>(null); // State to store the selected page details
	const [pageData, setPageData] = useState<any[]>([]);
	const [socialNetworkPages, setSocialNetworkPages] = useState<any[]>([]);
	const [invalidPageSelection, setInvalidPageSelection] = useState(false);
	const [uniqueNetworkTypes, setUniqueNetworkTypes]: any = useState<any[]>([]);
	const networkTypeMap: any = {
		facebook: "Facebook",
		instagram: "Instagram",
		linkedin: "LinkedIn",
		twitter: "Twitter"
	}

	const { sessionState } = useContext(SessionContext);

	useEffect(() => {
		// Fetch template data when the component mounts
		if (submittedData !== null) {
			fetchSharedData();
		}
	}, []); // The empty dependency array ensures this effect runs only once

	useEffect(() => {
		const fetchData = async () => {
			const response: any = await getPageData();
			// Filter only active records before processing
			const activeRecords = response.data.filter((item: any) => item.status === 'Active');

			// Set the filtered social network pages data
			setSocialNetworkPages(activeRecords);

			// Extract unique network types from the active records
			const uniquePages = Array.from(
				new Set(activeRecords.map((item: any) => item.network_type))
			);
			setUniqueNetworkTypes(uniquePages);
			setNetworkType(defaultPage)
		};

		fetchData();
	}, []);

	useEffect(() => {
		const matchingPages = socialNetworkPages.filter((page: any) => page.network_type === networkType);
		setPageData(matchingPages);
		setSelectedPageData(null);
	}, [networkType]);
	
	const fetchSharedData = async () => {
		try {
			if (!submittedData) {
				console.log('No data received');
			} else {
				let response = await axiosClient().get(
					`${process.env.REACT_APP_LEAD_SERVER}/userinfo/getuserinfo/${submittedData['studentID']}`
				);

				const alumniName = response?.data.userinfo.first_name ? response?.data.userinfo.first_name : '';
				const alumnipic = response?.data.userinfo.profile_picture_file_path ? response?.data.userinfo.profile_picture_file_path : '';
				const programName = response?.data.userinfo.display_name ? response?.data.userinfo.display_name : '';
				const batch = response?.data.userinfo.passOutYear ? response?.data.userinfo.passOutYear : '';
				let AchiDetail = ''
				if (!response?.data.achievement || response?.data.achievement === '') {
					AchiDetail = ''; // If there's no achievement, set to empty
				} else {
					// If achievement exists, get the title or set to empty if undefined
					AchiDetail = response?.data.achievement.title || '';
				}
				const instituteName = response?.data.userinfo.institutename ? response?.data.userinfo.institutename : '';
				let universityName = '';
				if (response?.data.pginfo === 'undefined' || response?.data.pginfo === '') {
					universityName = response?.data.pginfo.display_name ? response?.data.pginfo.display_name : '';
				} else {
					universityName = response?.data.uginfo.display_name ? response?.data.uginfo.display_name : '';
				}

				const currentCompanyName = '';

				if (submittedData['category'] === '1') {
					setPostText(`We\'re thrilled to highlight the accomplishments of ${alumniName} from the ${programName} Batch of ${batch}. In their recent achievement of ${AchiDetail}, they made the institute proud. ${alumniName}, we miss your time at the institute! <p> It\'s moments like these that truly make the legacy of ${instituteName} shine with pride. <p> #ProudMoment #AlumniAchievements #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `);
					let link = alumnipic;
					setImage(link);
				} else if (submittedData['category'] === '2') {
					setPostText(`Introducing ${alumniName}, our exceptional alumni from the ${programName} Batch of ${batch}! Started the journey from our institute\'s halls to excelling at the ${universityName} and has now reached remarkable heights at ${currentCompanyName}. Their success story inspires us all. <p> ${alumniName}, we take immense pride in having such remarkable alumni. All the best for your future Endeavors! <p> #AlumniSuccessStories #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `);
					let link = alumnipic;
					setImage(link);
				} else if (submittedData['category'] === '3') {
					setPostText(`Meet ${alumniName} from the ${programName} Batch of ${batch} who is now a student of ${universityName}. We are always proud to see our alumni succeed in their personal and professional lives. We remember ${alumniName}\'s time at institute and couldn\'t be more proud! <p> ${alumniName}, we can\'t wait to see what you\'ll do next! All the best for your future endeavors. <p> #PrestigiousAlumni #AlumniImpactProgram #StudentAlumniNetwork #AlumniNetwork `);
					let link = alumnipic;
					setImage(link);
				}
			}
		} catch (error) {
			console.error('Error fetching template data:', error);
		}
	};

	const getPageData = async () => {
		try {
			const params = {
				'institute_id': sessionState?.institutes?.join(',')
			}
			// const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/get-social-network-pages`, params);
			const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/social/get-pages`, params);
			
			const responseData = await response.data;
			return responseData;
		} catch (error) {
			console.error('Error fetching data:', error);
			return [];
		}
	};

	const handleOnNetworkTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNetworkType(event.target.value);
	};

	const [isMessageFilled, setIsMessageFilled] = useState(false);

	useEffect(() => {
		setIsMessageFilled(postText.trim() !== ''); // Update isMessageFilled when postText changes
	}, [postText]);

	// function to get access tokenk
	async function getAccessToken(): Promise<string | null> {
		const accessToken = 'EAAfzEIFqUtoBOxk6xsenBX7S65zjHjnABIzYza4xqQQMdI6TKIPxDW3UvhWg7OrFb1emX9zqJZABUQQ1XJfE46ihHnqgWYegh3gUDN0v3sHbk2riDfsWEX5Tw3IL8zn66uDZCr3pCFlCRbQVp5QLZAfw6WuwaV9ES4a3ym8cUAXJN1uSZA5K4osKUySClVZADC4ddVHOafF9hNX1PPKkZAMLOC3UwAwZCN8SXWXVFh7icMhB1A1uApiTW0QbObx';//For testing
		return accessToken;

		// const clientId = '2237577053229786';
		// const clientSecret = 'aa316e7020610c83ec231eea91847f54';
		// const redirectUri = 'http://localhost:3000/share-profile';

		// const url = `https://graph.facebook.com/v16.0/oauth/access_token`;
		// const params = {
		//   client_id: clientId,
		//   client_secret: clientSecret,
		//   redirect_uri: redirectUri,
		//   code: code_url,
		// };

		// try {
		//   const response = await axios.get(url, { params });

		//   const accessToken= response.data.access_token;

		//   return accessToken;
		// } catch (error) {
		//   console.error('Error getting access token:', error);
		//   return null;
		// }
	}

	//function to get userid
	async function getUserId() {
		const accessToken = await getAccessToken();
		if (accessToken) {
			const fields = 'id';
			const url = `https://graph.facebook.com/v16.0/me`;
			const params = {
				client_id: fields,
				access_token: accessToken,
			};

			try {
				const response = await axios.get(url, { params });
				const id = response.data.id;
				return id;
			} catch (error) {
				console.error('Error getting access token:', error);
				return null;
			}
		}
	}

	//function to get pageid 
	async function getPagesId() {
		const accessToken = await getAccessToken();
		const id = await getUserId();
		if (accessToken) {
			const url = `https://graph.facebook.com/v16.0/` + id + `/accounts`;
			const params = {
				access_token: accessToken
			};

			try {
				const response = await axios.get(url, { params });
				const accounts = response.data.data.map((account: any) => {
					return {
						access_token: account.access_token,
						name: account.name,
						id: account.id
					};
				});

				return accounts;
			} catch (error) {
				console.error('Error getting accounts:', error);
				return [];
			}
		}
	}

	function decodeHtmlEntities(text: any) {
		const element = document.createElement('div');
		element.innerHTML = text;
		return element.innerText || element.textContent;
	}
	
    useEffect(() => {
        if (invalidPageSelection) {
            const timer = setTimeout(() => {
                setInvalidPageSelection(false);
            }, 3000);

            // Cleanup function to clear the timer when the component is unmounted
            return () => clearTimeout(timer);
        }
    }, [invalidPageSelection]);

	const handlePublish = async () => {
		if (!selectedPageData) {
			Swal.fire({
				position: "center",
				icon: "error", 
				title: "Please select the page to post..!",
				showConfirmButton: false,
				timer: 2000
			  });
			  setInvalidPageSelection(true)
			return false;
		}

		switch (networkType) {
			case 'facebook':
				handleFacebookSubmit();
				break;
			
			case 'instagram':
			case 'linkedin':
				// handleInstagramShare();
				break;
		}
	};

	// async function convertImagesToBase64(element: any) {
	// 	const images = element.getElementsByTagName('img');
		
	// 	const promises = Array.from(images).map((img: any) => {
	// 		return new Promise<void>((resolve, reject) => {
	// 			const imageURL = img.src;
	// 			const imgElement = new Image();
	// 			imgElement.crossOrigin = 'Anonymous'; // Ensure cross-origin images are handled
	// 			imgElement.src = imageURL;
	// 			imgElement.onload = function () {
	// 				const canvas = document.createElement('canvas');
	// 				canvas.width = imgElement.width;
	// 				canvas.height = imgElement.height;
	// 				const ctx: any = canvas.getContext('2d');
	// 				ctx.drawImage(imgElement, 0, 0);
	// 				const dataURL = canvas.toDataURL('image/png');
	// 				img.src = dataURL; // Replace the `src` attribute with the base64 data URL
	// 				resolve();
	// 			};
	// 			imgElement.onerror = reject;
	// 		});
	// 	});
	
	// 	return Promise.all(promises); // Wait for all images to be converted
	// }

	// async function getSnap(elId: string = ''): Promise<string | null> {
	// 	const element = document.getElementById(elId);
		
	// 	// Check if the element exists before capturing the screenshot
	// 	if (!element) {
	// 		console.error('Element not found.');
	// 		return null;
	// 	}
	
	// 	let imageUrl: string | null = null;
	
	// 	// Convert all images to base64 before capturing the canvas
	// 	await convertImagesToBase64(element);
	
	// 	// Capture the screenshot with the complete scroll area
	// 	const canvas = await html2canvas(element, {
	// 		scrollX: 0,
	// 		scrollY: 0,
	// 		useCORS: false,
	// 		windowWidth: element.scrollWidth,
	// 		windowHeight: element.scrollHeight,
	// 	});
	
	// 	// Create a new Promise to handle the asynchronous `toBlob` and API call
	// 	imageUrl = await new Promise((resolve, reject) => {
	// 		canvas.toBlob(async (blob) => {
	// 			if (blob) {
	// 				// Create a FormData object and append the blob (file)
	// 				const formData = new FormData();
	// 				formData.append('image', blob, `screenshot-${Date.now()}.jpg`);
	
	// 				// Replace this with your actual API endpoint
	// 				const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/image`;
	
	// 				try {
	// 					const response = await axiosClient().post(apiUrl, formData, {
	// 						headers: {
	// 							'Content-Type': 'multipart/form-data',
	// 						},
	// 					});
	
	// 					resolve(response?.data?.link); // Resolve the Promise with the image URL
	// 				} catch (error) {
	// 					console.error('Error uploading file', error);
	// 					reject(error); // Reject the Promise on error
	// 				}
	// 			} else {
	// 				console.error('Blob creation failed.');
	// 				reject(new Error('Blob creation failed'));
	// 			}
	// 		}, 'image/jpeg');
	// 	});
	
	// 	return imageUrl;
	// }

	// async function handleInstagramShare() {
	// 	const message 	= decodeHtmlEntities(postText);
	// 	const loader	= Swal.fire({
	// 		title: 'Preparing your post...',
	// 		html: 'Please wait while we prepare your post!',
	// 		allowOutsideClick: false,
	// 		didOpen: () => {
	// 			Swal.showLoading(); // Show loading spinner
	// 		},
	// 	});
	// 	const imgUrl = await getSnap('post_content');
	// 	console.log(imgUrl, " imgUrl ")
	// 	Swal.close();

	// 	const apiUrl = `${process.env.REACT_APP_COMMON_API}/social/publish`;
	// 	const postData = {
	// 		'id': selectedPageData.id,
    //         'imageUrl': imgUrl,
    //         'caption': message
	// 	}
		
	// 	try {
	// 		// Show loader before the request
	// 		const loader = Swal.fire({
	// 			title: 'Publishing your post...',
	// 			html: 'Please wait while we publish your post on Instagram!',
	// 			allowOutsideClick: false,
	// 			didOpen: () => {
	// 				Swal.showLoading(); // Show loading spinner
	// 			},
	// 		});
	// 		const response = await axiosClient().post(`${apiUrl}`, postData);
	// 		// Close loader after receiving the response
	// 		Swal.close();

	// 		if (response?.data?.error) {
	// 			Swal.fire({
	// 				title: `${response?.data?.error?.message}`,
	// 				text: `${response?.data?.error?.error_user_msg}`,
	// 				icon: "error"
	// 			});
	// 		} else {
	// 			Swal.fire({
	// 				title: "Done!",
	// 				text: "Your post has been published on Instagram!",
	// 				icon: "success"
	// 			  });
	// 		}
	// 	} catch (error: any) {
	// 		// In case axios throws an error, handle it here
	// 		if (error?.response?.data?.error) {
	// 			Swal.fire({
	// 				title: `Oops..!`,
	// 				text: `Something went wrong, Please try again in a while..!!`,
	// 				icon: "error"
	// 			});
	// 		} else {
	// 			// Handle unexpected errors here
	// 			console.error('Unexpected error:', error);
	// 			Swal.fire({
	// 				title: "An unexpected error occurred",
	// 				text: error.message,
	// 				icon: "error"
	// 			});
	// 		}
	// 	}
	// }

	async function handleFacebookSubmit() {
		if (image) {
			const photoUrl = image;
			const message = decodeHtmlEntities(postText);
			const accessToken = selectedPageData.access_token;
			const pageId = selectedPageData.page_id;
			let post_type: number = 0;
			if (photoUrl.length === 0) {
				post_type = 1;
			}

			await uploadPhoto(photoUrl, message, accessToken, pageId, post_type);
			// add social post
			//   let socialpostAdd = `${process.env.REACT_APP_LEAD_SERVER}/socialpost/addsocailpost`;
			//   axiosClient()
			//     .post(`${socialpostAdd}`, {
			//         eventId : eventId ? eventId : '',
			//         eventTitle: state['data']['addevent']['eventTitle'],
			//         eventMode: state['data']['addevent']['eventMode'],
			//         activeEvent: state['data']['addevent']['activeEvent'],                
			//         registrationsActive: state['data']['addevent']['registrationsActive'],
			//         eventType: state['data']['addevent']['eventType'],
			//         registrationLink: state['data']['addevent']['registrationLink'],
			//         videoLink: state['data']['addevent']['videoLink'],
			//         presenterCategory: state['data']['addevent']['presenterCategory'],
			//         eventDescription: state['data']['addevent']['eventDescription'],
			//         instituteId: state['data']['addevent']['instituteId'],
			//         eventImage: state['data']['addevent']['eventImage'],
			//         created_by: userId,
			//      })
			//      .then((res: any) => {
			//         alert(res.data.message)
			//         if (!res.data.eventId) return alert('There is some issue in event management. Please try after sometime!');
			//         navigate(`/socialmedia/socialpage`);

			//     });
			navigate(`/socialmedia/socialpage`);
			alert('Data posted successfully.'); // You can show a success message or perform other actions here
		} else {
			alert('Please select a image.'); // Handle the case when no page is selected
		}
	}

	// async function main() {
	// 	const accounts = await getPagesId();
	// 	if (accounts.length > 0) {
	// 		accounts.forEach((account: Account) => {
	// 			// const fetchData = async () => {
	// 			//     try {
	// 			//         const response = await axiosClient().post(`${process.env.REACT_APP_COMMON_API}/profile/save-social-data`,{'page_id':account.id,'school_id':1234,'access_token':account.access_token,'page_name':account.name});
	// 			//         const data = await response.data; // Extract the data from the response

	// 			//     } catch (error) {
	// 			//         console.error('Error fetching data:', error);
	// 			//         alert(error);
	// 			//     }
	// 			// };

	// 			//  fetchData();
	// 		});
	// 	} else {
	// 		console.log('No accounts found.');
	// 	}
	// }
	// main();

	const handleSocialPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedValue = event.target.value;
		// Find the selected page in the pageData array
		const page = pageData.find((page) => page.page_id === selectedValue);
		setSelectedPageData(page);
	};

	// Call anotherFunction
	async function uploadPhoto(url: string, message: any, accessToken: string, pageId: string, post_type: Number): Promise<void> {
		let api_url: string = "test";
		if (post_type === 1) {
			api_url = `https://graph.facebook.com/v16.0/${pageId}/feed`; // Update with the correct URL
		}
		else {
			api_url = `https://graph.facebook.com/v16.0/${pageId}/photos`; // Update with the correct URL
		}

		const apiEndpoint = api_url;
		const data = {
			url: url,
			message: message,
			access_token: accessToken,
		};

		try {
			const response = await axios.post(apiEndpoint, data);
			if (response.status === 200) {
				console.log('Data Posted successfully.');
			} else {
				console.log('Photo upload failed.');
			}
		} catch (error) {
			console.error('Error uploading photo:', error);
		}
	}

	const handleImageUpload = async (e: any) => {
		let link = '';
		if (e.target.files != null) {
			const apiUrl = `${process.env.REACT_APP_COMMON_API}/upload/profile`;
			const formData = new FormData();
			formData.append('image', e.target.files[0]);
			const response = await axiosClient().post(apiUrl, formData);
			link = response.data.link;
			setImage(link);
		}
	};

	return (
		<>
			<div className="main flex-1 bg-white">
				<div className='max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto'>
					<div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
						<h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title position-relative">
							Social Post <span className="custom-tool">Create New</span>
						</h3>
					</div>
					<div className='ActiveListings_present'>
						<div className='row g-4'>
							<div className='col-md-6'>
								<form action="" className="w-100 mx-auto">
									<div className="row g-3">
										<div className="col-md-12">
											<label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="BroadcastTitle">Post To</label>
											<div className="postOption">
												<ul>
												{uniqueNetworkTypes.map((eachNetworkType: any) => (
													<li className='mt-2' key={eachNetworkType}>
														<input 
															checked={eachNetworkType === networkType}
															type="radio"
															name="social"
															value={eachNetworkType}
															id={eachNetworkType}
															className="d-none"
															onChange={handleOnNetworkTypeChange}
														/>
														<label htmlFor={eachNetworkType}>
															<i></i> 
															<span>{networkTypeMap[eachNetworkType]}</span>
															<b></b>
														</label>
													</li>
												))}
												</ul>
											</div>
											<div className='col-12'>
												<div className='pagename'>
													<div className={`row g-3 mt-1 ${invalidPageSelection ? 'shake-err' : ''}`}>
														{
															pageData.map((page: any) => (
																<div className="col-auto" key={page.id}>
																	<div className="form-check">
																		<input
																			className="form-check-input"
																			type="radio"
																			name="flexRadioDefault"
																			value={page.page_id}
																			id={`flexRadioDefault${page.id}`}
																			checked={selectedPageData?.page_id === page.page_id}
																			onChange={handleSocialPageChange}
																		/>
																		<label className="form-check-label" htmlFor={`flexRadioDefault${page.id}`}>
																			{page.page_name}
																		</label>
																	</div>
																</div>
															))}
													</div>
													{/* <div className="text-danger mb-3">Please enter "Heading"</div> */}
												</div>
											</div>
										</div>

										<div className='col-md-12'>
											<label className="form-label m-0 mb-1 font-16 font-500 w-100" htmlFor="Message">Post Message</label>
											<ReactQuill
												style={editorStyle}
												value={postText}
												onChange={setPostText} // No need to use e.target.value, just the parameter value
												placeholder="Compose your message..."
												className='editor'
											/>
										</div>

										<div className="col-md-12">
											<div className="row mb-4">
												<div className="col-md-12 inputfile_parent m-0">
													<div className="file file--upload w-100">
														<label htmlFor="choose_image">
															<i className="material-icons"><img src={uploadImg} /></i> Browse your image
														</label>
														<input
															type="file"
															name='post_image'
															className="d-none"
															id="choose_image"
															onChange={handleImageUpload}
															accept="img/*"
															style={{ opacity: '0.3' }}
														/>
													</div>
													<div className="docselect">
														<span className="me-1">
															<img src="images/doc_icon.svg" alt="" />
														</span>
														<input
															type="file"
															name="fileUpload"
															id="fileUpload"
															className="inputfile"
															data-multiple-caption="{count} files selected"
															multiple
															required
															onChange={(e) => handleImageUpload(e.target.files)}
														/>
														<span className="fileclose">
															<span className="icon-cross"></span>
														</span>
													</div>
												</div>
											</div>
										</div>

										<div className="col-md-12">
											<div className="row g-2 justify-content-center align-items-center">
												<div className="col-auto"><button type="button" className="btn btn-green height-45 px-5" onClick={handlePublish}
													disabled={!isMessageFilled} ><span>Publish</span></button></div>
											</div>
										</div>
									</div>
								</form>
							</div>
							<div className='col-md-6'>
								<div className="post-preview">
									<h2>Post Preview</h2>
									<div className="post-preview-data">
										<div className={`card ${networkType}`} id={`${networkType}-tab`}>
											<div className='card-header d-flex flex-wrap align-items-center p-3'>
												<span><img className="me-2 rounded" src={`${process.env.REACT_APP_IMAGE_BASEPATH}/${networkType}.svg`} width="30" alt="" /></span>
												<h5 className="card-title m-0">{networkTypeMap?.[networkType]}</h5>
											</div>
											<div id="post_content" className="card-body">
												<p className="card-text" dangerouslySetInnerHTML={{ __html: postText }} />
												<div className='post-img text-center'>
													<img alt="" src={image} className="img-fluid mx-auto" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default SocialPost;