import { useContext, useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../../library/Core/GlobalContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import smartReducer from '../../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { addUserSpecificDomains } from '../../../../library/Service/domainService';
import { handleCompleteLater, handleNext, onCurrentJobSelectionChange } from './TeachingJobActions';
import TeachingJobLayout from './TeachingJobLayout';

const TeachingJob = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const [redirectUrl, setRedirectUrl] = useState('');

    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            NEXT: handleNext,
            I_LL_DO_THIS_LATER: handleCompleteLater,
            ROUTER_NAVIGATE: navigate,
            isCurrent: onCurrentJobSelectionChange,
            REDIRECT: setRedirectUrl,
        },
    } as any);

    const { formConfig, data, routeInfo } = useLoaderData() as State;

    useEffect(() => {
        const domain = addUserSpecificDomains(new Map([...globalState.domain]), data);

        const seniorityLevel = [
            {
                categoryCode: 'JOB_SENIORITY_LEVEL', // replace with your actual categoryCode
                code: 'Research Assistant', // replace with desired year
                value: 'Research Assistant', // replace with desired value
                parentCode: '',
                displayOrder: 1, // replace with the appropriate order
            },
            {
                categoryCode: 'JOB_SENIORITY_LEVEL',
                code: 'Faculty Member',
                value: 'Faculty Member',
                parentCode: '',
                displayOrder: 2,
            },
            {
                categoryCode: 'JOB_SENIORITY_LEVEL',
                code: 'Head of Department',
                value: 'Head of Department',
                parentCode: '',
                displayOrder: 3,
            },
            {
                categoryCode: 'JOB_SENIORITY_LEVEL',
                code: 'Head of Institution',
                value: 'Head of Institution',
                parentCode: '',
                displayOrder: 4,
            },
        ];

        console.log(domain);
        domain.set('JOB_SENIORITY_LEVEL', seniorityLevel);
        data['teachingWork'] = data['teachingWork'] ?? {};

        const jobRoleCodeDomain = domain.get('JOB_ROLE_CODE');
        if (jobRoleCodeDomain) {
            const filteredJobRoleCode = jobRoleCodeDomain.filter((role) => role.code !== 'FREELANCER');
            domain.set('JOB_ROLE_CODE', filteredJobRoleCode);
        }

        const typeOfInstituteCodeDomain = domain.get('INSTITUTE_TYPE_CODE');
        if (typeOfInstituteCodeDomain) {
            const filteredInstituteCode = typeOfInstituteCodeDomain.filter((role) =>  
                role.code !== 'UNIVERSITY' && role.code !== 'UPSKILLING_INSTITUTE' && role.code !== 'JOB_CONSULTANCY'
            );
            domain.set('INSTITUTE_TYPE_CODE', filteredInstituteCode);
        }

        dispatch({
            type: 'DATA_INIT',
            payload: { formConfig, data, domain, routeInfo },
        });
    }, []);

    useEffect(() => {
        if (redirectUrl !== '') {
            navigate(redirectUrl);
        }
    }, [redirectUrl]);

    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            <TeachingJobLayout>{!state.flags.isDataLoading && <PageBuilder />}</TeachingJobLayout>
        </SmartContext.Provider>
    );
};

export default TeachingJob;
