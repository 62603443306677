import { activateUserSession, isEmpty, isFormValid, setError } from '../../../../library/Core/SmartFunctions';
import { ControlOnChangeArguments, DispatchEvent, State } from '../../../../library/Core/SmartTypes';
import { axiosClient } from '../../../../library/Service/axiosClient';
import ScreenNavigationService, { AlumniProfileEditScreens } from '../../../../services/screen-navigation.service';
// import { toStudentEntity } from '../../../../services/student-mapper.service';
import StudentService from '../../../../services/student.service';
import { navigateToNextStep } from '../../services/profile-next-step-navigator';

interface DomainItem {
    categoryCode: string;
    value: string;
    code: string;
}

interface Domain {
    [key: string]: DomainItem[];
}

export const handleNext = (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void,
    sessionState: any,
    sessionDispatch: (dispatchEvent: DispatchEvent) => void
) => {
    event.preventDefault();

    const inValidDate = isValidDate(state, dispatch);
    const isImpersonatedSession = sessionStorage.getItem('is-impersonated-session') === 'YES';
    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);
    if (!isImpersonatedSession && (!customValidationPassed || !inValidDate || !isFormValid(state, dispatch))) {
        dispatch({ type: 'SHOW_ERRORS' });
        return;
    }

    // Wrap professional practice in an array if it's not already
    let professionalPractice = state?.data['professionalPractice'];
    if (professionalPractice && typeof professionalPractice === 'object' && !Array.isArray(professionalPractice)) {
        professionalPractice = [professionalPractice];
    } else if (!Array.isArray(professionalPractice)) {
        professionalPractice = [];
    }

    // Get Industry and Functional Area Data with proper checks for array structure
    const IndustryData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => {
            return value.some((item: any) => item.categoryCode === 'INDUSTRY_CODE');
        })
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'INDUSTRY_CODE') {
                    console.log('Item during reduce:', item); // Log each item that matches 'INDUSTRY_CODE'
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {}) as any;

    const functionalData = Array.from(globalState?.domain || [])
        .filter(([key, value]: any) => {
            return value.some((item: any) => item.categoryCode === 'FUNCTIONAL_AREA_CODE');
        })
        .reduce((result: any, [key, value]: any) => {
            value.forEach((item: any) => {
                if (item.categoryCode === 'FUNCTIONAL_AREA_CODE') {
                    console.log('Item during reduce:', item);
                    result[item.value] = item.code;
                }
            });
            return result;
        }, {}) as any;

    // Mappers to convert Practice Types to corresponding Industry/Functional Area
    const industryMapperWithPractice = {
        DOCTOR: 'Healthcare and Life Sciences',
        DENTIST: 'Healthcare and Life Sciences',
        VETERINARIAN: 'Healthcare and Life Sciences',
        PSYSIOTHERAPY: 'Healthcare and Life Sciences',
        CA: 'Accounting, Auditing and Financial Services',
        LEGAL: 'Law and Legal Consulting',
        ARCHITECT: 'Infrastructure and Construction',
        INSURANCE_AGENT: 'BFSI',
        REAL_ESTATE: 'Real Estate',
    };

    const functionalAreaMapperWithPractice = {
        DOCTOR: 'Diagnosis and Treatment',
        DENTIST: 'Diagnosis and Treatment',
        VETERINARIAN: 'Diagnosis and Treatment',
        PSYSIOTHERAPY: 'Diagnosis and Treatment',
        CA: 'Finance and Accounts',
        LEGAL: 'Legal',
        ARCHITECT: 'Engineering and Design',
        INSURANCE_AGENT: 'Sales and BD',
        REAL_ESTATE: 'Sales and BD',
    };

    // Map professional practice to appropriate industry and functional area codes
    professionalPractice = professionalPractice.map((practice: any) => {
        let industryCode: string | null = null;
        let functionalAreaCode: string | null = null;

        if (practice?.typeOfPractice && practice.typeOfPractice !== 'OTHERS') {
            const typeOfPractice = practice?.typeOfPractice as keyof typeof industryMapperWithPractice;
            const mappedIndustry = industryMapperWithPractice[typeOfPractice];
            if (mappedIndustry && IndustryData) {
                industryCode = IndustryData[mappedIndustry] ?? null;
            }

            const mappedFunctionalArea = functionalAreaMapperWithPractice[typeOfPractice];
            if (mappedFunctionalArea && functionalData) {
                functionalAreaCode = functionalData[mappedFunctionalArea] ?? null;
            }
        } else {
            industryCode = practice?.industryCode ?? null;
            functionalAreaCode = practice?.functionalAreaCode ?? null;
        }

        return {
            ...practice,
            recordTypeCode: 'JOINED',
            jobCategory: 'PROFESSIONAL_PRACTICE',
            isOutcome: state?.data?.selectedInstitute?.postProgramCompletionStatusCode === 'PROFESSIONAL_PRACTICE',
            industryCode: industryCode,
            functionalAreaCode: functionalAreaCode,
        };
    });

    let jobAppliedRecords = state?.data?.jobsApplied ?? [];
    let teachingWork = state?.data['teachingWork'] ?? [];
    let otherJobsRecords = state?.data?.jobs ?? []; // save othere Jobs like work page/working for compnay category

    let defenceWork = state?.data?.defenceWork ?? []; 
    defenceWork = defenceWork.map((defence:any) => {
        const { designationRank, ...rest } = defence; // Destructure `designationRank` to designation
        return {...rest, designation: designationRank};
    })

    let jobHistory = [...professionalPractice, ...teachingWork, ...otherJobsRecords, ...jobAppliedRecords,...defenceWork];

    StudentService.pageSave(
        state.routeInfo.id,
        state.routeInfo.pageName,
        StudentService.toStudentEntity({
            id: state.routeInfo.id,
            jobs: jobHistory,
            profile: state?.data['profile'],
        })
    ).then((response) => {
        if (response?.status === 200) {
            navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch, sessionState, sessionDispatch);
        }
    });
};

const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessages = [] as any[];
    let isFormInvalid = false;
    const professionalPractice = state.data.professionalPractice;

    professionalPractice.forEach((professionalPractice: any, index: number) => {
        if (isEmpty(professionalPractice?.companyId) && isEmpty(professionalPractice?.otherCompany)) {
            isFormInvalid = true;
            setError(`professionalPractice.${index}.companyId`, [`Please enter "Name of the Company"`], dispatch);
        } else {
            setError(`professionalPractice.${index}.companyId`, [], dispatch);
        }
    });

    // Dispatch the validation errors to the state
    if (isFormInvalid) {
        dispatch({
            type: 'SET_FIELD_VALIDATION_ERRORS',
            payload: { dataKey: 'professionalPractice', errorMessages },
        });
    }
    return !isFormInvalid;
};

export const onCurrentJobSelectionChange = (props: ControlOnChangeArguments) => {
    const rowIndex = parseInt(props.parentDataKey.split('.').pop() as string);
    const jobs = props.state.data.professionalPractice.map((row: any, index: number) => {
        setError(`professionalPractice.${index}.endDate`, [], props.dispatch);
        return index == rowIndex ? { ...row, endYear: '', isCurrent: false } : { ...row, isCurrent: false };
    });
    props.dispatch({ type: 'SET_FIELD_VALIDATION_ERRORS', payload: { dataKey: `${props.parentDataKey}.jobStartAndEndDates`, value: [] } });

    props.dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey: 'professionalPractice', value: jobs } });
};

export const onStartDateChange = (props: ControlOnChangeArguments) => {
    alert(props.row.endDate);
    const errorMessages =
        new Date(props.value) > new Date(props.row.endDate) ? ['Start year should be less than or equal to End year'] : [];
    // props.dispatch({
    //     type: 'SET_FIELD_VALIDATION_ERRORS',
    //     payload: { dataKey: `${props.parentDataKey}.startDate`, errorMessages },
    // });

    props.errorMessages.push(errorMessages);
};

export const onEndDateChange = (props: ControlOnChangeArguments) => {
    const errorMessages =
        new Date(props.value) < new Date(props.row.startDate) ? ['End year should be greater than or equal to Start year'] : [];
    props.errorMessages.push(errorMessages);
};

export const handleCompleteLater = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    document.getElementsByClassName('modal').item(0)?.setAttribute('style', 'display:block');
    // state.actions['ROUTER_NAVIGATE']('/alumni/onboarding');
    //ScreenNavigationService.navigateToNextStep(AlumniProfileEditScreens.WORK_DETAILS, state, dispatch);
};

const isValidDate = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const professionalPracticeData = state?.data['professionalPractice'];
    let isFormValid = true;
    professionalPracticeData.forEach((professionalpractice: any, index: number) => {
        console.log(professionalpractice);

        if (isEmpty(professionalpractice?.startDate)) {
            setError(`professionalPractice.${index}.startDate`, [`Please enter "Start Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`professionalPractice.${index}.startDate`, [], dispatch);
        }

        if (
            (professionalpractice?.isCurrent === false || isEmpty(professionalpractice?.isCurrent)) &&
            isEmpty(professionalpractice?.endDate)
        ) {
            setError(`professionalPractice.${index}.endDate`, [`Please enter "End Date" `], dispatch);
            isFormValid = false;
        } else {
            setError(`professionalPractice.${index}.endDate`, [], dispatch);
        }

        if (new Date(professionalpractice?.startDate) >= new Date(professionalpractice?.endDate)) {
            console.log('Invalid date');
        }

        if (professionalpractice?.isCurrent === false || isEmpty(professionalpractice?.isCurrent)) {
            if (
                isEmpty(professionalpractice?.endDate) ||
                new Date(professionalpractice?.startDate) > new Date(professionalpractice?.endDate)
            ) {
                setError(`professionalPractice.${index}.endDate`, [`End year should be greater than or equal to Start year`], dispatch);
                isFormValid = false;
            } else {
                setError(`professionalPractice.${index}.endDate`, [], dispatch);
            }
        }
    });

    return isFormValid;
};
