import { useContext, useEffect, useMemo, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { validateFormField } from '../Core/FormFieldValidation';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, isEmpty, handleControlValueChange, getParentData } from '../Core/SmartFunctions';
import ErrorControl from './ErrorControl';
import { v4 as uuidv4 } from 'uuid';

const SelectEditableUUIDOnchange = (args) => {
    const { state, dispatch } = useContext(SmartContext);
    const { control, dataKey, parentDataKey } = { ...args };

    // Get initial data from the form state
    const data = getControlValueFromState(dataKey, state);
    const [selectedOption, setSelectedOption] = useState(
        data ? { value: data.value || data, label: data.label || data } : null
    );

    // Extract domain-related configurations
    const [domainCategoryCode] = control.props.domainCategoryCode.split('#');
    const manualEntryColumn = control?.props?.customProperties?.manualEntryColumn;
    const manualEntryData = getControlValueFromState(`${parentDataKey}.${manualEntryColumn}`, state);
    const parentData = control.props.parentId && getParentData(parentDataKey + '.' + control.props.parentId, state);

    const action = state?.actions?.[control.id];

    // Memoized domain options to avoid unnecessary recomputations
    const options = useMemo(() => {
        const domainData = state?.domain?.get(domainCategoryCode) || [];
        const filteredDomainData = isEmpty(parentData)
            ? domainData
            : domainData.filter((domain) => domain.parentCode === parentData);

        return filteredDomainData.map((domain) => ({
            value: domain.code,
            label: domain.value,
        }));
    }, [state.domain, domainCategoryCode, parentData, control]);

    // Update selectedOption when data or manual entry changes
    useEffect(() => {
        const option = options.find((opt) => opt.value === data?.value || opt.value === data);
        if (option) {
            setSelectedOption({ value: option.value, label: option.label });
        } else if (manualEntryData) {
            setSelectedOption({ value: manualEntryData, label: manualEntryData });
        } else {
            setSelectedOption(null);
        }
    }, [data, manualEntryData, options]);

    // Function to load options dynamically based on user input
    const loadOptions = (inputValue, callback) => {
        const latestOptions = state?.domain?.get(domainCategoryCode)?.map((domain) => ({
            value: domain.code,
            lowerLabel: domain.value?.toLowerCase() ?? '',
            label: domain.value,
        })) || [];

        if (inputValue.length < 3) {
            callback([]); // Require at least 3 characters for search
        } else {
            callback(latestOptions.filter((option) => option.lowerLabel.includes(inputValue.toLowerCase())));
        }
    };

    // Handle option selection and dispatch the value to state
    const processOptionValue = (optionValue, isManual = false) => {
        const manualEntryKey = `${dataKey}_manual`;
        const selectedOption = options.find((opt) => opt.value?.toString() === optionValue?.toString());

        console.log(selectedOption, 'first selectedOption')

        let valueToSet;

        if (selectedOption && !isManual) {
            // Use dropdown selection
            valueToSet = { value: selectedOption.value, label: selectedOption.label };

            // Update the main field
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey, name: control.id, value: valueToSet, errorMessages: [] },
            });

            // Clear manual entry
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: manualEntryKey, name: control.id, value: null, errorMessages: [] },
            });
        } else if (isManual && optionValue) {
            // Handle manual entry
            valueToSet = { uuid: uuidv4(), value: optionValue, label: optionValue };

            // Update manual entry field
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: manualEntryKey, name: control.id, value: valueToSet, errorMessages: [] },
            });

            // Clear the main field
            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey, name: control.id, value: null, errorMessages: [] },
            });
        } else {
            // Clear all if no value
            //valueToSet = null;

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey, name: control.id, value: null, errorMessages: [] },
            });

            dispatch({
                type: 'CONTROL_VALUE_CHANGE',
                payload: { dataKey: manualEntryKey, name: control.id, value: null, errorMessages: [] },
            });
        }
console.log(valueToSet, 'valueToSet')
        // Update the selected option state
        setSelectedOption(valueToSet);
    };

    // Handle change in selected value
    const handleChange = (option) => {
        if (option && option.value) {
            processOptionValue(option.value); // Process dropdown selection
        } else {
            processOptionValue(option ? option : null, true); // Process manual entry or clear
        }

        if (action) {
            action(option); // Call the action if defined
        } else {
            console.warn(`No action defined for control.id: ${control.id}`);
        }
    };

    // Handle input change for search functionality
    const handleInputChange = (inputValue) => {
        if (inputValue.length >= 3) {
            processOptionValue(inputValue, true); // Manual input
        }
    };

    return (
        <>
            {control.props?.label && (
                <label htmlFor={control.id} className="form-label">
                    {`${control.props.label} `}
                </label>
            )}
            <div className="input-group">
                <div className="input-group-text bg-white rounded-start">
                    <i className="bi bi-search fs-6"></i>
                </div>
                <div className="flex-fill form-control border-start-0 p-0">
                    <AsyncSelect
                        loadOptions={loadOptions}
                        value={selectedOption} // Ensure `selectedOption` is passed correctly
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        isClearable
                        isSearchable
                        cacheOptions
                        noOptionsMessage={({ inputValue }) =>
                            inputValue.length < 3 ? 'Enter minimum three characters' : 'No options'
                        }
                    />
                </div>
            </div>
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
        </>
    );
};

export default SelectEditableUUIDOnchange;