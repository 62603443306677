import { AxiosResponse } from 'axios';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import pageConfig from './PageConfignew.json';
import PageConfignew_institute_admin from './PageConfignew_institute_admin.json';
import { format, isValid } from 'date-fns';

class AddnewService {
    
    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, pageName } = params;
        
        return Promise.all([this.getPageConfig(), AddnewService.get(eventId)])
            .then((values) => {
                state.formConfig = values[0];
                state.data= values[1];
                state.routeInfo = { pageName };
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};
        const userTypeCode = localUserData?.userTypeCode;
        if (userTypeCode === 'INSTITUTE_ADMIN') {
            return Promise.resolve(PageConfignew_institute_admin);
        }

        return Promise.resolve(pageConfig);
    }

    static async get(eventId: any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        let BasicInfo = { };

        if (eventId) {
            // BasicInfo = (await axiosClient().get(`${process.env.REACT_APP_LEAD_SERVER}/marketevent/geteventinfo/${eventId}`))?.data;            
            BasicInfo = (await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/basic-info/${eventId}`))?.data;            
            BasicInfo = AddnewService.toEventto(BasicInfo);
        }

        return Promise.resolve(BasicInfo);
    }

    static toEventto(eventinfo: any) {
        const dateFromDate = new Date(eventinfo.from);
        const dateToDate = eventinfo.to ? new Date(eventinfo.to) : '';
        // Format the date and time as required by MySQL (YYYY-MM-DD HH:MM:SS)

        const formattedFromDateTime = format(dateFromDate, 'yyyy-MM-dd HH:mm:ss');
        const formattedToDateTime = dateToDate && isValid(dateToDate) ? format(dateToDate, 'yyyy-MM-dd HH:mm:ss') : '';

        eventinfo.activeEvent = eventinfo.status == 'inactive' ? 0 : 1;
        eventinfo.dateFrom = formattedFromDateTime;
        eventinfo.dateTo = formattedToDateTime;
        eventinfo.registrationsActive = eventinfo.regStatus == 'yes' ? 1 : 0;
        eventinfo.clientsOnly = eventinfo.clientsOnly == 'yes' ? true : false;
        eventinfo.showInSuperCounsellor = eventinfo.showInSuperCounsellor == 'yes' ? true : false;
        // eventinfo.registrationLink = eventinfo.regLink;
        eventinfo.eventType = String(eventinfo.eventType);
        eventinfo.instituteType = eventinfo.instituteType.map((item: any) => {
            return {instituteType: item}
        });

        return eventinfo;
    }

    static async save(id: string, type: string, data: any): Promise<any> {
        let result;
        //result = await axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
       
        return Promise.resolve(result);
    }

    static toStudentEntity(student: any) {
        return { ...student };
    }

    static toStudentAdditionalInfoDto(studentAdditionalInfo: any) {
        return studentAdditionalInfo;
    }

    static async pageSave(id: string, pageName: string, data: any): Promise<AxiosResponse<any, any>> {
        // TODO: Refactor this to depend on teh nodes rather than the pages
        const pagesBasedOnStudentProfile = [
            'alumni-basic-info',
            'alumni-profile-picture',
            'alumni-undergraduate',
            'alumni-scholarship',
            'alumni-entrance-test',
            'alumni-postgraduate',
            'alumni-professional-work',
            'alumni-benefits',
            'alumni-mentoring-interests',
            'student-basic-info',
            'student-education',
            'student-entrance-exams',
            'admin-basic-info',
            'admin-additional-info',
            'admin-preferences',
        ];

        const url = pagesBasedOnStudentProfile.includes(pageName)
            ? `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/profile`
            : `${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`;

        if (['alumni-benefits', 'alumni-mentoring-interests'].includes(pageName)) {
            axiosClient().put(`${process.env.REACT_APP_STUDENT_SERVER}/student/${id}/additional-info`, data);
        }

        return axiosClient().put(url, data);
    }

    static async SaveProfileImagePath(state: any): Promise<AxiosResponse<any, any>> {
        const imagePath = state.data.profile.profilePictureFilePath;
        const id = state.routeInfo.id;
        const data = { id: id, imagePath: imagePath };
        const url = `${process.env.REACT_APP_COMMON_API}/users/update-profile-image-path`;
        return axiosClient().post(`${url}`, data);
    }

    static getStudentPassOutYear = (student: any) => student.profile.passOutYear ?? 2000;
}
export default AddnewService;
