import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode, getFlexColDomainValueForCode, isEmpty, toSentenceCase } from '../../../library/Core/SmartFunctions';
import NoEventsFound from './NoEventsFound';
import useEvents from './useEvents';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { State } from '../../../library/Core/SmartTypes';
import { Modal } from 'bootstrap';
import ListPopup from '../../../library/SimpleControls/ListPopup';
const EventsGridControl = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { performSearch } = useEvents();
    const events = state?.data?.events;
    const pageSize = 10;

    const [isVideoModalOpen, setVideoModalOpen] = useState(false);
    const [player, setPlayer] = useState<any>(null);
    const [videoLink, setVideoLink] = useState('');
    const playerRef = useRef<any>(null);
    // const modalRef = useRef<HTMLElement | null>(null); // Create a ref for the modal element

    function extractVideoId(url: string) {
        if (url === undefined) return '';
        const videoIdMatch = url.match(/(?:youtu.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))([\w-]{11})/);
        if (videoIdMatch) {
            return videoIdMatch[1];
        }
        return '';
    }

    useEffect(() => {
        if (window.YT && typeof window.YT.Player !== 'undefined' && videoLink) {
            const videoId = extractVideoId(videoLink);
            playerRef.current = new window.YT.Player('youtube-player', {
                videoId: videoId,
                playerVars: {
                    origin: window.location.origin,
                },
                events: {
                    onReady: onPlayerReady,
                },
            });
        }
    }, [videoLink]);

    const onPlayerReady = (event: any) => {
        setPlayer(event.target);
        if (isVideoModalOpen) {
            event.target.playVideo();
        }
    };

    const openVideoModal = (link: string) => {
        setVideoLink(link);
        window.scrollTo(0, 0);
        setVideoModalOpen(true);
    };

    const closeVideoModal = () => {
        setVideoModalOpen(false);
        setVideoLink(''); //@note Same video is not playing again once closed the player;

        if (playerRef.current && playerRef.current.destroy) {
            playerRef.current.destroy();
            playerRef.current = null;
            setPlayer(null);
        }
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    // useEffect(() => {
    //     // Initialize Owl Carousel once the component has mounted
    //     $('#cardsliderCarousel').owlCarousel({
    //       items: 3, // Number of items to show at once
    //       loop: true, // Enable looping
    //       margin: 10, // Margin between items
    //       nav: true, // Show navigation buttons
    //       navText: ['‹', '›'], // Text for navigation buttons
    //       dots: false, // Disable dots navigation
    //       autoplay: true, // Enable autoplay
    //       autoplayTimeout: 2000, // Set autoplay timeout
    //       autoplayHoverPause: true, // Pause autoplay on hover
    //     });
    //   }, []);
    

    const [numEventsDisplayed, setNumEventsDisplayed] = useState(pageSize);
    const hasMore = numEventsDisplayed < events.length;

    const fetchMoreData = () => {
        if (numEventsDisplayed >= events.length) return;
        setNumEventsDisplayed(numEventsDisplayed + pageSize); // Display 6 more events each time the user scrolls down
    };

    // useEffect(() => {
    //     performSearch('TAB_CHANGE');
    // }, [state?.data?.searchBy]);

    useEffect(() => {
        performSearch('FILTER_CHANGE');
    }, [state?.data?.filters]);

    if (events.length === 0) return <NoEventsFound />;
    
    // OwlCarousel options
    const options = {
        items: 1,
        merge: true,
        loop: false,
        video: true,
        lazyLoad: true,
        nav: true,
        // navText : ["Prev","Next"],
        dots: false,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:false
    };

    const handleViewList = (data: any) => {
        let popupData: any = [];
        let list: any = [];
        data.forEach((universityId: any) => {
            const univFlexData = getFlexColDomainValueForCode(universityId, 'INSTITUTE_DOMAIN', state);
            const univFlexObj = JSON.parse(univFlexData);

            list.push({
                logo: univFlexObj.logo,
                name: getDomainValueForCode(universityId, 'INSTITUTE_DOMAIN', state)
            });
        });

        popupData.data = list;
        popupData.title = "List of Universities";

        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'listPopup', value: popupData } });
        dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: true } });

        // handlePopup('univ_list', state);
    }
    
    // const handlePopup = async (popupId: string, state: State) => {
    //     try {
    //         // let modalKey = 'isListModalOpen';
    
    //         // // Check if modal is already open
    //         // if (!state.internal.isListModalOpen) {
    //         //     dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: true } });
    //         // } else {
    //         //     dispatch({ type: 'SET_INTERNAL_STATE', payload: { key: 'isListModalOpen', value: false } });
    //         // }

            
    //         const modalElement = document.getElementById(popupId);
    //         if (modalElement) {
    //             if (!state.internal.isListModalOpen) {
    //             // if (!state.internal[modalKey]) {
    //                 const modal = new Modal(modalElement);
    //                 modal.show();
    //             } else {
    //                 const modal = Modal.getInstance(modalElement);
    //                 if (modal) {
    //                     modal.hide();
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error handling modal:', error);
    //     }
    // };

    // const handlePopup = (popupId: string, state: State) => {
    //     try {
    //         const modalElement = document.getElementById(popupId);
    //         if (modalElement) {
    //             const modal = Modal.getInstance(modalElement) || new Modal(modalElement);
    
    //             if (!state.internal.isListModalOpen) {
    //                 modal.show();
    //             } else {
    //                 modal.hide();
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error handling modal:', error);
    //     }
    // };
    

    // const handlePopup = (popupId: string, state: State) => {
    //     try {
    //         // const modalElement = document.getElementById(popupId);
    //         // if (modalElement) {
    //         //     const modal = Modal.getInstance(modalElement) || new Modal(modalElement, { backdrop: true });
    //         //     if (!state.internal.isListModalOpen) {
    //         //         modal.show();
    //         //     } else {
    //         //         modal.hide();
    //         //     }
    //         // }
    //     } catch (error) {
    //         console.error('Error handling modal:', error);
    //     }
    // };
    
    
    

    return (
        <div
            className="flex-1 polarcontsec tab-content mt-38"
            id="myTabContent"
            >
            <div className="tab-pane fade show active" id="UpcomingEvents" role="tabpanel" aria-labelledby="UpcomingEvents-tab">
                <h3 className="font-18 font-500 mb-3 d-none d-md-block">
                    <span id="ucount">{events.length}</span> Events Found
                </h3>
                <InfiniteScroll
                    dataLength={numEventsDisplayed} // length of data already rendered
                    next={fetchMoreData} // function to load more data
                    hasMore={hasMore} // boolean to control whether InfiniteScroll should call the 'next' function
                    loader={''}>
                    <div className="row EventsBlk" id="loadmore">
                        {events.slice(0, numEventsDisplayed).map((event: any) => (
                            <div key={event.id} className="col-lg-6 col-md-12 album_blk_parent pfusers" data-key={event.id}>
                                <div className="album_blk">
                                    <div className="album_imgblk position-relative">
                                        <span className="album_badge font-16 font-500 text-white d-inline-block position-absolute">
                                            {getDomainValueForCode(event.eventType, 'EVENT_TYPE_ALL', state)}
                                        </span>
                                        <img src={event.eventImage} alt=""  />
                                    </div>
                                    <div className="album_contblk eventsbox">
                                        <div className="d-flex flex-wrap justify-content-between align-items-start">
                                            <div className="flex-1">
                                                <h3 className="font-20 font-500 mb-2 title">{toSentenceCase(event.eventTitle)}</h3>
                                            </div>
                                        </div>
                                        <p className="d-flex align-items-center justify-content-start mContCenter mb-0">
                                            <span className="icon_bluecir me-2">
                                                <span className="icon-calender-time font-18 text-dark-blue icon"></span>
                                            </span>
                                            <span className="font-28 font-600 text-dark-blue me-2">
                                                {new Date(event.from).getDate()}
                                            </span>
                                            <span className="font-16 font-500 text-dark-blue">
                                                {moment(event.from).format('MMM YYYY, h:mm A')}
                                            </span>
                                        </p>

                                        {! [2,6].includes(parseInt(event?.eventType)) && event?.speakerInfo?.length > 0 && (
                                            <OwlCarousel className="owl-carousel owl-theme cardslider max-360 my-2" id='cardsliderCarousel' {...options}>
                                            {event?.speakerInfo?.map((speaker: any, idx: any) => (
                                                    <div className="item" key={`speaker-${event.id}-${idx}`}>
                                                    <div className="row g-2">
                                                        <div className="col-auto">
                                                            <span className="icon_bluecir">
                                                                {!isEmpty(speaker?.picture) && (
                                                                    <img src={speaker?.picture} className='img-fluid w-auto'/>
                                                                )}
                                                                {isEmpty(speaker?.picture) && (
                                                                    <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/speech_icon.jpg`} className='img-fluid w-auto'/>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className="col">
                                                            <div className="row g-2">
                                                                <div className="col">
                                                                    <span className=" fst-italic text-dark-blue">
                                                                        <b className="font-16 font-500 fst-normal d-block line-height-16">{toSentenceCase(speaker.name)},</b>
                                                                        <span className="font-14 d-block line-height-16 font-400">
                                                                            {speaker.designation}, {!isEmpty(speaker.organization) ? getDomainValueForCode(speaker.organization, 'INSTITUTE_DOMAIN', state) : speaker?.organizationManual}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            </OwlCarousel>
                                        )}
                                        
                                        {[2,6].includes(event?.eventType) && event?.relatedInstitute?.length > 0 && (
                                            <div className="row g-2 my-2 align-items-center">
                                                <div className="col-auto"><span className="icon_bluecir"><img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/speech_icon.jpg`}/></span></div>
                                                <div className="col">
                                                    <div className="row g-2 align-items-center">
                                                        <div className="col">
                                                            <span className=" fst-italic text-dark-blue">
                                                                <b className="font-16 font-500 fst-normal d-block line-height-16">{event?.relatedInstitute?.length} Universities</b> 
                                                            </span>
                                                        </div>
                                                        <div className="col-auto">
                                                            <a 
                                                                href="javascript:;" data-bs-toggle="modal" data-bs-target="#universities_list" 
                                                                className="btn btn-border-gray height-28 line-height-26 d-inline-flex font-500 font-14 text-gray-41 text-decoration-none justify-content-center"
                                                                onClick={() => {handleViewList(event?.relatedInstitute)}}
                                                            >
                                                                <span>View List</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {!isEmpty(event?.videoLink?.trim()) && (
                                            <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                                <a
                                                    className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                    role="button"
                                                    onClick={() => openVideoModal(event.videoLink)}>
                                                    <span>Watch</span>
                                                </a>
                                                <br />
                                            </p>
                                        )}

                                        {isEmpty(event?.videoLink?.trim()) && event?.eventUpcoming == '0'  && (
                                            <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                                <a
                                                    className="btn btn-green disable height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                    style={{ cursor: 'not-allowed' }}
                                                    data-bs-toggle="tooltip"
                                                    title="Coming soon..."
                                                    role="button">
                                                    <span>Watch</span>
                                                </a>
                                                <br />
                                            </p>
                                        )}

                                        {event?.eventUpcoming == '1' && event?.regStatus == 'yes' && ! isEmpty(event?.regLink) && (
                                            <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                                <a
                                                    className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                    role="button"
                                                    onClick={() => window.open(event?.regLink, '_blank', 'noopener,noreferrer')}>
                                                    <span>Register</span>
                                                </a>
                                                {event?.remainingDays > 0 && (
                                                    <span className="text-danger">{event?.remainingDays} Days left!</span>
                                                )}
                                                
                                                <br />
                                            </p>
                                        )}
                                        
                                        {event?.isPublished == 'no' && (
                                            <p className="d-flex align-items-center justify-content-start mContCenter flex-wrap">
                                                <a
                                                    className="btn btn-green text-white height-40 d-inline-flex font-500 font-16 text-decoration-none justify-content-center me-3 max-170"
                                                    role="button"
                                                    onClick={() => window.location.href = `${process.env.REACT_APP_APP_BASE_URL}/event/add/${event.id}`}>
                                                    <span>Edit</span>
                                                </a>
                                                <br />
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div
                            id="video_pop"
                            className={`modal fade vh-100 ${isVideoModalOpen ? 'show' : ''}`}
                            tabIndex={-1}
                            role="dialog"
                            style={{ display: isVideoModalOpen ? 'block' : 'none' }}>
                            <div className="modal-dialog modal-lg " role="document">
                                <div className="modal-content">
                                    <div className="modal-body p-0">
                                        <button type="button" className="btn-close" onClick={closeVideoModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        <div className="ratio ratio-16x9">
                                            <div id="youtube-player"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </InfiniteScroll>
            </div>

            <ListPopup id='universities_list' />
        </div>
    );
};

export default EventsGridControl;
