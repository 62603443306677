import { DispatchEvent, State } from '../../../library/Core/SmartTypes';

import { axiosClient } from '../../../library/Service/axiosClient';
import { isEmpty, setError } from '../../../library/Core/SmartFunctions';
import Swal from 'sweetalert2';

export const handleSave = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
    state: State,
    dispatch: (dispatchEvent: DispatchEvent) => void,
    globalState: any,
    globalDispatch: (dispatchEvent: DispatchEvent) => void) => {
    event.preventDefault();
    console.log(" Submitting the form...!")
    // if (!isFormValid(state, dispatch)) {
    //     return;
    // }

    const customValidationPassed = isFormValidBasedOnCustomValidation(state, dispatch);

    if ((!customValidationPassed)) {
        dispatch({ type: 'SHOW_ERRORS' });
        console.log(state.formValidationErrors);
        return;
    }
    dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

    let url = `${process.env.REACT_APP_COMMON_API}/test/save-psychometric-bas-mapping-data`;
    let payload: any = [];

    state.data?.classes?.map((classwiseData: any, idx: number) => {
        if (isEmpty(classwiseData) || classwiseData?.programLevel <= 0 || classwiseData?.testIds?.length <= 0) {
            return false;
        }
        classwiseData?.testIds?.map((testData: any) => {
            payload.push({
                psy_ses_ref_id: testData?.testIds,
                class_code: classwiseData.programLevel,
            })
        })
    })

    console.log(payload, " P A Y L O A D ");
    console.log(url, " U R L")
    axiosClient()
        .post(`${url}`, {
            institute_id: state.data.mappingData?.instituteId,
            data: payload
        })
        .then((res: any) => {
            const responseData = res.data as any;

            if (responseData.success === true) {
                Swal.fire({
                    title: "Done!",
                    text: "Mapping data saved successfully..!",
                    icon: "success"
                });
            } else {
                Swal.fire({
                    title: "Oops!",
                    text: `${responseData.message}`,
                    icon: "warning"
                });
            }

            dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [responseData] });
        })
        .catch((err: any) => {
            if (err.response) {
                console.log(err.response, " E R R ")
            } else {
                // Handle other errors
                console.error(err);
                alert('Oops..! Something went wrong, please try again in a while..!!');
            }
        });
};


const isFormValidBasedOnCustomValidation = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {
    const errorMessagesMapping = [] as any[];
    const errorMessagesClasses = [] as any[];
    const mappingData = state?.data?.mappingData;
    const classes = state?.data?.classes;
    const code = state?.internal?.widget_code;
    let isFormInvalid = false;

    console.log(mappingData, " mappingData ")
    if (isEmpty(mappingData?.instituteId) || mappingData?.instituteId == 0) {
        setError(`mappingData.instituteId`, [`Please select the institute`], dispatch);
        isFormInvalid = true;
    }

    console.log(isFormInvalid, " isFormInvalid ")
    // Dispatch the validation errors to the state
    dispatch({
        type: 'SET_FIELD_VALIDATION_ERRORS',
        payload: { dataKey: 'mappingData', errorMessagesMapping },
    });

    return !isFormInvalid;
};