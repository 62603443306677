import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import FormBuilderChild from '../Builders/FormBuilderChild';
import { SmartContext } from '../Core/SmartContext';
import { getControlValueFromState, getDataStructureFromControls, getUUIDs, isEmpty } from '../Core/SmartFunctions';
import { SimpleFormControlArguments, State } from '../Core/SmartTypes';
import ErrorControl from './ErrorControl';
import { v4 as uuidv4 } from 'uuid';

interface ErrorObject {
    [key: string]: string[];
}

const SmartFormGrid = ({ control, dataKey }: SimpleFormControlArguments) => {
    const { state, dispatch } = useContext(SmartContext);
    const data = useMemo(() => getControlValueFromState(dataKey as string, state as State), [dataKey, state]);
    const [uuids] = useState(() => getUUIDs(20));
    const [currentCount, setCurrentCount]: any = useState(data?.length > 0 ? data?.length : 1);

    const customProperties = useMemo(() => isEmpty(control.props.customProperties) ? { canAdd: true, canDelete: true, limit: Infinity, minDisplayRows: 1, minRequiredRows: 0 } : control.props.customProperties, [control.props.customProperties]);

    const addEmptyRecord = useCallback(() => {
        const emptyRow = { ...getDataStructureFromControls(control.controlGroup), id: uuidv4() };
        // setCurrentCount((prevCount: any) => prevCount + 1); // Use functional state update to avoid stale closure
        setCurrentCount((prevCount: any) => data.length + 1); // Use functional state update to avoid stale closure
        dispatch({ type: 'ADD_NEW_RECORD_IN_ARRAY', payload: { dataKey: dataKey, value: emptyRow } });
    }, [control.controlGroup, dataKey, dispatch, currentCount]);

    useEffect(() => {
        let minDisplayRows = customProperties?.minDisplayRows;
        if ( minDisplayRows < customProperties?.minRequiredRows ) {
            minDisplayRows = customProperties?.minRequiredRows;
        }

        // for (let i = data.length; i <= 2; i++) {
        for (let i = data?.length; i < minDisplayRows; i++) {
            addEmptyRecord();
        }
    }, []);

    useEffect(() => {
        if (data.length === 0) {
            addEmptyRecord();
        }
    }, [data, addEmptyRecord]);

    const handleAdd = useCallback((event: React.SyntheticEvent) => {
        event.preventDefault();
        addEmptyRecord();
    }, [addEmptyRecord]);

    const handleDelete = useCallback((dataKey: string, index: number) => {
        dispatch({ type: 'CONTROL_VALUE_CHANGE', payload: { dataKey, value: [...data.slice(0, index), ...data.slice(index + 1)] } });
        setCurrentCount((prevCount: any) => prevCount - 1); // Use functional state update to avoid stale closure
        const newErrors = reIndexErrors(state?.formValidationErrors, dataKey, index);
        dispatch({ type: 'SET_ALL_FIELD_VALIDATION_ERRORS', payload: newErrors });
    }, [data, dispatch, state?.formValidationErrors]);

    const reIndexErrors = useCallback((errors: ErrorObject, baseDataKey: string, indexToRemove: number): ErrorObject => {
        const newErrors: ErrorObject = {};

        for (let key in errors) {
            const [currentBaseDataKey, currentIndexStr, attribute] = key.split('.');
            const currentIndex = parseInt(currentIndexStr);

            if (currentBaseDataKey === baseDataKey) {
                if (currentIndex < indexToRemove) {
                    newErrors[key] = errors[key];
                } else if (currentIndex > indexToRemove) {
                    const newKey = `${baseDataKey}.${currentIndex - 1}.${attribute}`;
                    newErrors[newKey] = errors[key];
                }
                // keys equal to indexToRemove are omitted
            } else {
                newErrors[key] = errors[key]; // Copy over all other keys as-is
            }
        }

        return newErrors;
    }, []);

    if (!data || data.length === 0) return null; // Early return if no data

    return (
        <>
            {control.props.label && (
                <label htmlFor={control.id} className="form-label w-100">
                    {`${control.props.label} ${control.props.required ? '*' : ''}`}
                </label>
            )}
            {data.map((row: any, index: number) => (
                <div key={`${control.id}-${row.id}`} className="form-grid-section">
                    {/* {customProperties.canDelete && data.length > 1 && ( */}
                    {customProperties.canDelete && currentCount > customProperties?.minRequiredRows && (
                        <div className="delete-sticky">
                            <a
                                className="btn-delete"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleDelete(dataKey as string, index);
                                }}></a>
                        </div>
                    )}
                    <div className="row">
                        <FormBuilderChild controls={control.controlGroup} parentDataKey={`${dataKey}.${index}`} padding=" " />
                    </div>
                    <ErrorControl errorMessages={state?.formValidationErrors[`${dataKey}.${index}`]} />
                </div>
            ))}
            <ErrorControl errorMessages={state?.formValidationErrors[dataKey]} />
            {customProperties.canAdd && currentCount < customProperties.limit && (
                <div className="cta_add my-3">
                    <button type="button" className="btn btn-link btn-add" onClick={handleAdd}>
                        <i>
                            <img src={`${process.env.REACT_APP_IMAGE_BASEPATH}/add_plus.svg`} alt="" />
                        </i>{' '}
                        Add More
                    </button>
                </div>
            )}
        </>
    );
};

export default SmartFormGrid;
