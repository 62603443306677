import { useContext, useEffect } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { useImmerReducer } from 'use-immer';
import PageBuilder from '../../../library/Builders/PageBuilder/PageBuilder';
import { GlobalContext } from '../../../library/Core/GlobalContext';
import { SmartContext } from '../../../library/Core/SmartContext';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import smartReducer from '../../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../../library/Core/SmartTypes';
import { axiosClient } from '../../../library/Service/axiosClient';
import Swal from 'sweetalert2';
//import { onMobileCountryCodeChange, onMobileNumberChange, twelthPassoutYear } from './CreateAlumniActions';
import Layout from './Layout';
import SessionContext from '../../../library/Core/SessionContext';


const ThemeOptions = () => {
    const { globalState } = useContext(GlobalContext);
    const navigate = useNavigate();
    const { sessionState } = useContext(SessionContext);
    const userType = sessionState?.userTypeCode;

    // const queryParams = new URLSearchParams(window.location.search);
    // const eventId = queryParams.get('id');
    const { eventId } = useParams();
    
    if(!eventId){
        navigate(`/event/add`);
    }
    const { routeInfo, formConfig, data } = useLoaderData() as State;
    
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {
            // SAVE: handleCreate,    
            SAVE: handleSaveAndExit,
            SAVE_AND_EXIT: handleSaveAndExit,
            SAVE_AND_PREVIEW: handleSaveAndPreview,        
        },
    } as any);

    useEffect(() => {
        console.log(state.formValidationErrors, 'formValidationErrors');
    }, [state.formValidationErrors]);
    
    async function handleSaveAndExit(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        
        try {
            const response: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);
        
            // setTimeout(() => {
                if (response?.id && response?.success == 1) {
                    
                    let navigateToPath = `${process.env.REACT_APP_APP_BASE_URL}/events/your-drafts`;
                    
                    if (userType == 'SUPER_ADMIN') {
                        navigateToPath = `${process.env.REACT_APP_APP_BASE_URL}/event/manageevent`;
                    }
                    window.location.href = navigateToPath;
                } else {
                    console.error("Invalid response:", response);
                    // Handle error case
                }
            // },500)
            // Check for the latest response data
            
        } catch (error) {
            console.error("Error while save and exit:", error);
        }
    }

    async function handleSaveAndPreview(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();

        try {
            const resp: any = await handleCreate(event, state, dispatch, globalState, globalDispatch);            // Check if the response is valid

            // setTimeout(() => {
                if ( ! isEmpty(resp?.id) && resp?.success == 1 ) {
                    navigate(`/event/preview/${resp?.id}`);
                }
            // }, 500);

        } catch (error) {
            console.error("Error while save & next:", error);
        }
    }

    async function handleCreate(
        event: React.SyntheticEvent<HTMLButtonElement>,
        state: State,
        dispatch: (dispatchEvent: DispatchEvent) => void,
        globalState: any,
        globalDispatch: (dispatchEvent: DispatchEvent) => void
    ) {
        event.preventDefault();
        let retData = {
            id: '',
            success: 0,
            message: `Oops..! Something went wrong, Please try again in a while..!!`
        }
        const isnotValideventtheme = iseventthemenotValid(state, dispatch);
        if (isnotValideventtheme ) {
            
            dispatch({ type: 'SHOW_ERRORS' });
            return retData;
        }
        
        dispatch({ type: 'SET_BUSINESS_VALIDATION_ERRORS', payload: [] });

        //let updateaudience = `${process.env.REACT_APP_LEAD_SERVER}/marketevent/updatethemeinfo`;

        const loader	= Swal.fire({
            title: 'Saving...',
            html: `Please wait while we're saving your data..!`,
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading(); // Show loading spinner
            },
        });

        // Prepare the API endpoint
        const updatetheme = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/theme-info`;
        
        await axiosClient()
            .post(`${updatetheme}`, {
                eventId : eventId ? eventId : '',
                eventtheme: arrayColumn(state.data?.updatetheme?.eventtheme ?? [], 'eventtheme'),
                departmentid: arrayColumn(state.data?.updatetheme?.departmentid ?? [], 'departmentid'),
                eventcountries: arrayColumn(state.data?.updatetheme?.eventcountries ?? [], 'eventcountries'),
                eventskills: arrayColumn(state.data?.updatetheme?.eventskills ?? [], 'eventskills'),
                eventtests: arrayColumn(state.data?.updatetheme?.eventtests ?? [], 'eventtests'),
                eventindustry: arrayColumn(state.data?.updatetheme?.eventindustry ?? [], 'eventindustry')
             })
                .then((res: any) => {
                retData.id = res.data.eventId;
                retData.message = res.data.message;
                retData.success = 1;

                if (res.data.success != '1') {
                    retData.message = retData.message ?? 'There is some issue in event management. Please try after sometime!';
                    retData.success = 0;
                }

                Swal.close();

                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    },
                });
                Toast.fire({
                    // icon: `${retData.success == 1 ? 'warning' : 'success'}`,
                    icon: `${retData.success == 1 ? 'success' : 'warning'}`,
                    title: `${retData.message}`,
                });
    });

    return retData;
}

    const iseventthemenotValid = (state: State, dispatch: (dispatchEvent: DispatchEvent) => void) => {

        if(userType == 'INSTITUTE_ADMIN'){
            return false;
        }

        const theme = state?.data?.updatetheme?.eventtheme?.map((item: any) => item.eventtheme) || [];
        let returnVal = false;
        
        // Validate based on the selected themes
        if (theme.includes('6')) { // Admission Abroad
            console.log('Please select at least one country other than India')
            const atLeastOneCountryEntered = state?.data?.updatetheme?.eventcountries?.some((eventcountries: any) => 
                !isEmpty(eventcountries.eventcountries) && eventcountries.eventcountries !== '100');
            if (!atLeastOneCountryEntered) {
                setError('updatetheme.eventcountries', ['Please select at least one country other than India'], dispatch);
                returnVal = true;
            }
        }
    
        if (theme.includes('9')) { // Skill Building
            console.log('Please select at least one skill tag')
            const atLeastOneSkillEntered = state?.data?.updatetheme?.eventskills?.some((eventskills: any) => 
                !isEmpty(eventskills.eventskills));
            if (!atLeastOneSkillEntered) {
                setError('updatetheme.eventskills', ['Please select at least one skill tag'], dispatch);
                returnVal = true;
            }
        }
    
        if (theme.includes('12')) { // Test Preparation
            console.log('Please select at least one test tag')
            const atLeastOneTestEntered = state?.data?.updatetheme?.eventtests?.some((eventtests: any) => 
                !isEmpty(eventtests.eventtests));
            if (!atLeastOneTestEntered) {
                setError('updatetheme.eventtests', ['Please select at least one test tag'], dispatch);
                returnVal = true;
            }
        }
    
        if (theme.includes('2') || theme.includes('4')) { // Career-Course Overview or University Course Overview
            console.log('Please select at least one department tag')
            const atLeastOneDepartmentEntered = state?.data?.updatetheme?.departmentid?.some((departmentid: any) => 
                !isEmpty(departmentid.departmentid));
            if (!atLeastOneDepartmentEntered) {
                setError('updatetheme.departmentid', ['Please select at least one department tag'], dispatch);
                returnVal = true;
            }
        }
    
        //Validate eventtheme field if data is in array format
        const atLeastOneEventThemeEntered = theme.length > 0;
        
        if (!atLeastOneEventThemeEntered) {
            setError('updatetheme.eventtheme', ['Please select at least one event theme'], dispatch);
            returnVal = true;
        }
    
        // If no validation errors, return false to indicate success
        return returnVal;
    };
    



    useEffect(() => {
        const domain = new Map([...globalState.domain]);
        dispatch({
            type: 'DATA_INIT',
            payload: {
                formConfig,
                data: { updatetheme: data },
                domain,
                routeInfo,
            },
        });
    }, []);



    return (
        <SmartContext.Provider value={{ state, dispatch }}>
            
            <Layout>{!state.flags.isDataLoading && <PageBuilder />}</Layout>
        </SmartContext.Provider>
    );
};

export default ThemeOptions;
