
import { useParams } from 'react-router-dom';
import { storeAppConfig } from '../../../library/Core/SessionStore';
import { convertDomainArrayToMap } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';

import { axiosClient } from '../../../library/Service/axiosClient';

import pageConfig from './PageConfigspeaker.json';
import PageConfigspeaker_institute_admin from './PageConfigspeaker_institute_admin.json';

class SpeakerInfoService {
    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, id,groupId, pageName, searchBy } = params;
        console.log(params, " params ")
         return Promise.all([
            this.getPageConfig(),
            SpeakerInfoService.get(eventId)
          ])
            .then((values) => {
                console.log(values)
                state.formConfig = values[0];
                //state.domain = values[1].data instanceof Map ? values[1].data : convertDomainArrayToMap(values[1].data);
                console.log(values);
                state.data = values[1];
                state.internal = { gridState: [] };
                state.routeInfo = { id, groupId,pageName };
                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                //logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }


    static async get(eventId: any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        
        let SpeakerInfo = [ ];
        // const queryParams = new URLSearchParams(window.location.search);
        // const eventId = queryParams.get('id');
        
        if (eventId) {
           // SpeakerInfo = (await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/speaker-info/${eventId}`))?.data; 

           try {
                const response = await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/speaker-info/${eventId}`);
                SpeakerInfo = response?.data;
            } catch (error) {
                console.error('Error fetching speaker info:', error);
                return Promise.reject(error);
            }

            const speakerdata = SpeakerInfo

            console.log(speakerdata, 'API speakerdata list')
            
            if (speakerdata){
                SpeakerInfo = SpeakerInfoService.toSpeakerto(SpeakerInfo);

            } else{

                SpeakerInfo = [ ];
            }
            
        }
        console.log(SpeakerInfo, 'SpeakerInfo data')

        return Promise.resolve(SpeakerInfo);
    }


    static toSpeakerto(speakerinfo: any) {
       
        const speakerdata = speakerinfo
        //Using map to create a new array with custom objects
        const speakerSummaries = speakerdata.map((speaker: any) => ({
            speakernameManual: speaker?.speakerManual?.name || '',
            speakername: speaker?.speakerInfo?.id || '',
            designation: speaker.designation,
            organizationManual: speaker?.organizationManual?.name || '',
            organization: speaker?.organization?.id || '',
            speakerbio: speaker.speakerBio,
            addVideoThumbnail: speaker.picture,
        }));
       

         return speakerSummaries;
    }

    static onSpeakernameChange() {
        
        console.log("Speaker Names Array:");
    }


    
    static getPageConfig() {
        const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};
        const userTypeCode = localUserData?.userTypeCode;
        if (userTypeCode === 'INSTITUTE_ADMIN') {
            return Promise.resolve(PageConfigspeaker_institute_admin);
        }
        return Promise.resolve(pageConfig);
    }
}
export default SpeakerInfoService;
