import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SessionContext from '../../../../library/Core/SessionContext';
import { SmartContext } from '../../../../library/Core/SmartContext';
import { getDomainValueForCode, getIcon, isSchoolAdmin } from '../../../../library/Core/SmartFunctions';
import AchievementSimilarAlumni from '../controls/AchievementSimilarAlumni';

const ProfileAchievements = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { sessionState } = useContext(SessionContext);
    let model = { ...state.internal.model };
    const navigate = useNavigate();

    const isPeerReviewed = sessionState?.isPeerReviewed ?? false;
    const isPersonate = sessionStorage.getItem('is-impersonated-session') == 'YES' ? true : false;
    const userType = sessionStorage.getItem('user-type-code');

    const [isActive, setIsActive] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const [actionBtnStatus, setActionBtnStatus]: any = useState({});

    const handleClick = (id: any) => {
      
        setIsActive(!isActive);
        setIsHidden(!isHidden);

        setActionBtnStatus({
            ...actionBtnStatus,
            [id]: ! actionBtnStatus[id]
        });
    };

    if (isPersonate != true && userType == 'ALUMNI' && isPeerReviewed != true) {
        window.location.href = `/alumni/welcome`;
        return;
    }

    model.achievements = model.achievements.map((achievement: any) => {
        let modifiedAchievement = { ...achievement };
        modifiedAchievement.typeCodeStr = getDomainValueForCode(achievement.typeCode, 'ACHIEVEMENT_CODE', state);
        let date = new Date(achievement.achievementDate);
        modifiedAchievement.year = date.getFullYear();
        modifiedAchievement.month = date.toLocaleString('default', { month: 'short' });

        return modifiedAchievement;
    });

    return (
        <div>
            <div className="achievements-list">
                <div className="achievements-block-title">
                    <div className="row g-2">
                        <div className="col">
                            {model.isEditable && <div className="title">My Accomplishments</div>}
                            {!model.isEditable && (
                                <div className="title">
                                    Accomplishments of{' '}
                                    <span>
                                        {`${model.firstName}`} {model.lastName ? `${model.lastName}` : ''}
                                    </span>
                                </div>
                            )}
                        </div>
                        {model.isEditable && !isSchoolAdmin() && (
                            <div className="col-auto">
                                <a role="button" onClick={() => navigate(`/alumni/${model.id}/achievements`)} className="btn-add-section">
                                    <i></i> <span>ADD</span>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                {model.achievements.map((achievement: any) => (
                    <div key={achievement.id} className="achievements-block">
                        <div className="achievements-icon-block">
                            {/* <div className="icon icon-medal"></div> */}
                            <div
                                className={`icon icon-${
                                    achievement.typeCodeStr == 'Award' ? 'medal' : getIcon(achievement.typeCodeStr, false)
                                }`}></div>
                            <div className="achievements-icon-title">
                                <div className="achievements-title">{achievement.typeCodeStr}</div>
                                <div className="achievements-year">
                                    {achievement.year} {achievement.month}
                                </div>
                            </div>
                        </div>
                        <div className="achievements-block-detail">
                            <div className="row g-3 align-items-center">
                                <div className="col-md">
                                    <div className="row g-3">
                                        <div className="col-md">
                                            <h3>{achievement.title}</h3>
                                        </div>
                                        <a href={achievement.weblink} target="_blank" rel="noopener noreferrer">
                                            {achievement.weblink}
                                        </a>
                                    </div>
                                </div>
                                {model.isEditable && !isSchoolAdmin() && (
                                    <div className="col-md-auto">
                                        <div className={`edit_section ${actionBtnStatus[achievement.id] ? 'open' : ''} ${isHidden ? 'hidden' : ''}`}>
                                        <button className="edit_section_toggle" onClick={() => {handleClick(achievement.id)}}></button>
                                            <div className="edit_section_inner">
                                                <a href={`/alumni/${model.id}/achievements`} className="edit_link">
                                                    Edit
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}

                <AchievementSimilarAlumni />
            </div>
        </div>
    );
};

export default ProfileAchievements;
