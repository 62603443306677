import { useContext } from 'react';
import { axiosClient } from "../../../library/Service/axiosClient";
import { SmartContext } from '../../../library/Core/SmartContext';


const UserListService = () => {

    // Get the all user based on the search filters
    async function allUsers(instituteValue) {
        console.log(instituteValue, 'instituteValue all users')
        try {
            const usersData = await axiosClient().post(
                `${process.env.REACT_APP_INTERNAL_ADMIN_SERVER}/search/all-users-list`                
            );

            console.log(usersData?.data, 'markersData data list')
    
            // Ensure markersData?.data is an array and transform if necessary
            return usersData?.data?.map((item) => ({
                code: item.uuid,
                value: item.name,
                category_code: "USERS_FROM_SAME_INSTITUTE",
                parentCode: item.instituteId,
                display_order: 1,
                flex_col: {designation:item.designation, profilePictureFilePath:item.profilePictureFilePath},
                is_active: 1,
            })) || [];
        } catch (error) {
            console.error('Error fetching all users:', error);
            return []; // Return an empty array in case of error
        }
    }

    return {
        allUsers
    };

};

export default UserListService;