import { Dropdown,Tooltip } from 'bootstrap';
import { Modal } from 'bootstrap';
import moment from 'moment';
import { format } from 'date-fns';
import { useContext, useEffect, useRef, useState } from 'react';
import useSmartState from '../../../common/hooks/useSmartState';
import { SmartContext } from '../../../library/Core/SmartContext';
import { getDomainValueForCode } from '../../../library/Core/SmartFunctions';
import { renderToString } from 'react-dom/server';
import { axiosClient } from '../../../library/Service/axiosClient';
import ActionSettings from '../../../library/SimpleControls/ActionSettings';
import ManageEventService from './ManageEventService';
//Popup list

import { REPORT_CONFIG } from '../../admin/reports/report-config';

const BATCH_SIZE = 10;
const DEFAULT_SORT_COLUMN = 'createdAt';
const DEFAULT_SORT_DIRECTION = 'desc';


const ManageEventGrid = () => {
    const { state, dispatch } = useContext(SmartContext);
    const { setStateValue } = useSmartState();
    const [sortedEvents, setSortedEvents] = useState<string[]>([]);
    const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
    const [bulkAction, setBulkAction] = useState<string>('' as any);
    const [visibleEvent, setVisibleEvent] = useState(state.data?.eventList?.rows?.slice(0, 10));    
    const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
    const loadMoreRef: any = useRef();
    const { gridColumns, actions } = state.data.reportConfig;
    const dropdownRef = useRef(null);
    const [sortColumn, setSortColumn] = useState(DEFAULT_SORT_COLUMN);
    const [sortDirection, setSortDirection] = useState(DEFAULT_SORT_DIRECTION);
    const [allRows, setallRows] = useState([...state.data.eventList.rows] as any[]);
   
    //const allRows = [...state.data.eventList.rows];
    const config = REPORT_CONFIG[state.routeInfo.pageName];
    console.log(state.data.searchCriteria, 'state.data.searchCriteria 39')
    useEffect(() => {
        if (dropdownRef.current) {

            new (Dropdown as any)(dropdownRef.current);
        }
    }, []);
    useEffect(() => {
        const sorted = [...allRows].sort(compareFunction);
        console.log(sorted, 'sorted Events 48');
        setSortedEvents(sorted);
    }, [state.data.eventList, sortColumn, sortDirection]);

    useEffect(() => {
            ManageEventService.search(state.data.searchCriteria, state.routeInfo.pageName).then((response: any) =>{
                console.log(response.data, 'response data 54 ')
                setStateValue('eventList', response.data)
                setallRows(response.data.rows)
            }
                
            );

            console.log(state.data?.eventList, 'state.data?.eventList 57')
    }, [state.data.searchCriteria]);
    
    useEffect(() => {
        setVisibleEvent(sortedEvents.slice(0, BATCH_SIZE));
    }, [sortedEvents]);

    useEffect(() => {
        console.log(sortedEvents, 'sortedEvents');
        const sorted = [...allRows].sort(compareFunction);
        setSortedEvents(sorted);
    }, [state.data, sortColumn, sortDirection]);

    

    useEffect(() => {
        const loadMoreObserver = new IntersectionObserver((entries) => {
            // Check if all students are already visible
           
            if (sortedEvents.length <= visibleEvent.length) {
                if (loadMoreRef.current) {                    
                    loadMoreObserver.unobserve(loadMoreRef.current); // Stop observing
                }
                return;
            }
            
            if (entries[0].isIntersecting) {
                const nextBatch = sortedEvents.slice(0, visibleEvent.length + BATCH_SIZE);
                setVisibleEvent([...nextBatch]);
            }

            
        });

        if (loadMoreRef.current) loadMoreObserver.observe(loadMoreRef.current);
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        return () => {
            if (loadMoreRef.current) {
                loadMoreObserver.unobserve(loadMoreRef.current);
            }
        };
    }, [loadMoreRef, visibleEvent]);

   
    // Utility to determine if the column is a string type.
    const isStringColumn = typeof visibleEvent[0]?.[sortColumn] === 'string';

    const compareFunction = (a: any, b: any) => {
        const aValue = a[sortColumn];
        const bValue = b[sortColumn];

        if (isStringColumn) {
            return sortDirection === 'asc'
                ? aValue?.localeCompare(bValue, undefined, { sensitivity: 'accent' })
                : bValue?.localeCompare(aValue, undefined, { sensitivity: 'accent' });
        } else {
            return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : aValue < bValue ? 1 : -1;
        }
    };

     
    
    const getActionNode = (row: any) => {
        // Determine actions based on row status
        let actions = ['UPDATE'];
        if (row.status === 'active') {
            actions = [...actions, 'MARK AS INACTIVE'];
        } else {
            actions = [...actions, 'MARK AS ACTIVE'];
        }
    
        return actions.length > 1 ? (
            <ActionSettings id={row?.id} actions={actions} onClick={handleAction} />
        ) : null;
    };

    const handleAction = (id: string, action: string) => {
        console.log(`handleAction: ${id} / ${action}`);
        handlePerformAction(action, [id]);
    };

    const handlePerformAction = (action: string, lead: any) => {
       //handle if multiple
       switch (action) {
        case 'UPDATE':           
            //setSelectedStudents([]);
            window.open(`/event/add/${lead}`);          
            break;        
        case 'MARK_AS_INACTIVE':
            updateInAction(lead);            
            break; 
        case 'MARK_AS_ACTIVE':
            updateAction(lead);            
            break;

       }
    };

    const updateAction = async (lead: any) => {
        try {

            const updateStatusURL = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/event-status`;
            axiosClient()
            .post(updateStatusURL, {
                eventId: lead[0],  // Ensure this is properly populated
                status: 'active',
            })
            .then((res) => {
                console.log('API Response:', res.data);
                alert('Successfuly updated event status.');
                window.location.reload(); // Reload the page on success
            })
            .catch((error) => {
                console.error('Error updating event status:', error);
                alert('Failed to update event status. Please try again.');
                window.location.reload(); // Reload the page on success
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }

    }

    const updateInAction = async (lead: any) => {
        try {

            const updateStatusURL = `${process.env.REACT_APP_WIDGET_API_SERVER}/eventAction/event-status`;
            axiosClient()
            .post(updateStatusURL, {
                eventId: lead[0],  // Ensure this is properly populated
                status: 'inactive',
            })
            .then((res) => {
                console.log('API Response:', res.data);
                alert('Successfuly updated event status.');
                window.location.reload(); // Reload the page on success
            })
            .catch((error) => {
                console.error('Error updating event status:', error);
                alert('Failed to update event status. Please try again.');
                window.location.reload(); // Reload the page on success
            });
        } catch (error) {
            console.error('Error fetching data:', error);
            alert(error);
        }

    }
    const [isChecked, setIsChecked] = useState(false);
    const handleSelectAll = (event: any) => {

        setIsChecked(event.target.checked);
        const events = event.target.checked ? allRows.map((item: any) => item.id) : [];
        setSelectedEvents(events);
       
    };

    const handleAudienceValue = (row: any) => {
        
        
        // Parse the JSON string to an array
        let audienceCategories = [];
        try {
            audienceCategories = JSON.parse(row.audienceCategories);
        } catch (error) {
            console.error('Failed to parse audience categories:', error);
        }
        // Map through the parsed array and render each category
        const renderedCategories = audienceCategories.map((category: string, index: number) => (
            <li key={index}>{getDomainValueForCode(category, 'EVENT_AUDIENCE_ALL', state)}</li>
            
        ));
    
        return <ul>{renderedCategories}</ul>; // Return the list wrapped in a <ul>
    };

    

    return (
        <> 
         <div>
            <div className="ActiveListings_present">
                <div className="d-flex flex-wrap align-items-center mb-3 w-100 justify-content-between mailerLinks-parent">
                <div className="d-flex flex-wrap align-items-center flex-1 mailerLinks my-2">
                    <div className="d-flex justify-content-start align-items-center customselect_parnt">
                        <div className="d-flex justify-content-start align-items-center customselect_parnt">
                            <select className="form-select w-120 me-2" id="BulkAction" onChange={(e) => setBulkAction(e.target.value)}>
                                <option value="">Bulk Action</option>
                                
                            </select>
                            <button
                                className="btn btn-darkblue"
                                type="button"
                                id="BulkClickBtn"
                                onClick={() => handlePerformAction(bulkAction, selectedStudents)}>
                                <span className="line-height-16">Apply</span>
                            </button>
                        </div>
                    </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-end">
                        <h3 className="font-14 font-500 mb-0 me-2">Total {allRows.length.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} records</h3>
                    </div>
                </div>
                <div className="table-responsive attendee_dtl">
                    <table className="table" style={{ minWidth: '1042px' }}>
                        <thead>
                            <tr>
                                <th>
                                <div>
                                    {/* <input
                                        type="checkbox"
                                        //checked={selectedStudents.length === visibleStudents.length}
                                        id="Antony"
                                        className="form-check-input me-2 mt-0"
                                        checked={isChecked}
                                        onChange={handleSelectAll}
                                    /> */}
                                    Event Title
                                </div>
                                </th>
                                <th>Schedule Time</th>
                                {/* <th>Speaker Name</th> */}
                                <th>Target Audience</th>
                                <th>Status</th>
                                <th>Created on</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                        {sortedEvents.length > 0 ? (
                        sortedEvents.map((row: any) => (
                            <tr key={row.id}>
                                <td>
                                    <div className="d-flex flex-wrap">   
                                        <div>                                
                                        {/* <input
                                            className="form-check-input me-2 StudentId"
                                            name="uuid"
                                            type="checkbox"
                                            value={row.id}
                                            checked={selectedEvents.includes(row.id)}
                                            onChange={handleSelectStudent}
                                        /> */}
                                        </div> 
                                         <div className="name d-flex flex-column line-height-18 flex-1">
                                            {/* <a href="javascript:;" className="font-16 font-500">{row.first_name} {row.last_name}</a> */}
                                            {/* <input className="form-check-input me-2" type="checkbox" value="" id="Antony" />*/}
                                                                                              
                                              <span data-bs-toggle="tooltip" title={`${row.eventTitle}`} className="singlelinename font-16 lineHeight-16 font-500">{row.eventTitle}</span>
                                            
                                            {/* <span><span>Type</span> - <b className="font-600">{getDomainValueForCode(row.programType, 'PROGRAM_TYPE', state) ?? ''}</b></span>
                                            <span><span>Mode</span> - <b className="font-600">{row.programDeliveryMode}</b></span> */}
                                        </div>
                                    </div>
                                </td>
                    
                                <td>
                                    <div className="font-14 font-500 mb-1">{row.from ? moment(row.from).format('DD-MMM-YYYY') : 'N/A'}</div>
                                    <div className="font-14 font-500"> {row.to ? moment(row.to).format('DD-MMM-YYYY') : 'N/A'} </div> 
                                    {/*<div className="font-14 font-500"><span className="d-block">({row.intake ? row.intake : 'N/A'} Intake)</span></div>     */}
                                </td>

                                {/* <td> 
                                    <div className="font-14 font-500 mb-2 d-flex align-items-start"><i className="table_phone"></i> <span className="flex-1 line-height-13">{row.event_title}</span></div>
                                    <div className="font-14 font-500 d-flex align-items-start"><i className="table_mail"></i> <span className="flex-1 line-height-13">{row.event_title}</span></div>
                                </td> */}
                                <td>
                                    {/* Safely accessing audienceCategory and its label
                                    {Array.isArray(row.audienceCategory) && row.audienceCategory.length > 0 ? (
                                    <p>
                                        {row.audienceCategory.map((cat:any) => cat.label).join(', ')}
                                    </p>
                                    ) : (
                                    <p>None</p> // Handle the case where audienceCategory is null or empty
                                    )} */}

                                    <ul>{handleAudienceValue(row)}</ul>

                                    
                                </td>
                                <td>
                                    <div className="font-500">{row.status}</div>
                                </td>
                                <td>
                                    <div className="font-500">
                                    {row.createdAt ? moment(row.createdAt).format('DD-MMM-YYYY') : 'N/A'}
                                    </div>
                                </td>
                                <td>{getActionNode(row)}</td>
                                
                                
                            </tr>
                          ))
                          ) : (
                            <tr>
                              <td colSpan={7} className="text-center"> {/* Fallback message with colspan */}
                                No records found
                              </td>
                            </tr>
                          )}
                        <tr ref={loadMoreRef} />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        
        
        
        </>
        );
    };
    
export default ManageEventGrid;
