// import config from '../config/config';
import { AxiosResponse } from 'axios';
// import { isEmpty } from '../../../library/Core/SmartFunctions';
import logger from '../../../library/Core/logger';
import { axiosClient } from '../../../library/Service/axiosClient';
import pageConfig from './PageConfigaudience.json';
import PageConfigaudience_institute_admin from './PageConfigaudience_institute_admin.json';
import { useContext } from 'react';
import SessionContext from '../../../library/Core/SessionContext';
// import { SessionStateType } from '../../../library/Core/SmartTypes';

class AudienceCategoryService {
    static userStatusCode: string;
    static studentId: string;
    static userTypeCode: string;
    static genderCode: string;
    static instituteLogo: string;
    static instituteGroupId: string;
    constructor() {
        // IIFE (Immediately Invoked Function Expression)
        (() => {
            const { sessionState } = useContext(SessionContext);
            AudienceCategoryService.userStatusCode = sessionState?.userStatusCode as any;
            AudienceCategoryService.studentId = sessionState?.studentId as any;
            AudienceCategoryService.userTypeCode = sessionState?.userTypeCode as any;
            AudienceCategoryService.genderCode = sessionState?.genderCode as any;
            AudienceCategoryService.instituteLogo = sessionState?.instituteLogo as any;
            AudienceCategoryService.instituteGroupId = sessionState?.instituteGroupId as any;
            //const { userStatusCode, studentId, userTypeCode, genderCode, instituteLogo: schoolImage } = sessionState as SessionStateType;
        })();

        // Rest of the constructor
    }

    static pageLoader(params: any) {
        const state: any = {};
        const { eventId, pageName } = params;
        return Promise.all([this.getPageConfig(), AudienceCategoryService.get(eventId)])
            .then((values) => {
                state.formConfig = values[0];
                state.data = values[1];
                state.routeInfo = { pageName };                
                return Promise.resolve(state);
            })
            .catch(function (err) {
                logger.log(`Error in loading data for ${pageName} and the error is:`);
                console.log(err);
            });
    }

    static getPageConfig() {
        const localUserData = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string) : {};
        const userTypeCode = localUserData?.userTypeCode;
        if (userTypeCode === 'INSTITUTE_ADMIN') {
            return Promise.resolve(PageConfigaudience_institute_admin);
        }

        return Promise.resolve(pageConfig);
    }

    static async get(eventId: any): Promise<any> {
        // TODO: Fix the issue with sessionStorage
        
        let AudienceInfo = { };
        let AudienceInfodata = [ ];
        
        if (eventId) {
            AudienceInfodata = (await axiosClient().get(`${process.env.REACT_APP_WIDGET_API_SERVER}/eventInfo/theme-info/${eventId}`))?.data; 
            
            const Audiencedata = AudienceInfodata;

            console.log(Audiencedata, 'Get Audiencedata')
            
            if (Audiencedata){
                AudienceInfo = AudienceCategoryService.toAudienceto(AudienceInfodata);
            }else{
                AudienceInfo = { };
            }
        }
        console.log(AudienceInfo, 'AudienceInfo log data')

        return Promise.resolve(AudienceInfo);
    }

    static toAudienceto(AudienceInfo: any) {


        const parseToArray = (input: any) => {
            if (Array.isArray(input)) {
                return input; // Already an array, no need to parse
            }
            try {
                return JSON.parse(input || "[]"); // Safely parse string to array
            } catch (error) {
                console.error("Failed to parse input to array:", error);
                return []; // Fallback to an empty array in case of error
            }
        };
        
        // Safely map each field
        AudienceInfo.audienceCategory1 = parseToArray(AudienceInfo.audienceCategory).map((item: any) => {
            return { audienceCategory1: item };
        });
        
        //AudienceInfo.educationLevelCode = AudienceInfo.educationLevelCode;
        
        AudienceInfo.programId = parseToArray(AudienceInfo.programType).map((item: any) => {
            return { programId: item };
        });
        
        AudienceInfo.programCompletionYear = AudienceInfo.programCompletionYear?.map((item: any) => {
            return { programCompletionYear: item };
        });

        AudienceInfo.workingStatusCode = parseToArray(AudienceInfo.workingStatusCode).map((item: any) => {
            return { workingStatusCode: item };
        });

        AudienceInfo.instituteType = parseToArray(AudienceInfo.instituteType).map((item: any) => {
            return { instituteType: item };
        });

        AudienceInfo.curriculum = parseToArray(AudienceInfo.curriculum).map((item: any) => {
            return { curriculum: item };
        });

        AudienceInfo.superCounselor = parseToArray(AudienceInfo.superCounselor).map((item: any) => {
            return { superCounselor: item };
        });

        AudienceInfo.CompletionYear12th = parseToArray(AudienceInfo.completionYear12th).map((item: any) => {
            return { CompletionYear12th: item };
        });

        AudienceInfo.UGCompletionYear = parseToArray(AudienceInfo.ugCompletionYear).map((item: any) => {
            return { UGCompletionYear: item };
        });

        AudienceInfo.PGCompletionYear = parseToArray(AudienceInfo.pgCompletionYear).map((item: any) => {
            return { PGCompletionYear: item };
        });

        AudienceInfo.doctoralCompletionYear = parseToArray(AudienceInfo.doctoralCompletionYear).map((item: any) => {
            return { doctoralCompletionYear: item };
        });

        AudienceInfo.industry = parseToArray(AudienceInfo.industry).map((item: any) => {
            return { industry: item };
        });

        AudienceInfo.functionalArea = parseToArray(AudienceInfo.functionalArea).map((item: any) => {
            return { functionalArea: item };
        });

        console.log(AudienceInfo, 'AudienceInfo data')

        return AudienceInfo;
    }



    // static toAudienceto(AudienceInfo: any) {
       
        
    //     AudienceInfo.audienceCategory1 = AudienceInfo.data.audience_category;
    //     AudienceInfo.educationLevelCode = AudienceInfo.data.education_level;
    //     AudienceInfo.programId = AudienceInfo.data.program_type;
    //     AudienceInfo.workingStatusCode = AudienceInfo.data.working_status;
    //     AudienceInfo.batchyear = AudienceInfo.data.batch;
    //     // eventinfo.registrationsActive = eventinfo.data.reg_status;
    //     // eventinfo.eventType = eventinfo.data.event_type;
    //     // eventinfo.registrationLink = eventinfo.data.reg_link;
    //     // eventinfo.videoLink = eventinfo.data.video_link;
    //     // eventinfo.presenterCategory = eventinfo.data.presenter_category;
    //     // eventinfo.instituteId = eventinfo.data.related_institute;


    //     return AudienceInfo;
    // }

   
    
 
}

export default AudienceCategoryService;
