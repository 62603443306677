export const REPORT_CONFIG: any = {
    'internal-admin-all-school-summary': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: [],
        SEARCH_API: 'all-school-summary',
    },
    'internal-admin-all-alumni-report': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['VALIDATE','VERIFY_EMAIL', 'AUTO_LOGIN', 'DEACTIVATE', 'ACTIVATE', 'UPDATE', 'DELETE'],
        SEARCH_API: 'all-alumni-list',
        UPDATE_ACTION_LINK: (uuid: string) => `${process.env.REACT_APP_APP_BASE_URL}/alumni/${uuid}/profile`,
    },
    'internal-admin-user-entered-college': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: [],
        SEARCH_API: 'non-standard-colleges',
        UPDATE_ACTION_LINK: (uuid: string) => `${process.env.REACT_APP_APP_BASE_URL}/alumni/${uuid}/profile`,
    },
    'internal-admin-user-entered-exam': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: [],
        SEARCH_API: 'non-standard-exams',
        UPDATE_ACTION_LINK: (uuid: string) => `${process.env.REACT_APP_APP_BASE_URL}/alumni/${uuid}/profile`,
    },
    'internal-admin-benefits-report': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE'],
        SEARCH_API: 'benefits',
        UPDATE_ACTION_LINK: (uuid: string) => `${process.env.REACT_APP_APP_BASE_URL}/alumni/${uuid}/benefits`,
        TABLE_CLASS: "sticky_table_3_columns_noaction",
        TABLE_STYLE: {minWidth: '3000px'},
        TABLE_WIDTH: [250, 180, 250]
    },
    // action_master: [
    //     { code: 'VALIDATE', label: 'Validate' },
    //     { code: 'AUTO_LOGIN', label: 'Auto-login' },
    //     { code: 'DEACTIVATE', label: 'Deactivate' },
    //     { code: 'ACTIVATE', label: 'Activate' },
    //     { code: 'UPDATE', label: 'Update' },
    // ],
    'program-list': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE'],
        SEARCH_API: 'program/get-program-details',
        UPDATE_ACTION_LINK: (id: string) => `${process.env.REACT_APP_APP_BASE_URL}/program/edit-program/${id}`,
    },
    'manage-lead': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE','VIEW'],
        SEARCH_API: 'marketlead/getleadlist',
    },
    'manage-referral': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE','VIEW'],
        SEARCH_API: 'marketlead/getreferrallist',
    },
    'manage-event': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE','ACTION TO ACTIVE','ACTION TO INACTIVE'],
        SEARCH_API: 'marketevent/geteventlist',
        
    },
    'domain-data': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE','DEACTIVATE','ACTIVATE'],
        SEARCH_API: 'program/get-program-details',
        UPDATE_ACTION_LINK: (id: string) => `${process.env.REACT_APP_APP_BASE_URL}/program/edit-program/${id}`,
    },
    'institute-list': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE','Publish','Unpublish'],
        SEARCH_API: 'institute/get-institute-details',
      //  UPDATE_ACTION_LINK: (id: string) => `${process.env.REACT_APP_APP_BASE_URL}/institute/${id}/basic-info`,
    },
    'profilemarker-notes': {
        SHOW_SELECT_ALL: false,
        BULK_ACTION: [],
        ROW_ACTION: ['UPDATE','DEACTIVATE','ACTIVATE'],
        // SEARCH_API: 'program/get-program-details',
        // UPDATE_ACTION_LINK: (id: string) => `${process.env.REACT_APP_APP_BASE_URL}/program/edit-program/${id}`,
    },
};
