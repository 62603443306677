import React, { useContext, useEffect, useState } from 'react';
import { useImmerReducer } from 'use-immer';
import { useLoaderData } from 'react-router-dom';
import smartReducer from '../../library/Core/SmartReducer';
import { DispatchEvent, State } from '../../library/Core/SmartTypes';

const ManageWidgetsList = () => {
    const [state, dispatch] = useImmerReducer<State, DispatchEvent>(smartReducer, {
        flags: { isDataLoading: true, showFormErrors: 0 },
        formValidationErrors: {},
        actions: {},
    } as any);

    const { data } = useLoaderData() as { data: State['actions'] };

    useEffect(() => {
        dispatch({
            type: 'DATA_INIT',
            payload: { data },
        });
    }, []);

    /**
     * This function determines the configuration module slug based on the provided widget code.
     * It is used to generate the correct URL for configuring specific widgets.
     *
     * @param widgetCode - The unique identifier of the widget.
     * @returns The configuration module slug corresponding to the given widget code.
     *          If the widget code matches any of the specified cases, the function returns the corresponding configuration module slug.
     *          Otherwise, it defaults to 'configuration'.
     */
    const getConfigurationModuleSlug = (widgetCode: any) => {
        let configurationModule = 'configuration';

        switch (widgetCode) {
            case 'top_careers':
            case 'top_study_country':
            case 'top_companies':
            case 'top_colleges':
            case 'directory_stats':
                configurationModule = 'configure';
                break;

            case 'guiding_alumni_mentors':
            case 'notable_alumni_mentors':
            case 'alumni-speak':
            case 'eclectic_career_alumni':
            case 'entrepreneur_alumni':
            case 'topper_zone':
            case 'spotlight':
                configurationModule = 'configurelist';
                break;
        }

        return configurationModule;
    }

    const [isActive, setIsActive]: any = useState({});
    const [isHidden, setIsHidden] = useState(false);

    const handleClick = (id: any) => {
        setIsActive((prevState: any) => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };


    return (
        <>
            <div className="main flex-1 bg-white">
                <div className="max-1140 pt-3 pt-sm-4 px-lg-0 pb-5 overflow-hidden mx-auto">
                    <div className="d-flex justify-content-between align-items-center mb-3 pb-3 custom-bottom-liteblue">
                        <h3 data-aos="fade-left" data-aos-delay="600" className="font-35 font-600 mb-0 block-title position-relative">
                            Manage Widgets For Alumni
                        </h3>
                    </div>
                    {state.flags.isDataLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <div className="manage-widgets-wrap">

                            <div className="search alumniSearch m-0 open position-static flex-1 mb-3">
                                <form className="form-inline d-flex flex-wrap justify-content-between d-block">
                                    <input type="search" placeholder="Search by file name" aria-label="Search" className="form-control flex-1 font-13 font-500 text-truncate bg-transparent"/>
                                    <button className="btn btn-search icon icon-search" type="submit"></button>
                                </form>
                                <a href="javascript:;" className="btn-search-toggle icon-search"></a>
                            </div>
                            <ul>
                                {state.data?.wedgitList.map((widget: any) => (
                                    <li key={widget.id} className='p-0'>
                                        <div className="px-3 py-2">
                                            <div className="row g-2 g-md-3 py-1">
                                                <div className="col-md-auto">
                                                    <div className="d-flex h-100 align-items-center justify-content-center"> 
                                                        <i 
                                                        className="icon-down-arrow cursor-pointer" 
                                                        // data-bs-toggle="collapse" 
                                                        // data-bs-target={`#${widget.name.replace(/\s+/g, '')}`} 
                                                        aria-expanded={`${isActive[widget.name.replace(/\s+/g, '')] ? 'false' : 'true'}`}
                                                        // aria-controls={widget.name.replace(/\s+/g, '')} 
                                                        onClick={() => handleClick(widget.name.replace(/\s+/g, ''))}></i>
                                                    </div>
                                                </div>  
                                                <div className="col-md">
                                                    <div className="form-check form-check-tick">
                                                        <input className="form-check-input d-none" type="checkbox" value="" id="Hero" />
                                                        <label className="form-check-label font-18 font-500 flex-1" htmlFor="Hero">
                                                            {widget.name}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-auto">
                                                    <div className="d-flex align-items-center">
                                                        {widget.widgetInstId ? (
                                                            <>
                                                                <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}/${widget.widgetInstId}`} className="btn btn-widgets-edit me-2">
                                                                    <span>Edit</span>
                                                                </a>
                                                                <a href={`${process.env.REACT_APP_WIDGET_UI_SERVER}/widgetpreview/${widget.widgetInstId}`} target='_blank' className="btn btn-priview">
                                                                    <span>Preview</span>
                                                                </a>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <a href={`/widget/${getConfigurationModuleSlug(widget.code)}/${widget.code}`} className="btn btn-configure">
                                                                    <span>CONFIGURE</span>
                                                                </a>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div className={`collapse ${isActive[widget.name.replace(/\s+/g, '')] ? '' : 'show'}`} >
                                            <div className="p-3 bg-light">
                                                <div className="inner-widget-list-wrap">
                                                    <div className="widget-list py-2">
                                                    <div className="row g-3 g-md-3 py-1" >                   
                                                        <div className="col-md">
                                                            <div className="form-check form-check-tick h-100 d-flex align-items-center ">
                                                            
                                                                <label className="form-check-label font-18 font-500 flex-1">
                                                                Indian Institute of Food Science and Technology
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="form-check form-check-tick h-100 d-flex align-items-center ">
                                                            
                                                                <label className="form-check-label font-18 font-500 flex-1">
                                                                Indian Institute of Food Science and Technology Indian Institute of Food Science and Technology
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-auto">
                                                            <div className="d-flex align-items-center">
                                                                <a href="bulk-imports-map.html" className="btn btn-widgets-edit me-2"><span className="">Edit</span></a>
                                                                <a href="bulk-imports-map.html" className="btn btn-priview"><span className="">Preview</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="widget-list py-2">
                                                    <div className="row g-2 g-md-3 py-1" >                   
                                                    <div className="col-md">
                                                            <div className="form-check form-check-tick h-100 d-flex align-items-center ">
                                                            
                                                                <label className="form-check-label font-18 font-500 flex-1">
                                                                Indian Institute of Food Science and Technology
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md">
                                                            <div className="form-check form-check-tick h-100 d-flex align-items-center ">
                                                            
                                                                <label className="form-check-label font-18 font-500 flex-1">
                                                                Indian Institute of Food Science and Technology Indian Institute of Food Science and Technology
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-auto">
                                                        <div className="d-flex align-items-center">
                                                            <a href="bulk-imports-map.html" className="btn btn-widgets-edit me-2"><span className="">Edit</span></a>
                                                            <a href="bulk-imports-map.html" className="btn btn-priview"><span className="">Preview</span></a>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ManageWidgetsList;