import { axiosClient } from '../../../library/Service/axiosClient';
import { REPORT_CONFIG } from '../../admin/reports/report-config';
import { arrayColumn, isEmpty, isFormValid, setError } from '../../../library/Core/SmartFunctions';
import pageConfig from './PageConfigSearch.json';

class ManageEventService {
    static pageLoader(params: any): Promise<any> {
        const state: any = {};
        const searchCriteria = ManageEventService.getDefaultSearchCriteria();
       
        return Promise.all([
            this.getPageConfig(),
            this.search(searchCriteria, params.pageName)
        ]).then((values) => {
            console.log(values, 'fetched data')
            state.formConfig = values[0];
            state.data = {
                eventList: values[1].data ?? [],
                searchCriteria: { ...searchCriteria },
                //filterDomains: values[2].data,
                reportConfig: REPORT_CONFIG[params.pageName],
            };
            state.routeInfo = params;
            state.internal = { gridState: {} };
            return Promise.resolve(state);
        });
    }

    static search(searchCriteria: any, pageName: string): any {
      
        const config = REPORT_CONFIG[pageName];
        const payload = ManageEventService.sanitizeSearchCriteria(searchCriteria,);
        //return axiosClient().post(`${process.env.REACT_APP_LEAD_SERVER}/${config.SEARCH_API}`, payload);
        const apiServer = process.env.REACT_APP_WIDGET_API_SERVER;
        return axiosClient().post(
            `${apiServer}/eventInfo/fetch-events`,
            payload
        );
    }

    static getPageConfig() {
        
        return Promise.resolve(pageConfig);
    }

    static sanitizeSearchCriteria(searchCriteria: any) {
        console.log(searchCriteria, 'searchCriteria data')
        const criteria = { ...searchCriteria };
        for (const [key, value] of Object.entries(searchCriteria)) {
            if (key === 'instituteType') {
                    if(!isEmpty(value)){
                        //criteria[key] = [value];  // Wrap the value in an array
                        criteria[key] = arrayColumn(value ?? [], 'instituteType');
                    } else {
                            criteria[key] = [];
                    }
                } 

            if (key === 'audienceCategory1') {
                if(!isEmpty(value)){
                    criteria[key] = arrayColumn(value ?? [], 'audienceCategory1');  // Transform and wrap in an array
                } else {
                    criteria[key] = [];
                }
                
            }
            if (key === 'schoolId') {
                console.log(value, 'schoolId 58')
                if(!isEmpty(value)){
                    criteria[key] = [String(value)];
                } else {
                    criteria[key] = [];
                }
                  // Convert the value to a string and wrap it in an array
            }
            if (key === 'eve_presenter') {
                if (!isEmpty(value)) {
                    // Convert value to an integer and pass it to arrayColumn
                    //const parsedValue = parseInt(value as string, 10);
                    criteria[key] = arrayColumn(value ?? [], 'eve_presenter');
                } else {
                    // Assign an empty array if the value is empty
                    criteria[key] = [];
                }
            }

            if (key === 'status') {
                if (!isEmpty(value)) {
                    if(value === '0'){

                        criteria[key] = 'inactive';

                    } else if(value === '1') {

                        criteria[key] = 'active';

                    } else if(value === '2') {

                        criteria[key] = 'delete';

                    }
                      // Typecast value to a string
                } else {
                    criteria[key] = [];
                }
            }
        }

        console.log(criteria, 'criteria list data')
        return criteria;
    }

    static getDefaultSearchCriteria(): any {
        return {
            instituteId:[],
            programId: [],
            leadSource:[],
            registrationType:[],
            leadStatus:[],
            leadCreationDate:[],
            leadUpdationDate:[],
            intakeBatch:[],
            createdFrom:[],
            createdTo:[],
            updateFrom:[],
            updateTo:[],
            schoolId:[],
            registrationsActive:[]
        };
    }
}

export default ManageEventService;
